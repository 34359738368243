import { AppThunkAction } from "..";
import { KnownAction } from "./actionTypes";
import ApiService, { ApiEndpoints, getAxiosErrorMessage, newPendingReq, secureAxios } from "../../utilities/apiService";
import ErrorHandler from "../../components/controls/errorHandler";
import {
  User,
  UpdateUserStatusRequest,
  UpdatePartnerAssociationRequest,
  UpdateUserAssistantsRequest,
  RelatedUser,
  getPartnerAssociationById,
} from "../../dataTypes/user/user";
import SearchAssistantUsersRequest from "../../dataTypes/user/SearchAssistantUsersRequest";
import { UserSettings } from "../../dataTypes/user/userSettings";
import { notify } from "../../components/controls/notifier";
import { SearchOfficersOrAccountExecsRequest } from "../../dataTypes/partners/searchOfficersOrAccountExecsRequest";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { SearchUsersFilter, SearchUsersResult } from "../../dataTypes/user/searchUsers";
import { showLoading, hideLoading } from "react-redux-loading-bar";

export const accountActionCreators = {
  getSignedInUser: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    try {
      const resp: AxiosResponse<User> = await ApiService.CallApi("GET", ApiEndpoints.GetUserProfile, {});
      dispatch({ type: "GET_SIGNED_IN_USER", payload: resp.data });
    } catch (error: any) {
      ErrorHandler.HandleAxiosError(error, getAxiosErrorMessage(error));
    }
  },

  setSignedInUser:
    (user: User): AppThunkAction<KnownAction> =>
    (dispatch) => {
      dispatch({ type: "SET_SIGNED_IN_USER", payload: user });
    },

  getUserProfile:
    (userId: number, acctManagerParam?: { isAcctManager: boolean }): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // let redux know user profile request pending
      dispatch({ type: "GET_USER_PENDING" });

      if (!acctManagerParam) {
        dispatch(showLoading());
      }

      ApiService.CallApi(
        "GET",
        "/api/Account/GetUserProfileById",
        { userId },
        (data: User) => {
          dispatch({
            type: acctManagerParam && acctManagerParam.isAcctManager ? "GET_ACCT_MANAGER_PROFILE" : "GET_USER_PROFILE",
            payload: data,
          });
          setTimeout(() => {
            dispatch(hideLoading());
          }, 500);
        },
        (error: object) => {
          ErrorHandler.HandleError(error);
          setTimeout(() => {
            dispatch(hideLoading());
          }, 500);
        },
      );
    },

  getUserSettings: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    try {
      const resp: AxiosResponse<UserSettings> = await ApiService.CallApi("GET", ApiEndpoints.UserSettings, {});
      dispatch({ type: "GET_USER_SETTINGS", payload: resp.data });
    } catch (error: any) {
      ErrorHandler.HandleError(error, getAxiosErrorMessage(error));
    }
  },

  setUserProfile:
    (user: User | null): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "SET_USER_PROFILE", payload: user });
    },

  // this action should be used for updating user's basic contact info, like on the Admin UserProfile's or the Profile Settings
  updateUserBasicProfile:
    (user: User): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "UPDATE_USER_BASIC_PROFILE_PENDING" });

      ApiService.CallApi(
        "POST",
        "/api/Account/UpdateUserBasicProfile",
        user,
        () => dispatch({ type: "UPDATE_USER_BASIC_PROFILE" }),
        (error: object) => {
          dispatch({ type: "UPDATE_USER_BASIC_PROFILE_FAILED" });
          ErrorHandler.HandleError(error);
        },
      );
    },

  getRealEstateRoles: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    ApiService.CallApi(
      "GET",
      "/api/Account/RealEstateUserRoles",
      {},
      (data: any) => {
        dispatch({ type: "GET_REAL_ESTATE_ROLES_RECEIVED", payload: data });
      },
      (error: object) => {
        ErrorHandler.HandleError(error);
        dispatch({ type: "GET_REAL_ESTATE_ROLES_REQUEST" });
      },
      { allowAnonymous: true },
    );
    dispatch({ type: "GET_REAL_ESTATE_ROLES_REQUEST" });
  },

  getFinanceRoles: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    ApiService.CallApi(
      "GET",
      "/api/Account/FinanceUserRoles",
      {},
      (data: any) => {
        dispatch({ type: "GET_FINANCE_ROLES_RECEIVED", payload: data });
      },
      (error: object) => {
        ErrorHandler.HandleError(error);
        dispatch({ type: "GET_FINANCE_ROLES_REQUEST" });
      },
      { allowAnonymous: true },
    );
    dispatch({ type: "GET_FINANCE_ROLES_REQUEST" });
  },

  getPartnerUserRoles: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    ApiService.CallApi(
      "GET",
      "/api/Account/PartnerUserRoles",
      {},
      (data: any) => {
        dispatch({ type: "GET_PRTNER_USER_ROLES_RECEIVED", payload: data });
      },
      (error: object) => {
        ErrorHandler.HandleError(error);
        dispatch({ type: "GET_PRTNER_USER_ROLES_REQUEST" });
      },
      { allowAnonymous: true },
    );
    dispatch({ type: "GET_PRTNER_USER_ROLES_REQUEST" });
  },

  // more advanced search between title/escrow officers or account manager
  searchOfficersOrAccountExecs:
    (request: SearchOfficersOrAccountExecsRequest): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      ApiService.CallApi(
        "POST",
        "/api/Account/SearchOfficersOrAccountExecs",
        request,
        (data: any) => {
          dispatch({ type: "SEARCH_OFFICERS_OR_ACCOUNT_EXECS", payload: data });
        },
        (error: object) => {
          ErrorHandler.HandleError(error);
          //dispatch({ type: "GET_SEARCH_ACCOUNT_EXCES_REQUEST" });
        },
      );
      //dispatch({ type: "GET_SEARCH_ACCOUNT_EXCES_REQUEST" });
    },

  // search related assistant users
  searchAssistantUsers:
    (request: SearchAssistantUsersRequest): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      ApiService.CallApi(
        "GET",
        "/api/Account/SearchAssistantUsers",
        request,
        (data: any) => dispatch({ type: "SEARCH_ASSISTANT_USERS", payload: data }),
        (error: object) => ErrorHandler.HandleError(error),
      );
    },

  searchUsers:
    (filters: SearchUsersFilter): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // cancel any pending requests
      const { searchUsersReqStatus } = getState().account;
      if (searchUsersReqStatus.pending) {
        searchUsersReqStatus?.cancelTokenSource?.cancel();
      }

      // create pending request w/cancel token
      const cancelTokenSource = Axios.CancelToken.source();
      dispatch({ type: "SEARCH_USERS_PENDING", payload: { ...newPendingReq(), cancelTokenSource } });
      dispatch(showLoading());

      secureAxios
        .post<SearchUsersResult>(ApiEndpoints.SearchUsers, filters, { cancelToken: cancelTokenSource.token })
        .then((resp) => {
          dispatch(hideLoading());
          dispatch({ type: "SEARCH_USERS", payload: resp.data });
        })
        .catch((err) => {
          dispatch(hideLoading());
          if (!Axios.isCancel(err)) {
            ErrorHandler.HandleError(err);
            dispatch({ type: "SEARCH_USERS_FAILED" });
          }
        });
    },

  updateUserStatus:
    (request: UpdateUserStatusRequest): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "UPDATE_USER_STATUS_PENDING" });
      ApiService.CallApi(
        "POST",
        "/api/Account/UpdateUserStatus",
        request,
        () => dispatch({ type: "UPDATE_USER_STATUS", payload: request }),
        (error: object) => ErrorHandler.HandleError(error),
        { postWithURLQueryParams: true },
      );
    },

  approveUserRequest:
    (userId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "APPROVE_USER_REQUEST_PENDING" });

      ApiService.CallApi(
        "POST",
        "/api/Account/ApproveUser",
        { userId: userId },
        () => dispatch({ type: "SAVE_APPROVE_USER_RECEIVED_SUCCESS", payload: userId }),
        (error: AxiosError<any>) => {
          if (error.response && error.response.data && error.response.data.message) {
            ErrorHandler.HandleError(error.response.data, error.response.data.message);
          } else {
            ErrorHandler.HandleError(error);
          }
        },
        { postWithURLQueryParams: true },
      );
    },

  resetPasswordUserRequest:
    (username: string, password?: string): AppThunkAction<KnownAction> =>
    (dispatch) => {
      ApiService.CallApi(
        "POST",
        "/api/Account/ResetPassword",
        { username: username, password: password },
        () => {
          notify.success("Password has been successfully reset");
          dispatch({ type: "SAVE_RESET_PASSWORD_USER_RECEIVED_SUCCESS", payload: username });
        },
        (error: object) => ErrorHandler.HandleError(error),
      );
    },

  /** Deprecated - use standalone secure axios query in /userProfile/queries.ts */
  updatePartnerAssociation:
    (request: UpdatePartnerAssociationRequest): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "UPDATE_PARTNER_ASSOCIATION_PENDING" });
      ApiService.CallApi(
        "POST",
        "/api/Account/UpdatePartnerAssociation",
        request,
        (data: any) => {
          dispatch({ type: "UPDATE_PARTNER_ASSOCIATION" });
          notify.success("Changes saved successfully.");
        },
        (error: any) => {
          dispatch({ type: "UPDATE_PARTNER_ASSOCIATION" });
          ErrorHandler.HandleError(error);
        },
      );
    },

  updateUserAssistants:
    (request: UpdateUserAssistantsRequest): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "UPDATE_USER_ASSISTANTS_PENDING" });
      ApiService.CallApi(
        "POST",
        "/api/Account/UpdateUserAssistants",
        request,
        (data: RelatedUser[]) => {
          const { userProfile } = getState().account;
          if (!userProfile) {
            notify.error("No user profile found.");
            return;
          }

          const updatedUser = { ...userProfile };
          const updatedPartnerAssociation = getPartnerAssociationById(userProfile, request.partnerId);
          if (!updatedPartnerAssociation) {
            notify.error("No user partner association found.");
            return;
          }

          updatedPartnerAssociation.assistants = data;
          const i = userProfile?.partnerAssociations.findIndex(
            (x) => x.partnerId === updatedPartnerAssociation?.partnerId,
          );
          const updatedPartnerAssociations = [...updatedUser?.partnerAssociations];
          updatedPartnerAssociations.splice(i, 1, updatedPartnerAssociation);

          dispatch({ type: "SET_USER_PROFILE", payload: updatedUser });
          notify.success("Changes saved successfully.");
        },
        (error: any) => ErrorHandler.HandleError(error),
      );
    },

  updateUserCompanySettings:
    (user: User): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      ApiService.CallApi(
        "POST",
        "/api/Account/UpdateUserCompanySettings",
        user,
        (data: any) => {
          dispatch({ type: "SET_USER_PROFILE", payload: user });
          notify.success("Changes saved successfully.");
        },
        (error: AxiosError) => {
          const errorMessage: string = getAxiosErrorMessage(error);
          if (errorMessage) {
            ErrorHandler.HandleError(error, errorMessage);
          } else ErrorHandler.HandleError(error);
        },
      );
    },

  clearOfficersAccountExecsOptions: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "CLEAR_OFFICERS_ACCOUNT_EXECS_OPTIONS" });
  },

  clearAccountStore: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "CLEAR_ACCOUNT_STORE" });
  },

  clearUserProfile: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "CLEAR_USER_PROFILE" });
  },
};
