import * as React from "react";
import { NavLink } from "react-router-dom";
import DashedLineGroup, { DashedLine } from "../../controls/dashedLineGroup";

class TxMSection extends React.Component<any> {
  render() {
    return (
      <div className="txm-section-full-page-main-container">
        <div className="txm-section-main-container">
          <div className="txm-flexbox-container-homepage">
            <div className="whatElse-content">
              <div className="header-1 txt-white">
                Flueid <span className="txm-text-title txt-white txt-extraLight">TxM </span>
              </div>

              <div className="subheader-1 txt-light">Faster, Simpler, Easier Transactions</div>

              <div className="whatElseTxtSubSecMain">
                <div className="whatelseSec rich">
                  <div className=" txt-extraLight">
                    Flueid TxM has solutions and tools to simplify and secure communication between closing agents and
                    stakeholders of a real estate transaction.
                  </div>
                </div>
              </div>
              <NavLink to="/txm-about">
                <div className="btn-black btn-txm">More About TxM</div>
              </NavLink>
              <div className="dashed-line-container">
                <DashedLineGroup
                  visibilityClassName=""
                  className="dashed-line-txm-home"
                  render={(visible: boolean) => (
                    <>
                      <DashedLine width={35} duration={1.5} delay={0.2} x={"9vw"} show={visible} color="white" />
                      <DashedLine width={80} duration={1} delay={0.5} x={"11vw"} show={visible} color="white" />
                      <DashedLine width={35} duration={0.5} delay={0.7} x={"13vw"} show={visible} color="white" />
                    </>
                  )}
                />
              </div>
            </div>

            <div className="txm-hero-img"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default TxMSection;
