import * as React from "react";

interface Props {
  label?: string;
  type: "text" | "number" | "stringNumber" | "date" | "phone";
  // note: 'stringNumber' is used for something like zipCode, which needs to be a string of numeric characters
  //  in this case actual input type will be 'number', ensuring no letters can be entered.
  name: string;
  initialValue: string | number;
  placeHolder?: string;
  focusOnMount?: boolean;
  onChange: (name: any, value: string) => void;
  onClearClick?: (name: any) => void;
  required?: boolean;
  leftIcon?: JSX.Element;
  styles?: React.CSSProperties;
  autoComplete?: boolean;
  min?: number;
  max?: number;
  pattern?: RegExp;
  valid?: boolean;
  errorMessages?: string[];
  editable?: boolean;
}

interface State {
  value: string | number;
}

class HomeInput extends React.Component<Props, State> {
  state: State = {
    value: "",
  };

  private inputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    const { focusOnMount } = this.props;

    if (focusOnMount) {
      const ref = this.inputRef.current;
      if (ref) {
        ref.focus();
      }
    }
  }

  handleInputChange = (e: any) => {
    e.persist();

    const { type, max, initialValue } = this.props;

    let value = e.target.value;
    let name = e.target.name;

    // HANDLE DIFFERENT INPUT TYPE CONDITIONS
    if (type === "phone") {
      // parse the formatted number to a basic string
      // value = parseIncompletePhone(value);
      //**** Phones have an automatic max value
      // check to see if phone has exceeded max value
      // if (valueExceedsMax(initialValue, value, value[0] === '1' ? 11 : 10)) return;
    } else if (type === "number") {
      // parse the value
      if (!value) {
        value = 0;
      } else {
        value = parseFloat(value);
      }

      // return out if value exceeds max
      // if (max && valueExceedsMax(initialValue, value, max)) return;
    } else if (type === "stringNumber") {
      // return out if value exceeds max
      // if (max && valueExceedsMax(initialValue, value, max)) return;
    }

    this.props.onChange(name, value);
  };

  handleClearClick = (e: any) => {
    e.persist();

    const input = this.inputRef.current;
    if (input) {
      input.focus();
    }

    // this.props.onClearClick(this.props.name);
  };

  render() {
    const {
      label,
      type,
      name,
      editable,
      initialValue,
      autoComplete,
      placeHolder,
      required,
      leftIcon,
      styles,
      max,
      pattern,
      valid,
      errorMessages,
    } = this.props;

    let value = initialValue !== null ? initialValue : "";

    // format the phone number
    if (type === "phone") {
      // value = new AsYouType('US').input(value.toString());
    }

    const optionalAttributes: { [key: string]: any } = {};
    if (max && type === "text") optionalAttributes.maxLength = max;
    if (max && (type === "number" || type === "stringNumber")) optionalAttributes.max = max;
    if (pattern) optionalAttributes.pattern = pattern;
    if (editable === false) optionalAttributes.readOnly = true;

    return (
      <div className={`input-group ${editable === false ? "read-only" : ""}`} style={styles ? styles : {}}>
        {leftIcon ? leftIcon : ""}
        <input
          type={type === "stringNumber" ? "number" : type}
          min={type === "number" ? 1 : ""}
          value={type === "number" && !value ? "" : value}
          name={name}
          onChange={this.handleInputChange}
          placeholder={placeHolder}
          ref={this.inputRef}
          style={leftIcon ? { paddingLeft: "25px" } : {}}
          autoComplete={autoComplete ? "on" : "off"}
          //onKeyDown={this.handleKeyDown}
          {...optionalAttributes}
          className={`${errorMessages && errorMessages.length ? "error" : ""}`}
          id={label}
        />
        <label className={`label ${value ? "focus" : ""}`} htmlFor={label}>
          {label} {required && required === true ? "*" : ""}
        </label>
        {/*<div*/}
        {/*    className={`icon-clear ${!value ? 'hidden' : ''}`}*/}
        {/*    onClick={this.handleClearClick}*/}
        {/*    title={name}*/}
        {/*    style={type === 'date' ? { right: '40px' } : {}}*/}
        {/*/>*/}
        <div className={`bar ${!valid ? "error" : ""}`} />
        {errorMessages && errorMessages.length
          ? errorMessages.map((x: string, index: number) => (
              <div key={`input-error-message-${index}`} className="error-message">
                {x}
              </div>
            ))
          : ""}
      </div>
    );
  }
}

export default HomeInput;
