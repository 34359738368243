import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { GeoPoint } from "../../../dataTypes/maps";
import { SidebarContentType } from "components/titlefyPro/drawing/types/sidebarContentType";

export interface DrawingMapState {
  polygonPoints: GeoPoint[];
  shapeIsDragging: boolean;
  polygonEditable: boolean;
  sidebarContentType?: SidebarContentType;
}

const unloadedState: DrawingMapState = {
  polygonPoints: [],
  shapeIsDragging: false,
  polygonEditable: true,
  sidebarContentType: undefined,
};

export const DrawingMapReducer: Reducer<DrawingMapState, KnownAction> = (
  state = unloadedState,
  action: KnownAction,
) => {
  switch (action.type) {
    case "SET_POLYGON_POINTS":
      return { ...state, polygonPoints: action.payload };
    case "SET_POLYGON_UNEDITABLE":
      return { ...state, polygonEditable: false };
    case "SET_POLYGON_EDITABLE":
      return { ...state, polygonEditable: true };
    case "RESET_DRAWING_MAP_STORE":
      return { ...unloadedState };
    case "SET_DRAWING_SIDEBAR_CONTENT_TYPE":
      return { ...state, sidebarContentType: action.payload };
    default:
      return state || unloadedState;
  }
};
