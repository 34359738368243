import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { GeoPoint, IwContentType } from "dataTypes/maps";
import { BoundingBox } from "dataTypes/properties/propertiesNearbyRequest";

export type MapState = {
  options: google.maps.MapOptions;
  boundingBox?: BoundingBox;
};

export enum MapTilt {
  None = 0,
  Tilted = 45,
}

export interface PropertiesMapState {
  usersLocation: GeoPoint | null;
  mapState: MapState;
  iwContentType: IwContentType;
}

const unloadedState: PropertiesMapState = {
  usersLocation: null,
  mapState: {
    options: {
      zoom: 17,
      center: {
        lat: 32.830432,
        lng: -96.792587,
      },
      mapTypeId: "roadmap",
      tilt: MapTilt.None,
    },
    boundingBox: undefined,
  },
  iwContentType: "value",
};

export const PropertiesMapReducer: Reducer<PropertiesMapState, KnownAction> = (
  state = unloadedState,
  action: KnownAction,
) => {
  switch (action.type) {
    case "SET_USER_LOCATION":
      // geolocation always differs up to a certain decimal. Check to see if this is a significant change before updating
      if (
        state.usersLocation?.latitude.toFixed(3) !== action.payload?.latitude.toFixed(3) ||
        state.usersLocation?.longitude.toFixed(3) !== action.payload?.longitude.toFixed(3)
      ) {
      }
      {
        return { ...state, usersLocation: action.payload };
      }
    case "SET_MAP_STATE":
      return {
        ...state,
        mapState: {
          ...state.mapState,
          options: {
            ...state.mapState.options,
            ...action.payload.options,
          },
          boundingBox: action.payload.boundingBox,
        },
      };
    case "SET_IW_CONTENT_TYPE":
      return { ...state, iwContentType: action.payload };
    default:
      return state || unloadedState;
  }
};
