import { Reducer, AnyAction } from "redux";
import { KnownAction } from "./actionTypes";

export interface Links {
  title: string;
  url: string;
}

export interface SettingsState {
  links: Links[];
}

const unloadedState: SettingsState = {
  links: [],
};

//NOTE: typescript didn't like (state: UserState) in the argument list
export const SettingsReducer: Reducer<SettingsState, KnownAction> = (state = unloadedState, action: KnownAction) => {
  switch (action.type) {
    case "GET_FREE_CALCULATOR_LINKS":
      return { ...state, links: action.payload };
    default:
      return state || unloadedState;
  }
};
