import * as React from "react";
import { Visibility, Transition } from "semantic-ui-react";
import { Link } from "react-scroll";
import DashedLineGroup, { DashedLine } from "../../controls/dashedLineGroup";
import { ContactUsKey } from "../homePageContainer";
import { ContactTopic } from "./contactSection";

interface State {
  elementVisible: boolean;
}

interface Props {
  setTopic: (name: ContactUsKey, value: ContactTopic) => void;
  hiddenX?: number;
  showX?: number;
}

class CustomerExperienceSection extends React.Component<Props, State> {
  state = {
    elementVisible: false,
  };

  handleVisibilityUpdate = (e: any, data: any) => {
    const { elementVisible } = this.state;
    const { topVisible, bottomVisible } = data.calculations;

    if (!elementVisible && (topVisible || bottomVisible)) {
      this.setState({
        elementVisible: true,
      });
    }
  };

  render() {
    const { setTopic } = this.props;
    const { elementVisible } = this.state;

    return (
      <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="customer-featureContainer">
        <div className="customer-feature-container">
          <div className="customer-featureContent-container">
            <Transition visible={elementVisible} animation="fade right" duration={2500}>
              <div className="customer-featuretransition-container">
                <div className="customer-featurecontainer-column-left" />
              </div>
            </Transition>
            <div className="customer-feature-background-container">
              <div className="customer-feature-content">
                <div className="header-2 txt-regular">Supercharged closings</div>
                <div className="subheader-1 txt-semiBold">
                  Access, review and address client information and updates in real time, anywhere.
                </div>
                <div className="p-1 txt-extraLight">
                  Integrated with industry-leading title production systems, use Flueid Pro to seamlessly manage and
                  expedite the production workflow from start to finish.
                </div>
                <div className="customer-feature-subSecMain feature-blocks-grid">
                  <div className="customer-feature-sub feature-block-grid-item">
                    <div className="customer-feature-subIcon"></div>
                    <div className="p-1 txt-semiBold">Fast, Anytime Ordering</div>
                    <div className="p-3 txt-gray">Order services anytime, from anywhere, fast</div>
                  </div>
                  <div className="customer-feature-sub feature-block-grid-item">
                    <div className="customer-feature-subIcon realTime"></div>
                    <div className="p-1 txt-semiBold">Real-time Updates</div>
                    <div className="p-3 txt-gray">
                      Be in the know immediately as tasks are completed or need attention
                    </div>
                  </div>

                  <div className="customer-feature-sub feature-block-grid-item">
                    <div className="customer-feature-subIcon progress"></div>
                    <div className="p-1 txt-semiBold">Progress Tracking</div>
                    <div className="p-3 txt-gray">
                      Track the progress of your orders with intuitive, real-time information
                    </div>
                  </div>

                  <div className="customer-feature-sub feature-block-grid-item">
                    <div className="customer-feature-subIcon closing"></div>
                    <div className="p-1 txt-semiBold">Schedule Closings</div>
                    <div className="p-3 txt-gray">
                      Know exactly when and where the closing will take place and when completed
                    </div>
                  </div>

                  <div className="customer-feature-sub feature-block-grid-item">
                    <div className="customer-feature-subIcon sign"></div>
                    <div className="p-1 txt-semiBold">Electronic Signing</div>
                    <div className="p-3 txt-gray">Sign specific documents digitally and speed up the transaction</div>
                    <div className="p-5 customer-cominSoonSec">COMING SOON</div>
                  </div>

                  <div className="customer-feature-sub feature-block-grid-item">
                    <div className="customer-feature-subIcon vault"></div>
                    <div className="p-1 txt-semiBold">Document Vault</div>
                    <div className="p-3 txt-gray">Safe view, store and manage transaction documents</div>
                  </div>
                </div>
                <div className="subheader-1 txt-extraLight">
                  <span className="header-3">See it in action.</span> Contact us now.
                </div>
                <Link
                  to="flueidProContactForm-text-content"
                  onClick={() => setTopic("topic", ContactTopic.General)}
                  smooth={true}>
                  <div className="customer-featureContactBtn btn-black">Contact Us</div>
                </Link>
              </div>
            </div>
          </div>
          <DashedLineGroup
            visibilityClassName="feature-section-visibility-line-group-1"
            className="feature-section-dashed-line-group-1"
            render={(visible: boolean) => (
              <>
                <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
                <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
                <DashedLine width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
                <DashedLine width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
              </>
            )}
          />
          <DashedLineGroup
            visibilityClassName="feature-section-visibility-line-group-2"
            animationDirection="left"
            className="feature-section-dashed-line-group-2"
            render={(visible: boolean) => (
              <>
                <DashedLine width={269} duration={0.5} delay={0.7} x={"-10vw"} show={visible} />
                <DashedLine width={150} duration={1.5} delay={0} x={"-8vw"} show={visible} />
                <DashedLine width={15} duration={1.5} delay={1} x={"-4vw"} show={visible} />
                <DashedLine width={25} duration={2} delay={1} x={"-1vw"} show={visible} />
              </>
            )}
          />
        </div>
      </Visibility>
    );
  }
}

export default CustomerExperienceSection;
