import { IndustryType } from "../partners";
import { OrderContactType } from "../orders/enums/orderContactType";
import { UserGroup, UserPartnerAssociation } from "./user";

export interface UserLite {
  accountManagerId: number;
  accountManagerName: string;
  address1: string;
  address2: string;
  branchId: number;
  branchName: string;
  city: string;
  companyId: number;
  companyName: string;
  divisionId: number;
  divisionName: string;
  email: string;
  firstName: string;
  fullName: string;
  groups?: UserGroup[];
  industryType: IndustryType;
  jointGroup: number;
  lastName: string;
  middleName: string;
  orderContactType?: OrderContactType;
  partnerId: number;
  partnerName: string;
  phone: string;
  photoUrl: string;
  roleId: number;
  state: string;
  statusDescription: string;
  statusId: number;
  title: string;
  userId: number;
  userName: string;
  zip: string;
  partnerAssociations?: UserPartnerAssociation[];
}

export function newUserLite(): UserLite {
  return {
    userId: 0,
    roleId: 0,
    userName: "",
    industryType: 0,
    firstName: "",
    lastName: "",
    middleName: "",
    fullName: "",
    companyId: 0,
    companyName: "",
    divisionId: 0,
    divisionName: "",
    branchId: 0,
    branchName: "",
    partnerId: 0,
    partnerName: "",
    accountManagerId: 0,
    accountManagerName: "",
    title: "",
    phone: "",
    email: "",
    photoUrl: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    statusId: 0,
    statusDescription: "",
    jointGroup: 0,
  };
}

export interface UserLiteFormInfo extends UserLite {
  //[key: string]: number | IndustryType | string | OrderContactType | undefined;
  [key: string]: any;
}

export const emptyUserLite: UserLite = {
  userId: 0,
  roleId: 0,
  userName: "",
  industryType: 0,
  firstName: "",
  lastName: "",
  middleName: "",
  fullName: "",
  companyId: 0,
  companyName: "",
  divisionId: 0,
  divisionName: "",
  branchId: 0,
  branchName: "",
  partnerId: 0,
  partnerName: "",
  accountManagerId: 0,
  accountManagerName: "",
  title: "",
  phone: "",
  email: "",
  photoUrl: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  statusId: 0,
  statusDescription: "",
  jointGroup: 0,
};
