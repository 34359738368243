import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { Farm, FarmOverview, FarmProperty, FarmType } from "../../dataTypes/farms/index";
import { uniqueID } from "../../utilities/formatters";
import { SortType } from "../../dataTypes/properties";
import {
  RequestStatus,
  newRequestStatus,
  ReqStatus,
  newReqStatus,
  reqOk,
  newPendingReq,
  reqFailed,
} from "../../utilities/apiService";
import Axios, { CancelTokenSource } from "axios";
import { FarmGeoPoint } from "../../dataTypes/farms/farmGeoPoint";

export interface FarmsState {
  farm: Farm | null;
  farmProperties: FarmGeoPoint[];
  searchFarmPropsReqStatus: ReqStatus;
  searchFarmPropsCancelTokenSrc: CancelTokenSource;
  savedFarm: Farm | null;
  saveFarmRequestStatus: RequestStatus;
  farmPropSaveId: string;
  invalidFarmSearch: boolean;
  savedFarms: Farm[];
  getAllFarmsReqStatus: ReqStatus;
  farmsSortType: SortType;
  farmOverview: FarmOverview | null;
  selectedFarmPropId?: number;
  hoveredFarmPropId?: number;
  renameFarmSuccessId: string;
  sortType: SortType;
  farmType: FarmType;
  getFarmReqStatus: ReqStatus;
  getFarmCancelTokenSource: CancelTokenSource;
  fixNotFoundPropertyReqStatus: ReqStatus;
  addPropertyReqStatus: ReqStatus;
  deletePropertyReqStatus: ReqStatus;
}

const unloadedState: FarmsState = {
  farm: null,
  farmProperties: [],
  searchFarmPropsReqStatus: newReqStatus(),
  searchFarmPropsCancelTokenSrc: Axios.CancelToken.source(),
  savedFarm: null,
  saveFarmRequestStatus: newRequestStatus(),
  farmPropSaveId: "",
  invalidFarmSearch: false,
  savedFarms: [],
  getAllFarmsReqStatus: newReqStatus(),
  farmsSortType: "Created Newest",
  farmOverview: null,
  renameFarmSuccessId: "",
  sortType: "Address",
  farmType: FarmType.Undefined,
  getFarmReqStatus: newReqStatus(),
  getFarmCancelTokenSource: Axios.CancelToken.source(),
  fixNotFoundPropertyReqStatus: newReqStatus(),
  addPropertyReqStatus: newReqStatus(),
  deletePropertyReqStatus: newReqStatus(),
};

export const FarmsReducer: Reducer<FarmsState, KnownAction> = (state = unloadedState, action: KnownAction) => {
  switch (action.type) {
    case "SEARCH_FARM_PROPS_PENDING":
      return { ...state, searchFarmPropsReqStatus: newPendingReq(), searchFarmPropsCancelTokenSrc: action.payload };
    case "SEARCH_FARM_PROPERTIES":
      return { ...state, farmProperties: action.payload, searchFarmPropsReqStatus: reqOk(), invalidFarmSearch: false };
    case "SEARCH_FARM_PROPS_DOWNLOAD_PROGRESS":
      return {
        ...state,
        searchFarmPropsReqStatus: {
          ...state.searchFarmPropsReqStatus,
          downloadProgress: action.payload,
        },
      };
    case "SEARCH_FARM_PROPS_CANCEL":
      state.searchFarmPropsCancelTokenSrc.cancel();
      return { ...state, searchFarmPropsReqStatus: newReqStatus() };
    case "SEARCH_FARM_PROPS_FAILED":
      return { ...state, searchFarmPropsReqStatus: reqFailed() };

    case "CLEAR_FARM_PROPERTIES":
      return { ...state, farmProperties: [] };

    case "GET_FARM_PENDING":
      return { ...state, getFarmReqStatus: newPendingReq(), getFarmCancelTokenSource: action.cancelTokenSource };
    case "GET_FARM":
      return {
        ...state,
        farm: action.payload,
        farmOverview: action.payload.overview,
        getFarmReqStatus: reqOk(),
      };
    case "GET_FARM_DOWNLOAD_PROGRESS":
      return {
        ...state,
        getFarmReqStatus: {
          ...state.getFarmReqStatus,
          downloadProgress: action.payload,
        },
      };
    case "GET_FARM_FAILED":
      return { ...state, getFarmReqStatus: reqFailed() };
    case "GET_FARM_CANCEL":
      state.getFarmCancelTokenSource.cancel();
      return { ...state, getFarmReqStatus: newReqStatus() };

    case "CLEAR_FARM":
      return { ...state, farm: null, farmOverview: null, selectedFarmPropId: undefined };
    case "GET_FARM_OVERVIEW":
      return { ...state, farmOverview: action.payload };

    case "SAVE_FARM_PROPERTY":
      // find the newly saved farmProp in list of farmProperties and update it's label
      if (state.farm) {
        const updatedFarmPropList: FarmProperty[] = [...state.farm.properties].map((x: FarmProperty) => {
          // directly mutating this farmProperty is the only way that seems to solve the stale data that's in
          // farmPropMarker's filter comparison function
          if (x.propertyId === action.payload.propertyId) {
            x.label = action.payload.label;
            x.customer = action.payload.customer;
            x.notes = action.payload.notes;
          }
          return x;
        });

        state.farm.properties = updatedFarmPropList;

        return {
          ...state,
          farm: state.farm,
          farmPropSaveId: uniqueID(),
        };
      } else return { ...state };

    case "SET_SELECTED_FARM_PROP_ID":
      return {
        ...state,
        selectedFarmPropId: action.payload,
      };

    case "SAVE_FARM_REQUEST_PENDING":
      return {
        ...state,
        saveFarmRequestStatus: {
          ...state.saveFarmRequestStatus,
          pending: true,
        },
      };
    case "SAVE_FARM_REQUEST_FAILED":
      return {
        ...state,
        saveFarmRequestStatus: {
          pending: false,
          successId: null,
          failureId: uniqueID(),
        },
      };
    case "SAVE_FARM":
      return {
        ...state,
        savedFarm: action.payload,
        saveFarmRequestStatus: {
          pending: false,
          successId: uniqueID(),
          failureId: null,
        },
      };

    case "GET_ALL_FARMS_PENDING":
      return { ...state, getAllFarmsReqStatus: newPendingReq() };
    case "GET_ALL_FARMS":
      return { ...state, savedFarms: action.payload, getAllFarmsReqStatus: reqOk() };
    case "GET_ALL_FARMS_FAILED":
      return { ...state, getAllFarmsReqStatus: reqFailed() };
    case "SET_FARMS_SORT_TYPE":
      return { ...state, farmsSortType: action.payload };
    case "PROPS_EXCEED_LIMITS":
      return { ...state, invalidFarmSearch: true };

    case "TOO_FEW_POINTS_DEFINED":
      return { ...state, invalidFarmSearch: true };
    case "RESET_FARM_STORE":
      return { ...unloadedState };
    case "DELETE_FARM":
      const updatedFarms = [...state.savedFarms].filter((farm: Farm) => farm.farmId !== action.payload);
      return {
        ...state,
        farm: state.farm?.farmId === action.payload ? null : state.farm,
        farmOverview: state.farm?.farmId === action.payload ? null : state.farmOverview,
        savedFarms: updatedFarms,
      };

    case "RENAME_FARM":
      let renamedFarm = null;

      if (state.farm) {
        renamedFarm = { ...state.farm };

        if (renamedFarm && renamedFarm.farmId === action.payload.farmId) {
          renamedFarm.farmName = action.payload.farmName;
        }
      }

      // update the savedFarms list
      const updatedFarmsList = [...state.savedFarms];
      let index = 0;

      for (let i = 0; i < updatedFarmsList.length; i++) {
        if (updatedFarmsList[i].farmId === action.payload.farmId) index = i;
      }

      const farmCopy = { ...updatedFarmsList[index] };
      farmCopy.farmName = action.payload.farmName;
      updatedFarmsList.splice(index, 1, farmCopy);

      return {
        ...state,
        farm: renamedFarm,
        savedFarms: updatedFarmsList,
        renameFarmSuccessId: uniqueID(),
      };

    case "SET_SORT_TYPE":
      return { ...state, sortType: action.payload };

    case "SET_FARM_TYPE":
      return { ...state, farmType: action.payload };

    case "FIX_NOT_FOUND_PROPERTY_PENDING":
      return { ...state, fixNotFoundPropertyReqStatus: newPendingReq() };
    case "FIX_NOT_FOUND_PROPERTY_FOR_FARM":
      return {
        ...state,
        farm: action.payload,
        farmOverview: action.payload.overview,
        fixNotFoundPropertyReqStatus: reqOk(),
      };
    case "FIX_NOT_FOUND_PROPERTY_FAILED":
      return { ...state, fixNotFoundPropertyReqStatus: reqFailed() };

    case "ADD_PROPERTY_PENDING":
      return { ...state, addPropertyReqStatus: newPendingReq() };
    case "ADD_PROPERTY_TO_FARM":
      return { ...state, farm: action.payload, farmOverview: action.payload.overview, addPropertyReqStatus: reqOk() };
    case "ADD_PROPERTY_FAILED":
      return { ...state, addPropertyReqStatus: reqFailed() };

    case "DELETE_PROPERTY_PENDING":
      return { ...state, deletePropertyReqStatus: newPendingReq() };
    case "DELETE_PROPERTY_FROM_FARM":
      return {
        ...state,
        farm: action.payload,
        farmOverview: action.payload.overview,
        deletePropertyReqStatus: reqOk(),
        selectedFarmProp: null,
      };
    case "DELETE_PROPERTY_FAILED":
      return { ...state, deletePropertyReqStatus: reqFailed() };
    case "SET_HOVERED_FARM_PROP_ID":
      return { ...state, hoveredFarmPropId: action.payload };
    default:
      return state || unloadedState;
  }
};
