import { ValidationType } from "../enums/validationType";
import Validate from "../../../utilities/formFieldValidator";
import { get, cloneDeep } from "lodash";

export interface ValidatedField {
  required: boolean;
  valid: boolean;
  validationTypes: ValidationType[];
  customValidationPattern?: RegExp;
}

export function newValidatedField(
  required: boolean,
  validationTypes: ValidationType[],
  customValidationPattern?: RegExp,
): ValidatedField {
  const newField: ValidatedField = { required, valid: true, validationTypes };
  if (customValidationPattern) newField.customValidationPattern = customValidationPattern;
  return newField;
}

function isValidatedField(field: ValidatedField | undefined): field is ValidatedField {
  return field !== undefined && "valid" in field;
}

export function setValidatedFieldTrue<T>(
  validation: ValidationSchemaGeneric<T>,
  name: keyof T,
): ValidationSchemaGeneric<T> {
  let updatedValidation = cloneDeep(validation);
  let field: ValidatedField | undefined = updatedValidation[name];
  if (field && !field.valid) {
    field = { ...field, valid: true };
    updatedValidation[name] = field;
  }
  return updatedValidation;
}

export interface ValidationSchema {
  [key: string]: ValidatedField;
}

//--------------------
// Anything in this file with "Generic" at the end of the name is related to this new approach of passing a generic T
//  it helps make the key names of ValidationSchemaGeneric match key names of the provided type T object
//  giving dev the coveted auto-completion for key names and helping prevent misspellings
//  TODO - create a separate ticket to add this generics approach to the original ValidationSchema - will create lots of breaking changes
export type ValidationSchemaGeneric<T> = {
  [Key in keyof T]?: ValidatedField;
};

// pass in an object that has the values and the validation schema
export function validateSchema(object: { [key: string]: any }, schema: ValidationSchema): ValidationSchema {
  const updatedSchema: ValidationSchema = cloneDeep(schema);
  // iterate through keys of validation schema
  for (const key in updatedSchema) {
    // grab the value off the object
    const value = get(object, key);
    // validate it
    if (!validateField(updatedSchema[key], value)) {
      updatedSchema[key].valid = false;
    } else {
      updatedSchema[key].valid = true;
    }
  }
  return updatedSchema;
}

// pass in an object that has the values and the validation schema
export function validateSchemaGeneric<T>(
  object: { [key in keyof T]?: any },
  schema: ValidationSchemaGeneric<T>,
): ValidationSchemaGeneric<T> {
  const updatedSchema: ValidationSchemaGeneric<T> = cloneDeep(schema);
  // iterate through keys of validation schema
  for (const key in updatedSchema) {
    // grab the value off the object
    const value = get(object, key);
    // validate it
    const field = updatedSchema[key];
    if (isValidatedField(field)) {
      validateField(field, value) ? (field.valid = true) : (field.valid = false);
    }
  }
  return updatedSchema;
}

// quick way to check if any fields are valid, will break when first false value hit
export function allSchemaFieldsValid(schema: ValidationSchema): boolean {
  let allFieldsValid: boolean = true;
  for (const key in schema) {
    if (!schema[key].valid) {
      allFieldsValid = false;
      break;
    }
  }
  return allFieldsValid;
}

// quick way to check if any fields are valid, will break when first false value hit
export function allSchemaFieldsValidGeneric<T>(schema: ValidationSchemaGeneric<T>): boolean {
  let allFieldsValid: boolean = true;
  for (const key in schema) {
    const field = schema[key];
    if (isValidatedField(field) && !field.valid) {
      allFieldsValid = false;
      break;
    }
  }
  return allFieldsValid;
}

export function validateField(field: ValidatedField, value: any): boolean {
  // it's not required and there's no value, then it's considered valid
  if (!field.required && !value) return true;

  let fieldValid: boolean = true;

  // go through all validation types
  for (let i = 0; i < field.validationTypes.length; i++) {
    let validationTypePassed: boolean = true;

    // validate the value
    switch (field.validationTypes[i]) {
      case ValidationType.Email:
        validationTypePassed = Validate.Email(value);
        break;
      case ValidationType.Phone:
        validationTypePassed = Validate.Phone(value);
        break;
      case ValidationType.SSN:
        validationTypePassed = Validate.SSN(value);
        break;
      case ValidationType.Zip:
        validationTypePassed = Validate.Zipcode(value);
        break;
      case ValidationType.TaxId:
        validationTypePassed = Validate.TaxId(value);
        break;
      case ValidationType.Value:
        validationTypePassed = Validate.Value(value);
        break;
      case ValidationType.NotNullOrUndefined:
        validationTypePassed = Validate.NotNullOrUndefined(value);
        break;
      case ValidationType.NotEmptyArray:
        validationTypePassed = Array.isArray(value) && value.length > 0;
        break;
      case ValidationType.CustomPattern:
        if (field.customValidationPattern) {
          validationTypePassed = Validate.CustomPattern(field.customValidationPattern, value);
          break;
        }
        break;
      default:
        return true;
    }

    // field invalid, break loop
    if (!validationTypePassed) {
      fieldValid = false;
      break;
    }
  }

  return fieldValid;
}
