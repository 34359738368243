import { createStore, applyMiddleware, compose, combineReducers, StoreEnhancer, Store } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { History } from "history";
import * as StoreModule from "./index";
import { ApplicationState, reducers } from "./index";

declare var require: any;
declare var module: any;

export default function configureStore(history: History, initialState?: ApplicationState) {
  // Build middleware. These are functions that can process the actions before they reach the store.
  const windowIfDefined = typeof window === "undefined" ? null : (window as any);

  //==========================
  // If devTools is installed, connect to it
  const devToolsExtension =
    windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__
      ? (windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 }) as () => StoreEnhancer)
      : (f: any) => f;
  //============================

  const createStoreWithMiddleware = compose(
    applyMiddleware(thunk, routerMiddleware(history)),
    devToolsExtension,
  )(createStore);

  //===========================
  // Combine all reducers and instantiate the app-wide store instance
  //	- first combine the app's top level reducers and add router/history to this top level
  const allReducers = buildAllReducers(reducers, history);
  // this rootReducer is for app wide actions only
  const rootReducer = (state: any, action: any) => {
    if (action.type === "CLEAR_REDUX_STORE") {
      // omitting state fields will consider them undefined, which triggers redux to return their initial values
      // but preserve router state so on logout, initial router path doesn't override push to logout page
      state = { router: { ...state.router } };
    }
    return allReducers(state, action);
  };
  const store = (<any>createStoreWithMiddleware)(rootReducer, initialState) as Store<ApplicationState>;

  // Enable Webpack hot module replacement for reducers
  if ((module as any).hot) {
    (module as any).hot.accept("./index", () => {
      const nextRootReducer = require("./index");
      store.replaceReducer(buildAllReducers(nextRootReducer.reducers, history));
    });
  }

  return store;
}

function buildAllReducers(allReducers: any, history: any) {
  return combineReducers<ApplicationState>((<any>Object).assign({}, allReducers, { router: connectRouter(history) }));
}
