import * as React from "react";

interface Props {
  show: boolean;
  className?: string;
}
export const FlueidProBanner: React.FC<Props> = ({ show, children, className }) => {
  return (
    <div className={`flueid-pro-banner ${show ? "show" : "hide"} ${className || ""}`}>
      <div className="flueid-pro-banner_padding navbar-width">{children}</div>
    </div>
  );
};
