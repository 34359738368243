import { Partner } from "dataTypes/partners";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthNavBar, { AuthNavCloseBtn, AuthNavHeader } from "../authNavbar";
import { getAuthPath } from "../types/authPath";
import AuthFormButton from "./button";
import { AuthFormLogo } from "./logos";
import { LoadingDots } from "components/controls/loadingDots";

const FADE_CLASS_TIMEOUT = 3500;
const REDIRECT_TIMEOUT = FADE_CLASS_TIMEOUT + 500;

const LogoutSuccess: React.FC<{ partner?: Partner }> = ({ partner }) => {
  const history = useHistory();
  const [fadeClass, setFadeClass] = useState("fade-in");

  // schedule fade out
  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeClass("fade-out");
    }, FADE_CLASS_TIMEOUT);

    // clear timer on unmount
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // schedule redirect to website
  useEffect(() => {
    const timeout = setTimeout(() => {
      partner?.websiteUrl ? window.location.assign(partner.websiteUrl) : history.push("/"); // history.push for FP homepage makes faster navigation
    }, REDIRECT_TIMEOUT);

    // clear timer on unmount
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={fadeClass}>
      <AuthNavBar>
        <AuthNavHeader>Sign In</AuthNavHeader>
        <AuthNavCloseBtn websiteUrl={partner?.websiteUrl} />
      </AuthNavBar>
      <div className="form-container auth-form-container">
        <div className="content">
          <AuthFormLogo partner={partner} />
          <h5 className="header-5 boldest header m-b_sm">You have been logged out.</h5>
          <div>
            Returning to website
            <LoadingDots />
          </div>
          {partner?.websiteUrl ? (
            <a href={partner.websiteUrl}>
              <AuthFormButton>Close</AuthFormButton>
            </a>
          ) : (
            <Link to="/">
              <AuthFormButton>Close</AuthFormButton>
            </Link>
          )}
          <Link to={getAuthPath("/auth/sign-in", partner)} className="m-t_md">
            <u>Sign In</u>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LogoutSuccess;
