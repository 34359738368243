import { OrderType } from "../enums/orderType";
import { OrderStatus } from "../enums/orderStatus";
import { OrderServiceType } from "../enums/orderServiceType";

export interface OrderSearchRequest {
  orderTypes: OrderType[];
  orderServiceTypes: OrderServiceType[];
  orderStatuses: OrderStatus[];
  searchText: string;
  minOpenDate: string;
  maxOpenDate?: string;
  minCloseDate?: string;
  maxCloseDate?: string;
  sortBy: OrdersSortBy;
  pageSize: number;
  pageIndex: number;
}

export enum OrdersSortBy {
  OpenedDateDesc = 1,
  OpenedDateAsc = 2,
  EstimatedCloseDateDesc = 3,
  EstimatedCloseDateAsc = 4,
  PropertyAddressAsc = 5,
  PropertyAddressDesc = 6,
  OwnerNameAsc = 7,
  OwnerNameDesc = 8,
  ClosedDateDesc = 9,
  ClosedDateAsc = 10,
  CanceledDateDesc = 11,
  CanceledDateAsc = 12,
}
