import { MarkerTypes } from "components/titlefyPro/googleMaps/utilities/markerIconFinder";
import { GeoPoint } from "../maps";
import { PropertyDetailLite } from "../properties/propertyDetailLite";
import { FarmUploadMapping, newFarmUploadMapping } from "./farmUploadMapping";
import { FarmUploadProperty } from "./farmUploadProperty";

// max num of props per farm
export const MAX_FARM_PROPS: number = 4000;

export enum FarmType {
  Polygon,
  Upload,
  Undefined,
}

export interface Farm {
  farmId: number;
  userId: number;
  type: FarmType;
  farmName: string;
  city: string;
  state: string;
  zipCode: string;
  areaPolygonPoints: GeoPoint[];
  centerPoint: GeoPoint;
  propertyCount: number;
  hotPropertyCount: number;
  overview: FarmOverview;

  lastStatsUpdate: string | null;
  // TODO - create the EntityUpdate interface
  updates: any[];
  mostRecentUpdate: string | null;

  uploadExcelFilePath: string;
  uploadProcessed: boolean;
  uploadViewed: boolean;
  uploadMapping: FarmUploadMapping;
  uploadPropertyIds: number[];
  uploadNotFoundProperties: FarmUploadProperty[];

  properties: FarmProperty[];

  // doesn't look to be in the Farm.cs class anymore...BUT it still comes through
  createdDate: string;
}

export function newFarm(): Farm {
  return {
    farmId: 0,
    userId: 0,
    type: FarmType.Undefined,
    farmName: "",
    city: "",
    state: "",
    zipCode: "",
    areaPolygonPoints: [],
    centerPoint: { latitude: 0, longitude: 0 },
    propertyCount: 0,
    hotPropertyCount: 0,
    overview: newFarmOverview(),
    lastStatsUpdate: null,
    updates: [],
    mostRecentUpdate: null,
    uploadExcelFilePath: "",
    uploadProcessed: false,
    uploadViewed: false,
    uploadMapping: newFarmUploadMapping(),
    uploadPropertyIds: [],
    uploadNotFoundProperties: [],
    properties: [],
    createdDate: "",
  };
}

export interface FarmOverview {
  totalProperties: number;
  totalHotProperties: number;
  totalOwnerOccupied: number;
  totalNonOwnerOccupied: number;
  totalSoldPast12Months: number;
  avgLengthOfStay: number;
  pctWithPositiveEquity: number;
  avgEquity: number;
  avgValuation: number;
  avgSqFt: number;
  avgAgeOfProperty: number;
}

export function newFarmOverview(): FarmOverview {
  return {
    totalProperties: 0,
    totalHotProperties: 0,
    totalOwnerOccupied: 0,
    totalNonOwnerOccupied: 0,
    totalSoldPast12Months: 0,
    avgLengthOfStay: 0,
    pctWithPositiveEquity: 0,
    avgEquity: 0,
    avgValuation: 0,
    avgSqFt: 0,
    avgAgeOfProperty: 0,
  };
}

export interface FarmProperty {
  farmId: number;
  propertyId: number;
  label: FarmPropertyLabel;
  notes: string;
  customer: FarmPropertyCustomer;
  propertyInfo: PropertyDetailLite;
  lastUpdatedDate: string;
}

export enum FarmPropertyLabel {
  Undefined = 0,
  Lead = 1,
  VisitProspect = 2,
  DoNotContact = 3,
}

export function getFarmPropLabel(farmProp: FarmProperty): keyof typeof FarmPropertyLabel | MarkerTypes | undefined {
  if (farmProp.label === FarmPropertyLabel.DoNotContact) return "DoNotContact";
  if (farmProp.label === FarmPropertyLabel.Lead) return "Lead";
  if (farmProp.label === FarmPropertyLabel.VisitProspect) return "VisitProspect";
  if (farmProp.propertyInfo.hot) return "FARM_HOT";
}

export interface FarmPropertyCustomer {
  firstName: string;
  lastName: string;
  email: string;
  phoneType: string;
  phoneNumber: string;
  owner: boolean;
  renter: boolean;
  buyer: boolean;
  seller: boolean;
}

export interface RenameFarmRequest {
  farmId: number;
  farmName: string;
}

export function setFarmPropsNotHot(farm: Farm): Farm {
  const properties: FarmProperty[] = [...farm.properties].map((x) => {
    return {
      ...x,
      propertyInfo: {
        ...x.propertyInfo,
        hot: false,
      },
    };
  });

  return { ...farm, properties };
}
