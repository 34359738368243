import { toast, ToastOptions } from "react-toastify";

toast.configure({
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
});

export class notify {
  public static error(message: string = "Oops, something went wrong.", autoClose?: { autoClose: number }): void {
    toast.error(message, autoClose ? autoClose : {});
  }

  public static success(message: string, options?: ToastOptions): void {
    toast.success(message, options);
  }

  public static info(message: string, options?: ToastOptions): void {
    toast.info(message, options);
  }
}
