import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { PropertyDetailLite } from "../../dataTypes/properties/propertyDetailLite";
import {
  PropertySearchSuggestion,
  PropSearchSuggestionCategories,
  SuggestionSearchType,
  Suggestiontype,
} from "../../dataTypes/properties/propertySearchSuggestion";
import { SortType } from "../../dataTypes/properties";
import { PropertyTransactionHistoryItem } from "../../dataTypes/properties/transactionHistory";
import { MarketAnalysis } from "../../dataTypes/properties/propertyDetail/marketAnalysis";
import { PropertyDetail } from "../../dataTypes/properties/propertyDetail/propertyDetail";
import { ReqStatus, newReqStatus, newPendingReq, reqFailed, reqOk } from "../../utilities/apiService";
import { CancelTokenSource } from "axios";

export interface PropertiesState {
  activeSavedMapItemId?: number;
  propertiesNearby: PropertyDetailLite[];
  savedProps: PropertyDetailLite[];
  propertyDetail: PropertyDetail | null;
  propertyDetailReqStatus: ReqStatus;
  propertySearchSuggestions: PropSearchSuggestionCategories;
  propSearchSuggestionsFound: boolean;
  propsNearbyRequestLoading: boolean;
  propsNearbyCancelTokenSrc?: CancelTokenSource;
  selectedSearchSuggestion?: PropertySearchSuggestion;
  selectedPropertyId?: number;
  propertyLocationStatistics: MarketAnalysis | null;
  propertyTransactionHistory: PropertyTransactionHistoryItem[] | null;
  savedSortType: SortType;
  propsSortType: SortType;
  suggestionSearchType: SuggestionSearchType;
  propertySearchSuggestionsLoading: boolean;
  propertySearchSuggestionsCancelTokenSrc?: CancelTokenSource;
}

const unloadedState: PropertiesState = {
  propertiesNearby: [],
  activeSavedMapItemId: undefined,
  savedProps: [],
  propertyDetail: null,
  propertyDetailReqStatus: newReqStatus(),
  propertyLocationStatistics: null,
  propertyTransactionHistory: null,
  propertySearchSuggestions: {
    addressSuggestions: [],
    ownerSuggestions: [],
    placeSuggestions: [],
    apnSuggestions: [],
  },
  propSearchSuggestionsFound: true,
  propsNearbyRequestLoading: false,
  selectedSearchSuggestion: undefined,
  selectedPropertyId: undefined,
  savedSortType: "Address",
  propsSortType: "Closest",
  suggestionSearchType: SuggestionSearchType.AddressesSuggestion,
  propertySearchSuggestionsLoading: false,
};

//NOTE: typescript didn't like (state: PropertiesState) in the argument list
export const PropertiesReducer: Reducer<PropertiesState, KnownAction> = (
  state = unloadedState,
  action: KnownAction,
) => {
  switch (action.type) {
    case "PROPS_NEARBY_REQUEST_LOADING":
      return { ...state, propsNearbyRequestLoading: true, propsNearbyCancelTokenSrc: action.payload };
    case "GET_PROPERTIES_NEARBY":
      return {
        ...state,
        propertiesNearby: action.payload,
        propsNearbyRequestLoading: false,
      };

    case "SET_ACTIVE_SAVED_MAP_ITEM_ID":
      return { ...state, activeSavedMapItemId: action.payload };

    case "GET_ALL_SAVED_PROPERTIES":
      return {
        ...state,
        savedProps: action.payload,
      };

    // PROPERTY DETAIL REQUEST
    case "GET_PROPERTY_DETAIL_PENDING":
      return { ...state, propertyDetailReqStatus: newPendingReq() };
    case "GET_PROPERTY_DETAIL":
      return { ...state, propertyDetail: action.payload, propertyDetailReqStatus: reqOk() };
    case "GET_PROPERTY_DETAIL_FAILED":
      return { ...state, propertyDetailReqStatus: reqFailed() };

    case "GET_PROPERTY_TRANSACTION_HISTORY":
      const sortedHistoryByDate = [...action.payload].sort(function (
        a: PropertyTransactionHistoryItem,
        b: PropertyTransactionHistoryItem,
      ) {
        return +new Date(b.recordingDate) - +new Date(a.recordingDate);
      });
      return { ...state, propertyTransactionHistory: sortedHistoryByDate };

    case "CLEAR_PROP_TRANSACTION_HISTORY":
      return { ...state, propertyTransactionHistory: null };

    case "CLEAR_PROPERTY_DETAIL":
      return { ...state, propertyDetail: null };

    case "GET_LOCATION_STATISTICS":
      return { ...state, propertyLocationStatistics: action.payload };

    case "CLEAR_LOCATION_STATS":
      return { ...state, propertyLocationStatistics: null };

    case "SET_SELECTED_PROPERTY_ID":
      return { ...state, selectedPropertyId: action.payload };

    case "SET_PROPERTY_AS_SAVED":
      // the API doesn't return any new data, so we have to change the isSaved value here manually after a successful response from the API

      const propDetailSaved = { ...state.propertyDetail } as PropertyDetail | null;
      if (propDetailSaved?.propertyId === action.payload) {
        propDetailSaved.isSaved = true;
      }
      // update prop in properties nearby
      const updatedSavedPropsNearby = [...state.propertiesNearby];
      const savedProp = updatedSavedPropsNearby.find((x) => x.propertyId === action.payload);
      if (savedProp) {
        savedProp.saved = true;
        const i = updatedSavedPropsNearby.findIndex((x) => x.propertyId === savedProp.propertyId);
        updatedSavedPropsNearby.splice(i, 1, savedProp);
      }

      return {
        ...state,
        propertyDetail: propDetailSaved,
        propertiesNearby: updatedSavedPropsNearby,
      };

    case "SET_PROPERTY_AS_UNSAVED":
      const propDetailUnsaved = { ...state.propertyDetail } as PropertyDetail | null;
      if (propDetailUnsaved?.propertyId === action.payload) {
        propDetailUnsaved.isSaved = false;
      }

      // update prop in properties nearby
      const updatedUnsavedPropsNearby = [...state.propertiesNearby];
      const unsavedProp = updatedUnsavedPropsNearby.find((x) => x.propertyId === action.payload);
      if (unsavedProp) {
        unsavedProp.saved = false;
        const i = updatedUnsavedPropsNearby.findIndex((x) => x.propertyId === unsavedProp.propertyId);
        updatedUnsavedPropsNearby.splice(i, 1, unsavedProp);
      }

      // remove from saved props
      const updatedSavedList = [...state.savedProps].filter((x) => x.propertyId !== action.payload);

      return {
        ...state,
        propertyDetail: propDetailUnsaved,
        propertiesNearby: updatedUnsavedPropsNearby,
        savedProps: updatedSavedList,
      };

    //*****************
    // SEARCH RELATED ACTIONS
    case "SET_SUGGESTION_SEARCH_TYPE":
      return {
        ...state,
        suggestionSearchType: action.payload,
      };
    case "SET_PROPERTY_SEARCH_SUGGESTIONS_LOADING":
      return {
        ...state,
        propertySearchSuggestionsLoading: action.payload,
      };
    case "SET_PROPERTY_SEARCH_SUGGESTIONS_CANCEL_TOKEN_SRC":
      return {
        ...state,
        propertySearchSuggestionsCancelTokenSrc: action.payload,
      };
    case "GET_PROPERTY_SEARCH_SUGGESTIONS":
      const addressSuggestions: PropertySearchSuggestion[] = action.payload.filter(
        (suggestion: PropertySearchSuggestion) => suggestion.suggestionType === Suggestiontype.Address,
      );
      const ownerSuggestions: PropertySearchSuggestion[] = action.payload.filter(
        (suggestion: PropertySearchSuggestion) => suggestion.suggestionType === Suggestiontype.Owner,
      );
      const placeSuggestions: PropertySearchSuggestion[] = action.payload.filter(
        (suggestion: PropertySearchSuggestion) =>
          suggestion.suggestionType === Suggestiontype.City || suggestion.suggestionType === Suggestiontype.Zip,
      );
      const apnSuggestions: PropertySearchSuggestion[] = action.payload.filter(
        (suggestion: PropertySearchSuggestion) => suggestion.suggestionType === Suggestiontype.Apn,
      );

      let propSearchSuggestionsFound: boolean = true;

      if (
        !addressSuggestions.length &&
        !ownerSuggestions.length &&
        !placeSuggestions.length &&
        !apnSuggestions.length
      ) {
        propSearchSuggestionsFound = false;
      }

      return {
        ...state,
        propSearchSuggestionsFound: propSearchSuggestionsFound,
        propertySearchSuggestions: {
          addressSuggestions: addressSuggestions,
          ownerSuggestions: ownerSuggestions,
          placeSuggestions: placeSuggestions,
          apnSuggestions: apnSuggestions,
        },
      };
    case "CLEAR_PROPERTY_SEARCH_SUGGESTIONS":
      return {
        ...state,
        propertiesNearby: [...state.propertiesNearby],
        propertySearchSuggestions: {
          addressSuggestions: [],
          ownerSuggestions: [],
          placeSuggestions: [],
          apnSuggestions: [],
        },
      };

    case "SET_SELECTED_SEARCH_SUGGESTION":
      return {
        ...state,
        selectedSearchSuggestion: action.payload,
      };

    case "RESET_PROP_SEARCH_SUGGESTIONS_FOUND":
      return { ...state, propertiesNearby: [...state.propertiesNearby], propSearchSuggestionsFound: true };

    case "SET_SAVED_SORT_TYPE":
      return {
        ...state,
        savedSortType: action.payload,
      };

    case "SET_PROPS_SORT_TYPE":
      return {
        ...state,
        propsSortType: action.payload,
      };

    default:
      return state || unloadedState;
  }
};
