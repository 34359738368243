import * as React from "react";
import { Visibility } from "semantic-ui-react";

interface DashedLineProps {
  width: number; // in pixels, gets set as maxWidth
  x: string; // transform: translateX(x)
  duration: number; // in seconds
  delay: number; // in seconds
  show: boolean;
  // optional
  color?: "black" | "white";
  className?: string;
  id?: string;
  style?: React.CSSProperties;
}

export const DashedLine = ({ style, className, id, width, x, delay, duration, show, color }: DashedLineProps) => {
  const baseStyles: React.CSSProperties = {
    transition: `all ${duration}s ease-out`,
    transitionDelay: `${delay}s`,
    maxWidth: `${width}px`,
  };

  const animationStyles: React.CSSProperties = {
    transform: `translateX(${x})`,
    opacity: 1,
  };

  let finalStyles = { ...baseStyles, ...style };
  if (color) finalStyles = { ...finalStyles, backgroundColor: color };
  if (show) finalStyles = { ...finalStyles, ...animationStyles };
  return <div style={finalStyles} className={`dashed-group-item ${className || ""}`} id={id} />;
};

interface Props {
  render: (visible: boolean) => React.ReactNode;
  className?: string;
  showOnMount?: boolean;
  animationDirection?: "left" | "right";
  visibilityClassName?: string;
}

interface State {
  visible: boolean;
}

class DashedLineGroup extends React.Component<Props, State> {
  state: State = {
    visible: false,
  };

  componentDidMount() {
    if (this.props.showOnMount) {
      setTimeout(() => {
        this.setState({ visible: true });
      }, 1000);
    }
  }

  render() {
    const { className, animationDirection, visibilityClassName } = this.props;
    return (
      <Visibility
        className={`dashed-line-group-container-visibility ${visibilityClassName || ""}`}
        onTopVisible={() => {
          setTimeout(() => {
            this.setState({ visible: true });
          }, 1000);
        }}>
        <div
          className={`
                        dashed-line-group-container
                        ${className || ""} ${animationDirection || ""}
                        ${this.state.visible ? "show" : "hide"}`}>
          {this.props.render(this.state.visible)}
        </div>
      </Visibility>
    );
  }
}

export default DashedLineGroup;
