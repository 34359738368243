import * as contentful from "contentful";
import AppConfig from "./appConfig";

const { REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_API_KEY, REACT_APP_CONTENTFUL_PREVIEW_API_KEY } =
  process.env;

// get env and determine whether to use main or preview contentful api key
const appEnv = AppConfig.Environment();
const accessToken = appEnv === "production" ? REACT_APP_CONTENTFUL_API_KEY : REACT_APP_CONTENTFUL_PREVIEW_API_KEY;
const host = appEnv === "production" ? "cdn.contentful.com" : "preview.contentful.com";

const contentfulClient = contentful.createClient({
  space: REACT_APP_CONTENTFUL_SPACE_ID || "",
  accessToken: accessToken || "",
  host,
});

export default contentfulClient;
