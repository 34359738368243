export enum ValidationType {
  Undefined,
  Value,
  Email,
  Phone,
  SSN,
  Zip,
  TaxId,
  NotNullOrUndefined,
  CustomPattern,
  NotEmptyArray,
}
