import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import {
  BedroomFilterOptions,
  ValueFilterOptions,
  BathroomFilterOptions,
  SqFtFilterOptions,
} from "../../dataTypes/filters/filterOptions";

export interface StatusFilters {
  hotActive: boolean;
  savedActive: boolean;
  forSaleActive: boolean;
  pendingActive: boolean;
  recentlySoldActive: boolean;
  offMarketActive: boolean;
}

export interface PropertyTypeFilters {
  singleFamilyActive: boolean;
  condoMultiFamActive: boolean;
  townhouseActive: boolean;
  multiplexActive: boolean;
  lotLandActive: boolean;
  manufMobileActive: boolean;
  commercialActive: boolean;
}

export interface FiltersState {
  showHotPropsOnly: boolean;
  statusFilters: StatusFilters;
  propertyTypeFilters: PropertyTypeFilters;
  minBedrooms: BedroomFilterOptions;
  minValue: ValueFilterOptions;
  maxValue: ValueFilterOptions;
  minBathrooms: BathroomFilterOptions;
  minSqFt: SqFtFilterOptions;
  maxSqFt: SqFtFilterOptions;
  minYearBuilt: number;
  maxYearBuilt: number;
  savedModeFilterOn: boolean;
}

const unloadedState: FiltersState = {
  showHotPropsOnly: false,
  statusFilters: {
    hotActive: true,
    savedActive: false,
    forSaleActive: true,
    pendingActive: true,
    recentlySoldActive: true,
    offMarketActive: true,
  },
  propertyTypeFilters: {
    singleFamilyActive: true,
    condoMultiFamActive: true,
    townhouseActive: true,
    multiplexActive: true,
    lotLandActive: true,
    manufMobileActive: true,
    commercialActive: true,
  },
  minBedrooms: 0,
  minValue: 0,
  maxValue: 0,
  minBathrooms: 0,
  minSqFt: 0,
  maxSqFt: 0,
  minYearBuilt: 0,
  maxYearBuilt: 0,
  savedModeFilterOn: false,
};

const savedModeFilterDefaultFiltersState: FiltersState = {
  showHotPropsOnly: false,
  statusFilters: {
    hotActive: true,
    savedActive: true,
    forSaleActive: true,
    pendingActive: true,
    recentlySoldActive: true,
    offMarketActive: true,
  },
  propertyTypeFilters: {
    singleFamilyActive: true,
    condoMultiFamActive: true,
    townhouseActive: true,
    multiplexActive: true,
    lotLandActive: true,
    manufMobileActive: true,
    commercialActive: true,
  },
  minBedrooms: 0,
  minValue: 0,
  maxValue: 0,
  minBathrooms: 0,
  minSqFt: 0,
  maxSqFt: 0,
  minYearBuilt: 0,
  maxYearBuilt: 0,
  savedModeFilterOn: true,
};

export const FiltersReducer: Reducer<FiltersState, KnownAction> = (state = unloadedState, action: KnownAction) => {
  switch (action.type) {
    case "TOGGLE_HOT_PROPERTIES":
      if (!state.showHotPropsOnly) {
        // if not currently showing hotPropsOnly, then it's about to be toggled off and we need to
        // turn off any other statusFilter (except for savedActive since we aren't using that anymore)
        return {
          ...state,
          showHotPropsOnly: true,
          statusFilters: {
            hotActive: true,
            savedActive: false,
            forSaleActive: false,
            pendingActive: false,
            recentlySoldActive: false,
            offMarketActive: false,
          },
        };
      } else
        return {
          ...state,
          showHotPropsOnly: false,
          statusFilters: {
            hotActive: true,
            savedActive: false,
            forSaleActive: true,
            pendingActive: false,
            recentlySoldActive: false,
            offMarketActive: false,
          },
        };
    //return { ...state, showHotPropsOnly: !state.showHotPropsOnly };
    case "TOGGLE_SAVED_MODE_FILTER":
      if (!state.savedModeFilterOn) {
        // it's currently off and being switched on
        return { ...savedModeFilterDefaultFiltersState, savedModeFilterOn: true };
      } else {
        // it's currently on and being switched off needs new default values for status/property
        return { ...unloadedState, savedModeFilterOn: false };
      }
    //return { ...state, savedModeFilterOn: !state.savedModeFilterOn };
    case "TURN_SAVED_MODE_FILTER_ON":
      return { ...savedModeFilterDefaultFiltersState, savedModeFilterOn: true };
    case "RESET_DEFAULT_FILTER_OPTIONS":
      return { ...unloadedState };
    case "RESET_DEFAULT_SAVED_MODE_FILTER_OPTIONS":
      return { ...savedModeFilterDefaultFiltersState };
    case "SET_MIN_BEDROOMS_FILTER":
      return { ...state, minBedrooms: action.payload };
    case "SET_MIN_VALUE":
      return { ...state, minValue: action.payload };
    case "SET_MAX_VALUE":
      return { ...state, maxValue: action.payload };
    case "SET_MIN_BATHROOMS_FILTER":
      return { ...state, minBathrooms: action.payload };
    case "SET_MIN_SQ_FT_FILTER":
      return { ...state, minSqFt: action.payload };
    case "SET_MAX_SQ_FT_FILTER":
      return { ...state, maxSqFt: action.payload };
    case "SET_MIN_YEAR_BUILT_FILTER":
      return { ...state, minYearBuilt: action.payload };
    case "SET_MAX_YEAR_BUILT_FILTER":
      return { ...state, maxYearBuilt: action.payload };
    case "TOGGLE_STATUS_FILTER":
      switch (action.payload) {
        case "hot":
          return { ...state, statusFilters: { ...state.statusFilters, hotActive: !state.statusFilters.hotActive } };
        case "saved":
          return { ...state, statusFilters: { ...state.statusFilters, savedActive: !state.statusFilters.savedActive } };
        case "forSale":
          return {
            ...state,
            statusFilters: { ...state.statusFilters, forSaleActive: !state.statusFilters.forSaleActive },
            showHotPropsOnly: false,
          };
        case "pending":
          return {
            ...state,
            statusFilters: { ...state.statusFilters, pendingActive: !state.statusFilters.pendingActive },
            showHotPropsOnly: false,
          };
        case "recentlySold":
          return {
            ...state,
            statusFilters: { ...state.statusFilters, recentlySoldActive: !state.statusFilters.recentlySoldActive },
            showHotPropsOnly: false,
          };
        case "offMarket":
          return {
            ...state,
            statusFilters: { ...state.statusFilters, offMarketActive: !state.statusFilters.offMarketActive },
            showHotPropsOnly: false,
          };
        default:
          return { ...state };
      }
    case "TOGGLE_PROPERTY_TYPE_FILTER":
      switch (action.payload) {
        case "singleFamily":
          return {
            ...state,
            propertyTypeFilters: {
              ...state.propertyTypeFilters,
              singleFamilyActive: !state.propertyTypeFilters.singleFamilyActive,
            },
          };
        case "condoMultiFam":
          return {
            ...state,
            propertyTypeFilters: {
              ...state.propertyTypeFilters,
              condoMultiFamActive: !state.propertyTypeFilters.condoMultiFamActive,
            },
          };
        case "townhouse":
          return {
            ...state,
            propertyTypeFilters: {
              ...state.propertyTypeFilters,
              townhouseActive: !state.propertyTypeFilters.townhouseActive,
            },
          };
        case "multiplex":
          return {
            ...state,
            propertyTypeFilters: {
              ...state.propertyTypeFilters,
              multiplexActive: !state.propertyTypeFilters.multiplexActive,
            },
          };
        case "lotLand":
          return {
            ...state,
            propertyTypeFilters: {
              ...state.propertyTypeFilters,
              lotLandActive: !state.propertyTypeFilters.lotLandActive,
            },
          };
        case "manufMobile":
          return {
            ...state,
            propertyTypeFilters: {
              ...state.propertyTypeFilters,
              manufMobileActive: !state.propertyTypeFilters.manufMobileActive,
            },
          };
        case "commercial":
          return {
            ...state,
            propertyTypeFilters: {
              ...state.propertyTypeFilters,
              commercialActive: !state.propertyTypeFilters.commercialActive,
            },
          };
      }
    default:
      return state || unloadedState;
  }
};
