import * as React from "react";
import { Transition } from "semantic-ui-react";
import { Link } from "react-scroll";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { ContactUsKey } from "./homePageContainer";
import { ContactTopic } from "./sections/contactSection";

interface State {
  previousScrollY: number;
  scrollingUp: boolean;
  mobileMenuOpen: boolean;
}

interface Props {
  setTopic: (name: ContactUsKey, value: string) => void;
  useFloatingLogo?: boolean;
  useSubpageLinks: boolean;
}

type ComponentProps = Props & RouteComponentProps;

class NavBar extends React.Component<ComponentProps, State> {
  state: State = {
    previousScrollY: 0,
    scrollingUp: false,
    mobileMenuOpen: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    // method to tell if user is scrolling up/down, determining if "scrolling" navbar is visible
    const { previousScrollY, mobileMenuOpen } = this.state;

    if (!mobileMenuOpen) {
      if (window.scrollY === 0) {
        this.setState({ scrollingUp: false });
      } else if (window.scrollY < previousScrollY) {
        this.setState({
          previousScrollY: window.scrollY,
          scrollingUp: true,
        });
      } else {
        this.setState({
          previousScrollY: window.scrollY,
          scrollingUp: false,
        });
      }
    }
  };

  openMobileMenu = () => {
    this.setState({ mobileMenuOpen: true });
  };

  closeMobileMenu = () => {
    this.setState({ mobileMenuOpen: false });
  };

  renderHamburger = () => {
    const { mobileMenuOpen } = this.state;

    return (
      <div className="navbar-hamburger" onClick={this.openMobileMenu} style={mobileMenuOpen ? { display: "none" } : {}}>
        <div className={`hamburger-bar`} />
        <div className={`hamburger-bar`} />
      </div>
    );
  };

  renderMobileMenu = () => {
    return (
      <Transition visible={this.state.mobileMenuOpen} animation="fade" duration={300}>
        <div className="navbar-mobile-menu-open">
          <div className="mobile-menu-content navbar-content">
            <div className="top-row">
              {this.props.useSubpageLinks ? (
                <NavLink to="/">
                  <div onClick={this.closeMobileMenu} className="logo logo-grey" />
                </NavLink>
              ) : (
                <Link to="homepage-container" smooth={true} duration={750} className="logo-link">
                  <div onClick={this.closeMobileMenu} className="logo logo-grey" />
                </Link>
              )}
              <div className="icon-close-black" onClick={this.closeMobileMenu} />
            </div>
            {this.signInBtn()}
            {this.scheduleDemoBtn()}
            {this.contactBtn()}
          </div>
        </div>
      </Transition>
    );
  };

  contactBtn = () => {
    const { setTopic } = this.props;
    const { mobileMenuOpen } = this.state;

    return this.props.useSubpageLinks ? (
      <NavHashLink
        to="/#contact"
        className="subheader-2 txt-medium"
        onClick={() => setTopic("topic", ContactTopic.General)}>
        Contact
      </NavHashLink>
    ) : (
      <Link
        to="flueidProContactForm-text-content"
        onClick={() => {
          if (mobileMenuOpen) this.closeMobileMenu();
          setTopic("topic", ContactTopic.General);
        }}
        smooth={true}
        className={`subheader-2 txt-medium`}>
        Contact
      </Link>
    );
  };

  scheduleDemoBtn = () => {
    const { setTopic } = this.props;
    const { mobileMenuOpen } = this.state;

    return this.props.useSubpageLinks ? (
      <NavHashLink
        to="/#contact"
        className="btn-white signUpDemoBtn"
        onClick={() => setTopic("topic", ContactTopic.Demo)}>
        Schedule a Demo
      </NavHashLink>
    ) : (
      <Link
        to="flueidProContactForm-text-content"
        onClick={() => {
          if (mobileMenuOpen) this.closeMobileMenu();
          setTopic("topic", ContactTopic.Demo);
        }}
        smooth={true}
        className="btn-white">
        Schedule a Demo
      </Link>
    );
  };

  signInBtn = () => {
    return (
      <NavLink to="/auth">
        <div className="btn-black">Sign In</div>
      </NavLink>
    );
  };

  render() {
    const { scrollingUp } = this.state;

    return (
      <React.Fragment>
        <div className={`navbar-container ${scrollingUp ? "navbar-scrolling fade-in" : ""}`}>
          <div className="navbar-content">
            {this.props.useSubpageLinks ? (
              <NavLink to="/">
                <div className={`logo logo-grey ${this.props.useFloatingLogo === false ? "static-flueid-logo" : ""}`} />
              </NavLink>
            ) : (
              <div
                className={`logo ${this.props.useFloatingLogo === false ? "static-flueid-logo" : ""}`}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              />
            )}
            <div className="buttons-container main-container">
              {this.contactBtn()}
              <div className="contact_seperator"></div>
              {this.scheduleDemoBtn()}
              {this.signInBtn()}
            </div>
            {this.renderHamburger()}
            {this.renderMobileMenu()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(NavBar);
