import * as React from "react";
import { AsYouType } from "libphonenumber-js";
import { parseIncompletePhone, valueExceedsMax } from "./utilities";
import { Image, Popup } from "semantic-ui-react";

// note: 'stringNumber' is used for something like zipCode, whose value needs to be a string of numeric characters
//  in this case actual input type will be 'number', ensuring no letters can be entered.
export type InputType = "text" | "number" | "stringNumber" | "date" | "phone" | "password";

export interface BasicInputProps<T> {
  initialValue: string | number;
  label: string;
  name: T;
  onChange: (name: T, value: string) => void;
  onClearClick?: (name: T) => void;
  type: InputType;

  //****
  // optional props
  allowedChars?: RegExp;
  autoComplete?: boolean;
  className?: string;
  editable?: boolean;
  errorMessages?: string[];
  focusOnMount?: boolean;
  hasFocus?: boolean;
  hint?: string;
  inputMode?: React.HTMLAttributes<HTMLInputElement>["inputMode"];
  inputVersion?: "v1" | "v2";
  leftIcon?: JSX.Element;
  max?: number;
  min?: number;
  onBlur?: () => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  pattern?: RegExp;
  placeHolder?: string;
  required?: boolean;
  styles?: React.CSSProperties;
  tabIndex?: number;
  valid?: boolean;
}

interface State {
  passwordVisible: boolean;
}

class BasicInput<T extends string> extends React.Component<BasicInputProps<T>, State> {
  state: State = {
    passwordVisible: false,
  };

  private inputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    const { focusOnMount } = this.props;

    if (focusOnMount) {
      this.focusInput();
    }
  }

  componentDidUpdate(prevProps: BasicInputProps<T>, prevState: State) {
    const { hasFocus } = this.props;
    const { passwordVisible } = this.state;

    if (hasFocus) {
      this.focusInput();
    }

    if (prevState.passwordVisible !== passwordVisible) {
      this.focusInput();
    }
  }

  handleInputChange = (e: any) => {
    e.persist();

    const { type, max, initialValue, allowedChars } = this.props;

    let value = e.target.value;
    let name = e.target.name;

    if (allowedChars && !allowedChars.test(value)) return;

    // HANDLE DIFFERENT INPUT TYPE CONDITIONS
    if (type === "phone") {
      // parse the formatted number to a basic string
      value = parseIncompletePhone(value);

      //**** Phones have an automatic max value
      // check to see if phone has exceeded max value
      if (valueExceedsMax(initialValue, value, value[0] === "1" ? 11 : 10)) return;
    } else if (type === "number") {
      // parse the value
      if (!value) {
        value = 0;
      } else {
        value = parseFloat(value);
      }

      // return out if value exceeds max
      if (max && valueExceedsMax(initialValue, value, max)) return;
    } else if (type === "stringNumber") {
      // return out if value exceeds max
      if (max && valueExceedsMax(initialValue, value, max)) return;
    } else if (type === "date" && value) {
      const date: Date = new Date(value);
      // return out if date's year is greater than 4 digits,
      // TODO - remove this check when we hit the year 10,000
      if (date && date.getFullYear().toString().length > 4) return;
    }

    this.props.onChange(name, value);
  };

  handleClearClick = (e: any) => {
    const { name, onClearClick, type } = this.props;
    e.persist();

    this.focusInput();

    if (onClearClick) onClearClick(name);

    // if password input cleared, reset passwordVisible to false
    if (type === "password" && this.state.passwordVisible) this.setState({ passwordVisible: false });
  };

  inputType = (): InputType => {
    const { type } = this.props;
    const { passwordVisible } = this.state;
    if (type === "stringNumber") return "number";
    if (type === "password") {
      return passwordVisible ? "text" : "password";
    }
    return type;
  };

  focusInput = () => {
    const input = this.inputRef.current;
    if (input) input.focus();
  };

  render() {
    const {
      autoComplete,
      className,
      editable,
      initialValue,
      inputMode,
      inputVersion,
      label,
      leftIcon,
      max,
      name,
      onBlur,
      onClearClick,
      onFocus,
      pattern,
      placeHolder,
      required,
      styles,
      tabIndex,
      type,
      hint,
    } = this.props;
    let { errorMessages, valid } = this.props;

    const { passwordVisible } = this.state;

    let value = initialValue !== null ? initialValue : "";

    // format the phone number
    if (type === "phone") {
      value = new AsYouType("US").input(value.toString());
    }

    const optionalAttributes: { [key: string]: any } = {};
    if (max && type === "text") optionalAttributes.maxLength = max;
    if (max && (type === "number" || type === "stringNumber")) optionalAttributes.max = max;
    if (pattern) optionalAttributes.pattern = pattern;
    if (editable === false) optionalAttributes.readOnly = true;

    // https://gitlab.com/flueid/flueid-pro/fp-ui/-/issues/175
    // internal date validation - year must be 4 digits. Sometimes users manually type just 2 digits "yy" for year value
    // and input requires "yyyy", ie. they enter "23" and input shows "0023" which is valid according to the input but not what they intented ("2023")
    const MIN_YEAR = 1000;
    const MIN_DATE = "1776-07-04";
    if (type === "date") {
      try {
        const d = new Date(value);
        if (d.getFullYear() < MIN_YEAR) {
          let errMsg = "Year invalid - must be 4 digits";
          errorMessages ? errorMessages?.push(errMsg) : (errorMessages = [errMsg]);
          valid = false;
        }
      } catch (err) {
        console.error(err);
      }
    }

    return (
      <div
        className={`input-group ${editable === false ? "read-only" : ""} ${inputVersion || ""} ${
          valid !== undefined && !valid ? "error" : ""
        } ${className || ""}`}
        style={styles ? styles : {}}>
        {label.length > 0 && (
          <div className={`label ${required ? "required" : "optional"}`}>
            {label} {required && required === true ? "*" : ""}
            {hint && <Popup content={hint} key={hint} header="" trigger={<i className="question circle icon"></i>} />}
          </div>
        )}
        {leftIcon ? leftIcon : ""}
        <input
          autoComplete={autoComplete ? "on" : "off"}
          className={`${errorMessages && errorMessages.length ? "error" : ""} ${
            type === "date" && !value ? "pad-right-5" : ""
          }`}
          inputMode={inputMode}
          min={type === "number" ? 1 : type === "date" ? MIN_DATE : ""}
          name={name}
          onBlur={onBlur}
          onChange={this.handleInputChange}
          onFocus={onFocus}
          onWheel={type === "number" ? (e) => this.inputRef.current?.blur() : undefined}
          placeholder={placeHolder}
          ref={this.inputRef}
          style={leftIcon ? { paddingLeft: 25 } : {}}
          tabIndex={tabIndex}
          type={this.inputType()}
          value={type === "number" && !value ? "" : value}
          {...optionalAttributes}
        />
        {onClearClick && (
          <div
            className={`icon-clear ${!value ? "hidden" : ""}`}
            onClick={this.handleClearClick}
            title={name}
            style={type === "date" ? { right: "6px" } : {}}
          />
        )}
        {type === "password" && value && (
          <div
            className={passwordVisible ? "icon-password-show" : "icon-password-hidden"}
            title={passwordVisible ? "Hide password" : "Show password"}
            onClick={() => this.setState({ passwordVisible: !passwordVisible })}
          />
        )}
        <div className={`bar ${!valid ? "error" : ""}`} />

        {errorMessages && errorMessages.length ? (
          <div className={`error-message-container ${(errorMessages.length > 1 && "error-box-shadow") || ""}`}>
            {errorMessages.map((x: string, index: number) => (
              <div key={`input-error-message-${index}`} className="error-message">
                {x}
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default BasicInput;
