import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { OrderSubmissionResult } from "../../../dataTypes/orders/interfaces/orderSubmission";
import { UserLite } from "../../../dataTypes/user/userLite";
import { OrderTypeOption } from "../../../dataTypes/orders/interfaces/orderTypeOption";
import { OrderContactOption } from "../../../dataTypes/orders/interfaces/orderContactOption";
import { OrderDetailsOptions } from "../../../dataTypes/orders/interfaces/orderDetailsOptions";
import { PropertyDetailLite } from "../../../dataTypes/properties/propertyDetailLite";
import { uniqueID } from "../../../utilities/formatters";
import { CheckDuplicateOrderResponse } from "../../../dataTypes/orders/interfaces/checkDuplicateOrder";
import { DropdownOption } from "../../../dataTypes/common";

// THIS STORE CONTAINS ALL DATA FOR POPULATING NEW ORDER FORMS

export interface NewOrderState {
  checkDuplicateOrderResponse: CheckDuplicateOrderResponse | null;
  orderSubmissionResult: OrderSubmissionResult | null;
  relatedBizSrcUsers: UserLite[] | null;
  defaultOrderContacts: UserLite[];
  contactsReceivedId: string;
  relatedAssistants: UserLite[];
  assistantsReceivedId: string;
  orderTypeOptions: OrderTypeOption[];
  orderContactOptions: OrderContactOption[];
  orderDetailsOptions: OrderDetailsOptions | null;
  businessSourceRoles: DropdownOption[];
  closingDate: string;
  searchPropsResults: PropertyDetailLite[] | null;
  createOrderPending: boolean;
  searchBizSrcUsersPending: boolean;
  orderTypeOptionsPending: boolean;
}

const initialState: NewOrderState = {
  checkDuplicateOrderResponse: null,
  orderSubmissionResult: null,
  relatedBizSrcUsers: null,
  defaultOrderContacts: [],
  contactsReceivedId: "",
  relatedAssistants: [],
  assistantsReceivedId: "",
  orderTypeOptions: [],
  orderContactOptions: [],
  orderDetailsOptions: null,
  closingDate: "",
  businessSourceRoles: [],
  searchPropsResults: null,
  createOrderPending: false,
  searchBizSrcUsersPending: false,
  orderTypeOptionsPending: false,
};

export const NewOrderReducer: Reducer<NewOrderState, KnownAction> = (state = initialState, action: KnownAction) => {
  switch (action.type) {
    case "CHECK_DUPLICATE_ORDER":
      return { ...state, checkDuplicateOrderResponse: action.payload };
    case "CLEAR_CHECK_DUPLICATE_ORDER":
      return { ...state, checkDuplicateOrderResponse: null };
    case "CREATE_ORDER":
      return { ...state, orderSubmissionResult: action.payload, createOrderPending: false };
    case "CREATE_ORDER_PENDING":
      return { ...state, createOrderPending: true };
    case "CREATE_ORDER_FAILURE":
      return { ...state, orderSubmissionResult: action.payload ? action.payload : null, createOrderPending: false };
    case "CLEAR_ORDER_RESULTS":
      return { ...state, orderSubmissionResult: null };
    case "SEARCH_BIZ_SRC_USERS_PENDING":
      return { ...state, searchBizSrcUsersPending: true };
    case "SEARCH_RELATED_BIZ_SRC_USERS":
      return { ...state, relatedBizSrcUsers: action.payload, searchBizSrcUsersPending: false };
    case "CLEAR_RELATED_BIZ_SRC_USERS":
      return { ...state, relatedBizSrcUsers: null };
    case "GET_DEFAULT_ORDER_CONTACTS":
      // process the contacts and make sure they all get an id
      const processedContacts: UserLite[] = action.payload.map((x: UserLite) => {
        const contact = { ...x };
        if (!contact.userId) contact.userId = Math.random();
        return contact;
      });
      return {
        ...state,
        defaultOrderContacts: processedContacts,
        contactsReceivedId: state.contactsReceivedId ? "" : uniqueID(),
      };
    case "GET_RELATED_ASSISTANTS":
      return {
        ...state,
        relatedAssistants: action.payload,
        assistantsReceivedId: state.assistantsReceivedId ? "" : uniqueID(),
      };
    case "ORDER_TYPE_OPTIONS_PENDING":
      return { ...state, orderTypeOptionsPending: true };
    case "GET_ORDER_TYPE_OPTIONS":
      return { ...state, orderTypeOptions: action.payload, orderTypeOptionsPending: false };
    case "GET_ORDER_CONTACT_OPTIONS":
      return { ...state, orderContactOptions: action.payload };
    case "GET_ORDER_DETAILS_OPTIONS":
      return { ...state, orderDetailsOptions: action.payload };
    case "GET_CLOSING_DATE":
      // remove the "T" timestamp part off of the datetime string returned from API (the date inputs need yyyy-mm-dd format)
      return { ...state, closingDate: action.payload.slice(0, action.payload.indexOf("T")) };
    case "SET_CLOSING_DATE":
      return { ...state, closingDate: action.payload.slice(0, action.payload.indexOf("T")) };
    case "FIND_PROPERTIES":
      return { ...state, searchPropsResults: action.payload };
    case "GET_BUSINESS_SOURCE_ROLES":
      return { ...state, businessSourceRoles: action.payload };
    case "CLEAR_SEARCH_PROPS_RESULTS":
      return { ...state, searchPropsResults: null };
    case "RESET_DEFAULT_ORDER_CONTACTS":
      return {
        ...state,
        defaultOrderContacts: [],
        contactsReceivedId: "",
      };
    case "RESET_RELATED_ASSISTANTS":
      return { ...state, relatedAssistants: [], assistantsReceivedId: "" };
    case "RESET_ORDER_TYPE_OPTIONS":
      return { ...state, orderTypeOptions: [] };
    case "RESET_ORDER_DETAILS_OPTIONS":
      return { ...state, orderDetailsOptions: null, closingDate: "" };
    case "RESET_ORDER_CONTACT_OPTIONS":
      return { ...state, orderContactOptions: [] };
    case "RESET_NEW_ORDER_STORE":
      return initialState;
    default:
      return state || initialState;
  }
};
