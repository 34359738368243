import * as React from "react";
import { ApplicationState } from "../../../store";
import Validate from "../../../utilities/formFieldValidator";
import AuthService from "../../../utilities/authService";
import AuthNavbar, { AuthNavCloseBtn, AuthNavHeader } from "../authNavbar";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { CognitoUser } from "@aws-amplify/auth";
import { Partner } from "../../../dataTypes/partners";
import { User } from "../../../dataTypes/user/user";
import { accountActionCreators } from "../../../store/account/actionCreators";
import BasicInput from "../../controls/inputs/basicInput";
import { loadingBarActionCreators } from "../../../store/controls/loadingBar/loadingBarActionCreators";
import { getAuthPath } from "../types/authPath";
import { InputField } from "../types/inputField";
import AuthFormButton from "./button";
import ErrorHandler from "components/controls/errorHandler";

interface State {
  currentFieldIndex: number;
  fieldInvalid: boolean;
  fieldInvalidMessages: string[];
  inputFields: InputField[];
  submitButtonDisabled: boolean;
}

interface ReduxStateProps {
  signedInUser: User | null;
}

interface ReduxDispatchProps {
  getSignedInUser: () => void;
  showLoadingBar: (scope?: string) => void;
  hideLoadingBar: (scope?: string) => void;
}

interface OwnProps {
  partner?: Partner;
  awsCognitoUser?: CognitoUser;
}

type ComponentProps = OwnProps & ReduxStateProps & RouteComponentProps & ReduxDispatchProps;

class NewUserPasswordReset extends React.Component<ComponentProps, State> {
  state: State = {
    currentFieldIndex: 0,
    fieldInvalid: false,
    fieldInvalidMessages: [],
    inputFields: [
      {
        name: "newPassword",
        label: "Password",
        value: "",
        instructionText: "Complete your account by creating a new password.",
      },
      {
        name: "confirmPassword",
        label: "Password",
        value: "",
        instructionText: "Please re-enter your new password to confirm it.",
      },
    ],
    submitButtonDisabled: false,
  };

  private inputRef = React.createRef<HTMLInputElement>();

  //-------------------
  // LIFECYCLE METHODS
  componentDidMount() {
    const input = this.inputRef.current;

    if (input) {
      input.focus();
    }
  }

  componentDidUpdate(prevProps: ComponentProps) {
    const { signedInUser, history } = this.props;

    const input = this.inputRef.current;

    if (input) {
      input.focus();
    }

    // when signedInUser received, check termsAccepted
    if (!prevProps.signedInUser && signedInUser) {
      if (signedInUser.termsAccepted) {
        // send them to props page
        history.push("/app");
      } else {
        // send them to terms/conditions
        history.push(getAuthPath("/auth/terms-conditions", this.props.partner));
      }
    }
  }

  handleInput = (value: string) => {
    const { inputFields, currentFieldIndex } = this.state;
    //make a copy of the fields
    const updatedFields = [...inputFields];
    //update the current fields value
    updatedFields[currentFieldIndex].value = value.trim(); // trim is helpful in case user copy/pasted confirmation code with unwanted white space

    this.setState({
      inputFields: updatedFields,
      fieldInvalid: false,
      fieldInvalidMessages: [],
      submitButtonDisabled: false,
    });
  };

  handleClear = (name: string) => {
    const { inputFields, currentFieldIndex } = this.state;
    const updatedFields = [...inputFields];
    updatedFields[currentFieldIndex].value = "";
    this.setState({
      inputFields: updatedFields,
      fieldInvalid: false,
      fieldInvalidMessages: [],
      submitButtonDisabled: false,
    });
  };

  progressNextFormField = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { inputFields, currentFieldIndex, fieldInvalidMessages } = this.state;

    // if there's no input in the field, let user know to enter value
    if (!inputFields[currentFieldIndex].value) {
      this.setState({
        fieldInvalid: true,
        fieldInvalidMessages: [
          ...fieldInvalidMessages,
          `Please enter a ${inputFields[currentFieldIndex].label.toLowerCase()}`,
        ],
      });
      return;
    } else {
      if (inputFields[currentFieldIndex].value) {
        // validate the new password with AWS password policy rules
        if (inputFields[currentFieldIndex].name === "newPassword") {
          const errorMessages = Validate.Password(inputFields[currentFieldIndex].value);

          if (errorMessages.length > 0) {
            this.setState({
              fieldInvalid: true,
              fieldInvalidMessages: errorMessages,
            });
          } else {
            this.setState({
              currentFieldIndex: currentFieldIndex + 1,
              submitButtonDisabled: false,
            });
          }
        }
      }
    }
  };

  previousFormField = () => {
    this.handleClear("");
    this.setState({
      currentFieldIndex: this.state.currentFieldIndex - 1,
    });
  };

  submitNewPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { getSignedInUser, awsCognitoUser } = this.props;
    const { inputFields, fieldInvalidMessages } = this.state;

    // check to see if password/confirmPassword are equal and then submit email/confirmationCode/password
    if (inputFields[0].value === inputFields[1].value) {
      // disable submit button to prevent extra requests
      this.setState({ submitButtonDisabled: true });

      // reset the new user's password
      if (awsCognitoUser) {
        AuthService.ResetNewUserPassword(awsCognitoUser, inputFields[1].value)
          .then(() => getSignedInUser())
          .catch((error) => {
            ErrorHandler.HandleError(error, AuthService.GetErrorMsg(error));
          });
      }
    } else {
      this.setState({
        fieldInvalid: true,
        fieldInvalidMessages: [...fieldInvalidMessages, "Please ensure the passwords match"],
        submitButtonDisabled: true,
      });
    }
  };

  //--------------------
  // RENDER UI ELEMENTS METHODS
  renderFormField = (currentFieldIndex: number) => {
    const { inputFields, fieldInvalid, fieldInvalidMessages, submitButtonDisabled } = this.state;
    const field: InputField = inputFields[currentFieldIndex];

    return (
      <form
        className="auth-form"
        onSubmit={currentFieldIndex === 1 ? this.submitNewPassword : this.progressNextFormField}>
        <div key={`input-${field.name}`} className="form-group">
          <BasicInput
            label={field.label}
            type={field.name === "newPassword" || field.name === "confirmPassword" ? "password" : "text"}
            name={field.name}
            initialValue={field.value}
            placeHolder={field.label}
            onChange={(name: string, value: string) => this.handleInput(value)}
            onClearClick={(name: string) => this.handleClear(name)}
            inputVersion="v2"
            focusOnMount
            valid={!fieldInvalid}
            errorMessages={fieldInvalidMessages}
            autoComplete
          />
        </div>
        <AuthFormButton
          type="submit"
          className={!inputFields[currentFieldIndex].value || fieldInvalid ? "btn-disabled " : ""}
          disabled={fieldInvalid || submitButtonDisabled ? true : false}>
          {currentFieldIndex === 0 ? "Next" : "Send"}
        </AuthFormButton>
      </form>
    );
  };

  render() {
    const { currentFieldIndex, inputFields } = this.state;

    return (
      <div className="fade-in">
        <AuthNavbar>
          {currentFieldIndex === 0 && (
            <Link to={getAuthPath("/auth/sign-in", this.props.partner)}>
              <div className="back-btn" />
            </Link>
          )}
          {currentFieldIndex > 0 && <div className="back-btn" onClick={this.previousFormField} />}
          <AuthNavHeader>Update Password</AuthNavHeader>
          <AuthNavCloseBtn websiteUrl={this.props.partner?.websiteUrl} />
        </AuthNavbar>
        <div className="auth-form-container">
          <div className="content">
            <h3 className="header-3 boldest header" style={{ marginBottom: "10px" }}>
              {currentFieldIndex < 1 ? "Create a New Password" : "Confirm New Password"}
            </h3>
            <div className="paragraph-small">{inputFields[currentFieldIndex].instructionText}</div>
            {this.renderFormField(currentFieldIndex)}

            <div
              onClick={
                currentFieldIndex > 0
                  ? this.previousFormField
                  : () => {
                      return;
                    }
              }
              style={{ color: "#BBB", marginBottom: "65px", marginTop: "20px", textDecoration: "underline" }}>
              <div
                style={
                  currentFieldIndex > 0
                    ? { visibility: "visible", cursor: "pointer" }
                    : { visibility: "hidden", cursor: "default" }
                }>
                Back
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReduxStateProps => ({
  signedInUser: state.account.signedInUser,
});

const mapDispatchToProps: ReduxDispatchProps = {
  getSignedInUser: accountActionCreators.getSignedInUser,
  showLoadingBar: loadingBarActionCreators.showLoadingBar,
  hideLoadingBar: loadingBarActionCreators.hideLoadingBar,
};

export default connect<ReduxStateProps, ReduxDispatchProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(NewUserPasswordReset);
