import { AxiosError } from "axios";
import { getAxiosErrorMessage } from "utilities/apiService";
import { notify } from "./notifier";

export default class ErrorHandler {
  // takes in a response object and optional user friendly message
  static HandleError(errorData: any, notifyMessage?: string, autoClose?: { autoClose: number }): void {
    // perhaps a map for status error code to a descriptive string message that gets logged to the console?
    console.log(errorData);

    notify.error(notifyMessage, autoClose);
  }

  static HandleAxiosError(error: AxiosError, optionalMessage = "Oops, something went wrong."): void {
    const errorMessage = getAxiosErrorMessage(error);
    this.HandleError(error, errorMessage || optionalMessage);
  }
}
