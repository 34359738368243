import { Reducer, combineReducers } from "redux";
import { KnownAction } from "./actionTypes";
import { PartnerOrderSettings, SoftProConnectionString } from "../../dataTypes/partners/partnerOrderSettings";
import { PartnerOrderOptions } from "../../dataTypes/partners/partnerOrderOptions";
import { ClientCompany } from "../../dataTypes/partners/clientCompany";
import { uniqueID } from "../../utilities/formatters";
import { DivisionBranchOptions } from "../../dataTypes/partners/divisionBranchOptions";
import { DropdownOption } from "../../dataTypes/common";
import { Partner, PaginatedSearchResult } from "../../dataTypes/partners";
import { ReqStatus, newReqStatus, newPendingReq, reqOk, reqFailed } from "../../utilities/apiService";

interface ClientsState {
  clientCompany: ClientCompany | null;
  clientCompanies: ClientCompany[];
  clientCompanyOptions: DropdownOption[];
  clientCompanyDivisionsOptions: DivisionBranchOptions;
  saveClientEventId: string;
  searchClientCompaniesResult: PaginatedSearchResult<ClientCompany>;
  searchClientCompaniesReqStatus: ReqStatus;
}

interface PartnersState {
  partner: Partner | null;
  partners: Partner[];
  partnerDivisions: DivisionBranchOptions;
  getPartnerDivisionsReqStatus: ReqStatus;
  partnerOrderSettings: PartnerOrderSettings | null;
  getPartnerOrderSettingsReqStatus: ReqStatus;
  savePartnerOrderSettingsReqStatus: ReqStatus;
  savePartnerEventId: string;
  savePartnerOrderSettingsEventId: string;
  allPartnerOptions: DropdownOption[];
  searchPartnersResult: PaginatedSearchResult<Partner>;
  searchPartnersReqStatus: ReqStatus;
  partnerOrderOptions: PartnerOrderOptions | null;
}

export interface CompaniesState {
  clients: ClientsState;
  partners: PartnersState;
}

const initialState: CompaniesState = {
  clients: {
    clientCompany: null,
    clientCompanies: [],
    clientCompanyOptions: [],
    clientCompanyDivisionsOptions: { divisionOptions: [], divisionBranches: [] },
    saveClientEventId: "",
    searchClientCompaniesResult: new PaginatedSearchResult(),
    searchClientCompaniesReqStatus: newReqStatus(),
  },
  partners: {
    allPartnerOptions: [],
    partnerDivisions: { divisionOptions: [], divisionBranches: [] },
    getPartnerDivisionsReqStatus: newReqStatus(),
    partner: null,
    partners: [],
    partnerOrderSettings: null,
    getPartnerOrderSettingsReqStatus: newReqStatus(),
    savePartnerOrderSettingsReqStatus: newReqStatus(),
    savePartnerEventId: "",
    savePartnerOrderSettingsEventId: "",
    searchPartnersResult: new PaginatedSearchResult(),
    searchPartnersReqStatus: newReqStatus(),
    partnerOrderOptions: null,
  },
};

export const CompaniesReducer = combineReducers<CompaniesState, KnownAction>({
  clients: (state: ClientsState = initialState.clients, action: KnownAction) => {
    switch (action.type) {
      case "SEARCH_CLIENT_COMPANIES_PENDING":
        return { ...state, searchClientCompaniesReqStatus: newPendingReq() };
      case "SEARCH_CLIENT_COMPANIES":
        return { ...state, searchClientCompaniesResult: action.payload, searchClientCompaniesReqStatus: reqOk() };
      case "SEARCH_CLIENT_COMPANIES_FAILED":
        return { ...state, searchClientCompaniesReqStatus: reqFailed() };
      case "GET_CLIENT_COMPANY":
        return { ...state, clientCompany: action.payload };
      case "SAVE_CLIENT_COMPANY":
        return { ...state, saveClientEventId: uniqueID() };
      case "CLEAR_CLIENT_COMPANY":
        return { ...state, clientCompany: null };
      case "GET_CLIENT_COMPANY_OPTIONS":
        return { ...state, clientCompanyOptions: action.payload };
      case "GET_CLIENT_COMPANY_DIVISIONS_OPTIONS":
        return { ...state, clientCompanyDivisionsOptions: action.payload };
      case "CLEAR_CLIENT_COMPANY_OPTIONS":
        return { ...state, clientCompanyOptions: [] };
      case "CLEAR_CLIENT_COMPANY_DIVISION_OPTIONS":
        return { ...state, clientCompanyDivisionsOptions: { divisionOptions: [], divisionBranches: [] } };
      default:
        return state;
    }
  },
  partners: (state: PartnersState = initialState.partners, action: KnownAction) => {
    switch (action.type) {
      case "GET_ALL_PARTNERS":
        return { ...state, allPartnerOptions: action.payload };
      case "GET_PARTNER_DIVISIONS_PENDING":
        return { ...state, getPartnerDivisionsReqStatus: newPendingReq() };
      case "GET_PARTNER_DIVISIONS_RECEIVED":
        return { ...state, partnerDivisions: action.payload, getPartnerDivisionsReqStatus: reqOk() };
      case "GET_PARTNER_DIVISIONS_FAILED":
        return { ...state, getPartnerDivisionsReqStatus: reqFailed() };
      case "GET_PARTNER":
        return { ...state, partner: action.payload };
      case "SAVE_PARTNER":
        return { ...state, savePartnerEventId: uniqueID() };
      case "CLEAR_PARTNER":
        return { ...state, partner: null };
      case "SEARCH_PARTNERS_PENDING":
        return { ...state, searchPartnersReqStatus: newPendingReq() };
      case "SEARCH_PARTNERS":
        return { ...state, searchPartnersResult: action.payload, searchPartnersReqStatus: reqOk() };
      case "SEARCH_PARTNERS_FAILED":
        return { ...state, searchPartnersReqStatus: reqFailed() };
      case "CLEAR_PARTNER_DIVISIONS":
        return { ...state, partnerDivisions: { divisionOptions: [], divisionBranches: [] } };
      //-------------------
      // PARNTER ORDER SETTINGS
      case "GET_PARTNER_ORDER_SETTINGS_PENDING":
        return { ...state, getPartnerOrderSettingsReqStatus: newPendingReq() };
      case "GET_PARTNER_ORDER_SETTINGS":
        return { ...state, partnerOrderSettings: action.payload, getPartnerOrderSettingsReqStatus: reqOk() };
      case "GET_PARTNER_ORDER_SETTINGS_FAILED":
        return { ...state, getPartnerOrderSettingsReqStatus: reqFailed() };
      case "SAVE_PARTNER_ORDER_SETTINGS_PENDING":
        return { ...state, savePartnerOrderSettingsReqStatus: newPendingReq() };
      case "SAVE_PARTNER_ORDER_SETTINGS":
        return {
          ...state,
          partnerOrderSettings: action.payload,
          savePartnerOrderSettingsReqStatus: reqOk(),
        };
      case "SAVE_PARTNER_ORDER_SETTINGS_FAILED":
        return { ...state, savePartnerOrderSettingsReqStatus: reqFailed() };
      case "CLEAR_PARTNER_ORDER_SETTINGS":
        return { ...state, partnerOrderSettings: null };
      case "GET_PARTNER_ORDER_OPTIONS":
        return { ...state, partnerOrderOptions: action.payload };
      default:
        return state;
    }
  },
});

export const CompaniesRootReducer: Reducer<CompaniesState, KnownAction> = (
  state = initialState,
  action: KnownAction,
) => {
  return CompaniesReducer(state, action);
};
