import { IAlert, AlertScope } from "./types";
import { Entry } from "contentful";
import { INFO_ALERT_TIMEOUT } from ".";

// Only want to show info alerts to user once. Filters out already seen alerts & adds new alerts to local storage.
export function filterSeenInfoAlerts(alerts: Entry<IAlert>[], scope: AlertScope) {
  try {
    const seenInfoAlertsKey = "seenInfoAlertsKey";
    const seenIds: string[] = JSON.parse(localStorage.getItem(seenInfoAlertsKey) || "[]");

    const newAlerts = alerts.filter((entry) => {
      if (entry.fields.severity === "Info") {
        if (seenIds?.includes(entry.sys.id)) {
          return false;
        } else if (scope === entry.fields.scope) {
          seenIds?.push(entry.sys.id);
          return true;
        }
      }
      return true;
    });

    // edge case - wait to set item in case they re-render before seeing the alert
    setTimeout(() => {
      localStorage.setItem("seenInfoAlertsKey", JSON.stringify(seenIds));
    }, INFO_ALERT_TIMEOUT);

    return newAlerts;
  } catch (err) {
    console.log(err);
  }
}
