import { useQuery } from "react-query";
import contentfulClient from "utilities/contentfulClient";
import { AlertScope, IAlert } from "./types";
import { filterSeenInfoAlerts } from "./utils";

const ALERT_STALE_TIME = 180000;

const fetchContentfulAlerts = () =>
  contentfulClient.getEntries<IAlert>({
    content_type: "flueidProAlert",
  });

export const useFetchContentfulAlerts = (scope: AlertScope) =>
  useQuery("contentful-alerts", fetchContentfulAlerts, {
    select: (data) => filterSeenInfoAlerts(data.items, scope),
    staleTime: ALERT_STALE_TIME,
    onError: (err: unknown) => console.error(err),
  });
