import * as React from "react";
import DashedLineGroup, { DashedLine } from "../../controls/dashedLineGroup";

class TxMFullPageSection extends React.Component<any> {
  componentDidMount() {
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className="txm-section-full-page-main-container">
        <div className="txm-section-main-container">
          <div className="txm-flexbox-container-full">
            <div className="txm-full-page-hero-img"></div>
            <div className="whatElse-content">
              <DashedLineGroup
                visibilityClassName=""
                className="dashed-line-txm-full-page"
                render={(visible: boolean) => (
                  <>
                    <DashedLine width={35} duration={1.5} delay={0.2} x={"1vw"} show={visible} color="white" />
                    <DashedLine width={50} duration={1} delay={0.5} x={"4vw"} show={visible} color="white" />
                    <DashedLine width={90} duration={0.5} delay={0.7} x={"7vw"} show={visible} color="white" />
                  </>
                )}
              />
              <div className="txt-extraLight margin-top-txm">Flueid TxM</div>

              <div className="header-1 txt-white">Faster, Simpler, Easier Transactions</div>

              <div className="whatElseTxtSubSecMain">
                <div className="whatelseSec rich">
                  <div className=" txt-extraLight">
                    Flueid TxM is a multifaceted software module that safely shares information, documents, and
                    communication by leveraging our proprietary Collaboration Fabric, integrating seamlessly with the
                    closing agent’s enterprise software to protect sensitive information and facilitate a smoother
                    closing process.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="txm-middle-section-container">
          <div className="txm-middle-section-content">
            <div>
              <div className="header-1 txt-white middle-section-title txm-txt-white">TxM</div>
              <div className="subheader-1 txt-light txm-txt-white">
                Flueid has a solution for everyone within the real estate ecosystem.
              </div>
              <DashedLineGroup
                visibilityClassName=""
                className="dashed-line-txm-full-page middle-section-color-white margin-top-txm"
                render={(visible: boolean) => (
                  <>
                    <DashedLine width={240} duration={1.5} delay={0.2} x={"15vw"} show={visible} color="white" />
                    <DashedLine width={25} duration={1} delay={0.5} x={"18vw"} show={visible} color="white" />
                    <DashedLine width={45} duration={0.5} delay={0.7} x={"20vw"} show={visible} color="white" />
                  </>
                )}
              />
            </div>
            <div className="txm-middle-section-block">
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-package"></div>
                <div className="header-3 txt-white subsection-titles txm-txt-white">Collaboration Packages</div>
                <div className="txt-extraLight txm-txt-white">
                  TxM Collaboration Packages are pre-defined for each of our customers based on their business
                  practices, the parties involved, and what is being shared. These collaboration packages limit the
                  number of errors and eyeballs on each communication.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-notif"></div>
                <div className="header-3 txt-white subsection-titles txm-txt-white">Notification Framework</div>
                <div className="txt-extraLight txm-txt-white">
                  TxM Notification Framework creates efficiency and improves communication by sending automated alerts
                  to all parties when transaction milestones are achieved. These alerts ensure consistent communication
                  to your customers via branded, self-managed message templates.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-action"></div>
                <div className="header-3 txt-white subsection-titles txm-txt-white">Action Items</div>
                <div className="txt-extraLight txm-txt-white">
                  TxM Action Items allow all parties to stay on top of progress by providing 24/7 visibility to current
                  status and any obstacles to closing.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-schedule"></div>
                <div className="header-3 txt-white subsection-titles txm-txt-white">Scheduler</div>
                <div className="txt-extraLight txm-txt-white">
                  TxM Scheduler is built upon Flueid’s Collaboration Fabric and integrates with the closing agent’s
                  enterprise software to manage closing appointments. TxM Scheduler ensures both location and staffing
                  availability and generates confirmation and reminder notifications that allow attendees to easily add
                  appointments to their personal calendars.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-wiresafe"></div>
                <div className="header-3 txt-white subsection-titles txm-txt-white">WireSafe</div>
                <div className="txt-extraLight txm-txt-white">
                  TxM WireSafe ensures wire transfer communication is protected and minimizes wire fraud scams.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-convo"></div>
                <div className="header-3 txt-white subsection-titles txm-txt-white">Conversations</div>
                <div className="txt-extraLight txm-txt-white">
                  TxM Conversations supply a secure and coordinated location for communication surrounding the
                  transaction. TxM Conversations minimizes the risk of email and wire fraud by bringing people together
                  in a trusted, yet easy to use shared space.
                </div>
              </div>
              <div className="info-block-container txm-txt-white">
                <div className="txm-subcontent-icon icon-document"></div>
                <div className="header-3 txt-white subsection-titles txm-txt-white">Document Sharing</div>
                <div className="txt-extraLight txm-txt-white">
                  TxM Document Sharing creates a safe space to upload and exchange necessary documentation without ever
                  compromising sensitive information.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-infosheets"></div>
                <div className="header-3 txt-white subsection-titles txm-txt-white">Infosheets</div>
                <div className="txt-extraLight txm-txt-white">
                  TxM InfoSheets provide customizable forms for all parties to safely, yet easily input the necessary
                  information needed for a transaction. TxM InfoSheets minimize errors, protect customer data, and save
                  the closing agent time by powering a direct import of the transaction data into the enterprise
                  software.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-autograph"></div>
                <div className="header-3 txt-white subsection-titles txm-txt-white">Autograph</div>
                <div className="txt-extraLight txm-txt-white">
                  TxM Autograph saves time and provides unparalleled convenience by allowing customers to digitally
                  sign, and closers to electronically notarize, all closing documentation from anywhere on the planet
                  using just their laptop and an internet connection. TxM Autograph promptly generates earth-friendly
                  digital copies of all closing documentation and protects the integrity of those documents forever
                  through blockchain technology.
                </div>
              </div>
            </div>

            <DashedLineGroup
              visibilityClassName=""
              className="dashed-line-txm-full-page middle-section-color-white margin-top-txm padding-bottom-txm"
              render={(visible: boolean) => (
                <>
                  <DashedLine width={45} duration={1.5} delay={0.2} x={"20vw"} show={visible} color="white" />
                  <DashedLine width={20} duration={1} delay={0.5} x={"21vw"} show={visible} color="white" />
                  <DashedLine width={35} duration={0.5} delay={0.7} x={"25vw"} show={visible} color="white" />
                  <DashedLine width={60} duration={0.5} delay={0.7} x={"28vw"} show={visible} color="white" />
                  <DashedLine width={90} duration={0.5} delay={0.7} x={"32vw"} show={visible} color="white" />
                </>
              )}
            />
          </div>
        </div>

        <div className="txm-middle-section-container middle-section-white">
          <div className="txm-middle-section-content">
            <div>
              <div className="header-1 txt-white middle-section-title">SPS Tools</div>
              <div className="subheader-1 txt-light">
                Flueid has solutions to help SoftPro Select users get more done.
              </div>
              <DashedLineGroup
                visibilityClassName=""
                className="margin-top-txm"
                render={(visible: boolean) => (
                  <>
                    <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
                    <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
                    <DashedLine width={140} duration={1} delay={0.5} x={"18vw"} show={visible} />
                    <DashedLine width={189} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
                  </>
                )}
              />
            </div>
            <div className="txm-middle-section-block">
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-data"></div>
                <div className="header-3 txt-white subsection-titles">Data Warehouse</div>
                <div className="txt-extraLight">
                  Flueid’s Data Warehouse allows SPS users to unlock and easily access the deep layers of information
                  your business has created while using SPS. You can now easily discover where your orders are coming
                  from and which partners and associates are driving your business. No database or query writing skills
                  required — the Data Warehouse presents a “flat,” easy to navigate view of critical business data that
                  can be exported to MS Excel for customized reporting.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-browser"></div>
                <div className="header-3 txt-white subsection-titles">Attachment Browser</div>
                <div className="txt-extraLight">
                  Flueid’s Attachment Browser add-on allows SPS users to view all the attachments stored in their order
                  while working through the data entry screens. The Attachment Browser can be kept visible at all times
                  — particularly convenient for multi-monitor setups — so that the user can browse and view all order
                  documents, including Microsoft Office documents, while moving from screen to screen in SPS.
                </div>
                <div className="txt-extraLight txm-para-spacing">
                  The Attachment Browser also provides PDF manipulation tools for annotating, separating and combining
                  PDFs.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-order"></div>
                <div className="header-3 txt-white subsection-titles">Order Inspector</div>
                <div className="txt-extraLight">
                  Flueid’s Order Inspector allows SPS users to audit the full history of every order. Users can access
                  every order version ever saved and audit every single field value (including custom fields) in every
                  version, to see exactly what the state of the order was at any point in time. The Order Inspector lets
                  you load two different versions at the same time and do a side-by-side comparison of any field in SPS.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-docnamer"></div>
                <div className="header-3 txt-white subsection-titles">Doc Namer</div>
                <div className="txt-extraLight">
                  Flueid’s Document Namer allows SPS administrators to define standard folder location and document
                  naming conventions for use throughout the organization when generating or attaching documents on SPS
                  orders.
                </div>
                <div className="txt-extraLight txm-para-spacing">
                  The Document Namer presents SPS users with a simple user interface for naming generated and attached
                  documents that allows them to select from a list of predefined folders and create a valid document
                  name.
                </div>
              </div>
              <div className="info-block-container">
                <div className="txm-subcontent-icon icon-quick"></div>
                <div className="header-3 txt-white subsection-titles">Quick Attach</div>
                <div className="txt-extraLight">
                  Flueid’s Quick Attach allows SPS users to rapidly search for orders in SPS and quickly queue files
                  (outside documents, emails, scans, etc.) for attachment to the SPS order without having to wait for
                  the order to open/save/close. Attachments can be added without waiting for other users to exit an
                  order, and without blocking other coworkers from the order.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TxMFullPageSection;
