export enum AdminNavTabType {
  Users = 0,
  Partners = 1,
  PartnersClients = 2,
  Company = 1,
  Global = 3,
}

/** List of admin nav tabs that are actually router links and shouldn't just display tab content. */
export const adminNavTabRouterLinks = [AdminNavTabType.Global];

export function isAdminNavTabRouterLink(tab: AdminNavTabType) {
  return adminNavTabRouterLinks.includes(tab);
}
