import { PropertyType } from "dataTypes/properties/propertyType";
import { FarmProperty } from "../../../../../dataTypes/farms";

export const defaultMin = 0;
export const ageDefaultMax = 30;
export const mtgRateDefaultMax = 10;
export const loanToValueDefaultMax = 100;
export const mtgAmountDefaultMax = 8670000;
export const estValueDefaultMax = 8670000;
export const propAgeDefaultMax = 250;
export const storiesDefaultMax = 3;
export const bedBathDefaultMax = 5;
export const sqFtDefaultMax = 15000;

export class CalculatedMaxDefaults {
  equity = 0;
  value = 0;
  sqft = 0;
  propAge = 0;
  constructor(farmProps?: FarmProperty[]) {
    if (farmProps) {
      this.equity = findMaxEquity(farmProps);
      this.value = findMaxValue(farmProps);
      this.sqft = findMaxSqft(farmProps);
      this.propAge = findPropAgeMax(farmProps);
    }
  }
}

export const findMaxEquity = (farmProps: FarmProperty[]): number => {
  let max: number = estValueDefaultMax;
  max = Math.max.apply(
    Math,
    farmProps.map((farmProp: FarmProperty) => farmProp.propertyInfo.estEquity),
  );
  return Math.ceil(max / 10000) * 10000;
};

export const findMaxValue = (farmProps: FarmProperty[]): number => {
  let max: number = estValueDefaultMax;
  max = Math.max.apply(
    Math,
    farmProps.map((farmProp: FarmProperty) => farmProp.propertyInfo.estValue),
  );
  return Math.ceil(max / 10000) * 10000;
};

export const findMaxSqft = (farmProps: FarmProperty[]): number => {
  let maxSqft: number;
  if (!farmProps.length) return 0;
  const largestProp = farmProps.reduce((prev: FarmProperty, current: FarmProperty) => {
    let value1 = 0;
    let value2 = 0;
    prev.propertyInfo.propType === PropertyType.LotLand
      ? (value1 = prev.propertyInfo.lotSqFt)
      : (value1 = prev.propertyInfo.sqFt);
    current.propertyInfo.propType === PropertyType.LotLand
      ? (value2 = current.propertyInfo.lotSqFt)
      : (value2 = current.propertyInfo.sqFt);
    return value1 > value2 ? prev : current;
  });

  largestProp.propertyInfo.propType === PropertyType.LotLand
    ? (maxSqft = largestProp.propertyInfo.lotSqFt)
    : (maxSqft = largestProp.propertyInfo.sqFt);

  return Math.ceil(maxSqft / 50) * 50;
};

export const findPropAgeMax = (farmProps: FarmProperty[]): number => {
  let max = 0;

  //*****
  // How to account for zero values? Math.min will almost always find yearBuilt: 0
  // which ends up making the max value the current year...this makes the range on the slider way too big

  const currentYear = new Date().getUTCFullYear();
  const oldestPropYear = Math.min.apply(
    Math,
    farmProps.map((farmProp: FarmProperty) => farmProp.propertyInfo.yearBuilt),
  );
  max = currentYear - oldestPropYear;

  return max;
};
