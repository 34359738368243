import * as React from "react";
import { connect } from "react-redux";
import AuthNavbar, { AuthNavCloseBtn, AuthNavHeader } from "../authNavbar";
import { Partner } from "../../../dataTypes/partners";
import { Link, RouteComponentProps } from "react-router-dom";
import ApiService, { ApiEndpoints } from "../../../utilities/apiService";
import ErrorHandler from "../../controls/errorHandler";
import AuthService from "../../../utilities/authService";
import { accountActionCreators } from "../../../store/account/actionCreators";
import { ApplicationState } from "../../../store";
import { User } from "../../../dataTypes/user/user";
import { Modal } from "semantic-ui-react";
import { Terms } from "components/privacyTerms/termsConditionsPage";
import { AxiosError } from "axios";
import { getAuthPath } from "../types/authPath";
import AuthFormButton from "./button";

interface OwnProps {
  partner?: Partner;
}

interface ReduxStateProps {
  signedInUser: User | null;
}

interface ReduxDispatchProps {
  clearAccountStore: () => void;
}

type ComponentProps = OwnProps & ReduxStateProps & ReduxDispatchProps & RouteComponentProps;

interface State {
  modalOpen: boolean;
}

class AcceptTermsConditions extends React.Component<ComponentProps, State> {
  state: State = {
    modalOpen: false,
  };

  componentDidMount() {
    const { signedInUser, history } = this.props;

    if (!signedInUser) {
      // redirect to sign in page
      history.push(getAuthPath("/auth/sign-in", this.props.partner));
    }
  }

  handleAcceptClick = () => {
    const { history, location } = this.props;

    ApiService.CallApi("GET", ApiEndpoints.UpdateTermsAcceptance, {})
      .then(() => {
        const state = location.state as Location;
        // any route location state has been passed along? push them to where they were trying to go
        if (state && state.pathname) {
          // grab any location state data that may have been passed along during redirection
          const redirectPath: string = state.pathname || "";
          const searchParams: string = state.search || "";
          history.push(redirectPath + searchParams);
        } else {
          history.push("/app");
        }
      })
      .catch((error: AxiosError) => ErrorHandler.HandleAxiosError(error));
  };

  handleDeclineClick = () => {
    const { clearAccountStore } = this.props;
    AuthService.SignOut()
      .then(() => {
        clearAccountStore();
      })
      .catch((error) => ErrorHandler.HandleError(error));
  };

  render() {
    const { modalOpen } = this.state;

    return (
      <div className="fade-in">
        <AuthNavbar>
          <Link to={getAuthPath("/auth/sign-in", this.props.partner)}>
            <div className="back-btn"></div>
          </Link>
          <AuthNavHeader>Terms and Conditions</AuthNavHeader>
          <AuthNavCloseBtn websiteUrl={this.props.partner?.websiteUrl} />
        </AuthNavbar>
        <div className="form-container auth-form-container">
          <div className="content terms-conditions">
            <div className="flex flex-col items-center">
              <div className="header-4 boldest">Accept Terms and Conditions</div>
              <div className="header-7">Before proceeding you must accept Flueid Pro's Terms and Conditions</div>
              <button
                className="btn btn-large btn-basic cursor-pointer"
                onClick={() => this.setState({ modalOpen: !modalOpen })}>
                View Terms and Conditions
              </button>
            </div>
            <Link to="/" onClick={this.handleDeclineClick} className="txt-sm">
              <u>Decline and Sign Out</u>
            </Link>
            <AuthFormButton onClick={this.handleAcceptClick}>Accept</AuthFormButton>
          </div>
        </div>
        <Modal open={modalOpen} onClose={() => this.setState({ modalOpen: false })} size="small">
          <Modal.Header>
            <div className="terms-content_padding">
              <div className="flex justify-end">
                <div className="icon-close-black" onClick={() => this.setState({ modalOpen: false })} />
              </div>
            </div>
          </Modal.Header>
          <Modal.Content className="modal-content_overflowY">
            <div className="terms-content_padding">
              <Terms />
            </div>
          </Modal.Content>
          <Modal.Actions className="modal-actions_padding">
            <div className="flex justify-center terms-content_padding">
              <AuthFormButton onClick={this.handleAcceptClick}>Accept</AuthFormButton>
            </div>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  signedInUser: state.account.signedInUser,
});

const mapDispatchToProps = {
  clearAccountStore: accountActionCreators.clearAccountStore,
};

export default connect<ReduxStateProps, ReduxDispatchProps, OwnProps, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(AcceptTermsConditions);
