import create from "zustand";

interface Store {
  showSwitchPartnersModal: boolean;
  setShowSwitchPartnersModal: (open: boolean) => void;
}

export const usePartnersModalStore = create<Store>((set) => ({
  showSwitchPartnersModal: false,
  setShowSwitchPartnersModal: (open) =>
    set((state) => ({
      ...state,
      showSwitchPartnersModal: open,
    })),
}));
