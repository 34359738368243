import { DropdownOption } from "dataTypes/common";
import { startCase } from "lodash";

export function hasKey<O extends {}>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}

// Get typed list of keys
export const getKeys = <T extends {}>(o: T): Array<keyof T> => <Array<keyof T>>Object.keys(o);
// ex. Typescript doesn't like letting you use keys returned from Object.keys() to access object properties
// const obj = {test: 123, hello: "my dude"}
// const keys = Object.keys(obj);
// keys.forEach(k => obj[k]); // TS errors => type 'string' can't be used to index type '{ test: number; hello: string; }'

/** Determines if there are any truthy values on an object. This is a shallow depth check, it will not recursively check nested objects. */
export function objectHasValues<T extends {}>(obj?: T | null) {
  if (!obj) return false;

  try {
    return !!Object.values(obj).filter(Boolean).length;
  } catch (error) {
    return false;
  }
}

/**
 * Convert typescript enum to an array of objects ([{value: 1, text: "example"}])
 * @param {enum} Enum - Enum to convert
 * @param {boolean} useStartCase - Whether to title case the key or not.
 * @param {Map} labelMap - A map of specific labels to use instead of the key (if map is provided and the label doesn't exist for the key, it will use startCase).
 */
export const enumToDictArray = (Enum: any, useStartCase: boolean = true, labelMap?: Map<any, string>) =>
  Object.keys(Enum)
    .filter((item) => isNaN(parseInt(item)) && item !== "Undefined")
    .map((key: any) => {
      const labelFromMap = labelMap?.get(parseInt(Enum[key]));
      const label = !!labelFromMap ? labelFromMap : useStartCase ? startCase(key) : key;
      return {
        value: parseInt(Enum[key]),
        text: label,
      };
    });

type TextConfig = {
  labelMap?: Map<any, string>;
  enum?: Record<string | number, string | number>;
};

/**
 * Convert array of strings or numbers into array of DropdownOption objects
 * @param values - array of string or values to convert
 * @param config - optional config for the DropdownOption.text field. Accepts enum or Map to determine the text for each option.
 * @returns - array of DropdownOption objects
 */
export function valuesToOptions<T extends string | number>(
  values?: T[],
  config?: TextConfig,
): DropdownOption<T>[] | undefined {
  return values?.map((value) => {
    let text = String(value);
    if (config?.enum) {
      text = config.enum[value] ? startCase(String(config.enum[value])) : text;
    }
    if (config?.labelMap) text = config.labelMap.get(value) || text;
    return { text, value };
  });
}
