import { parseIncompletePhoneNumber } from "libphonenumber-js";

export const constrainRangeSliderValues = (
  values: string[],
  min: number,
  max: number,
  step: number,
  isMin: boolean,
): number[] => {
  let minValue = parseInt(values[0], 10) || min;
  let maxValue = parseInt(values[1], 10) || step; // Max should be one step above min if invalid or 0.

  if (min > minValue) {
    minValue = min;
  }

  if (max <= minValue) {
    minValue = max;
  }

  if (min >= maxValue) {
    maxValue = min;
  }

  if (max < maxValue) {
    maxValue = max;
  }

  if (!isMin && minValue >= maxValue) {
    minValue = maxValue;
  }

  if (isMin && maxValue <= minValue) {
    maxValue = minValue;
  }

  return [minValue, maxValue];
};

// used to deal with libphonenumber-js bug that doesn't allow deletion of area code parentheses
export function parseIncompletePhone(phone: string): string {
  // null check
  if (!phone) {
    // if phone number is null, return empty string
    return "";
  }

  let newPhoneValue: string = phone;

  const openParentheses = /[(]/g;
  const closeParentheses = /[)]/g;

  // if there're no matching parentheses, slice off the parentheses
  if (phone.match(openParentheses) && !phone.match(closeParentheses)) {
    newPhoneValue = parseIncompletePhoneNumber(newPhoneValue.slice(0, -1));
  } else {
    // otherwise use the libphonenumber-js method to parse number
    newPhoneValue = parseIncompletePhoneNumber(newPhoneValue);
  }

  return newPhoneValue;
}

export function maskSSN(ssn: string): string {
  let maskedSSN: string = "";

  for (let i = 0; i < ssn.length; i++) {
    if (i === 2) {
      if (ssn.length <= 3) {
        maskedSSN += "x";
      } else {
        maskedSSN += "x-";
      }
    } else if (i === 4) {
      if (ssn.length <= 5) {
        maskedSSN += "x";
      } else {
        maskedSSN += "x-";
      }
    } else if (i >= 5) {
      maskedSSN += ssn[i];
    } else {
      maskedSSN += "x";
    }
  }

  return maskedSSN;
}

export function unmaskSSN(ssn: string): string {
  let unmaskedSSN: string = "";

  for (let i = 0; i < ssn.length; i++) {
    if (i === 2) {
      if (ssn.length <= 3) {
        unmaskedSSN += ssn[i];
      } else {
        unmaskedSSN += `${ssn[i]}-`;
      }
    } else if (i === 4) {
      if (ssn.length <= 5) {
        unmaskedSSN += ssn[i];
      } else {
        unmaskedSSN += `${ssn[i]}-`;
      }
    } else {
      unmaskedSSN += ssn[i];
    }
  }

  return unmaskedSSN;
}

export const formatTaxId = (str: string) => (str.length > 2 ? `${str.slice(0, 2)}-${str.slice(2)}` : str);

export function valueExceedsMax(
  currentValue: string | number,
  newValue: string | number,
  max: string | number,
): boolean {
  return currentValue.toString().length === max && newValue.toString().length > currentValue.toString().length
    ? true
    : false;
}
