import { getCurrentPartnerAssociation, hasMultiPartners, UserPartnerAssociation } from "dataTypes/user/user";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Dimmer, Loader, Modal } from "semantic-ui-react";
import { ApplicationState } from "store";
import { stringifyParams } from "utilities/apiService";
import useSearchParams from "utilities/hooks/useSearchParams";
import { getActivePartners, setCurrentPartner } from "./queries";
import { usePartnersModalStore } from "./store";
import styles from "./styles.module.scss";
import ErrorHandler from "../errorHandler";
import { AxiosError } from "axios";

export const fromSignIn = "fromSignIn";
export const fromSignInParam = stringifyParams({ fromSignIn: true });

type PartnerPickerFormProps = {
  handleClose: () => void;
};

const PartnerPickerForm = ({ handleClose }: PartnerPickerFormProps) => {
  const user = useSelector((state: ApplicationState) => state.account.signedInUser);
  const currPartnerId = getCurrentPartnerAssociation(user)?.partnerId;
  const [partnerId, setPartnerId] = useState(currPartnerId);
  const [pending, setPending] = useState(false);
  const [activePartners, setActivePartners] = useState<UserPartnerAssociation[]>();
  const [activePartnersLoading, setActivePartnersLoading] = useState(true);

  // set the newly selected partner
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (partnerId) {
      setPending(true);
      try {
        await setCurrentPartner(partnerId);

        // remove the fromSignIn query param and reassign search to trigger reload so no stale partner content shows
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete(fromSignIn);
        window.location.search = urlParams.toString();
      } catch (e) {
        ErrorHandler.HandleAxiosError(e as AxiosError);
      }

      setPending(false);
    }
  };

  // fetch active partners
  useEffect(() => {
    getActivePartners()
      .then((resp) => setActivePartners(resp.data))
      .catch((err) => ErrorHandler.HandleAxiosError(err as AxiosError))
      .finally(() => setActivePartnersLoading(false));
  }, []);

  return (
    <div className="notification-modal__content">
      {activePartnersLoading && (
        <Dimmer active inverted>
          <Loader>Loading companies</Loader>
        </Dimmer>
      )}
      {!activePartnersLoading && !!activePartners?.length && (
        <form onSubmit={handleSubmit} className={styles.form}>
          <h3 className={`notification-modal__header ${styles.mbSm}`}>Switch Title Company</h3>
          <p className={`large ${styles.mbLg}`}>Select the Title Company</p>
          <div className={styles.buttons + " " + styles.mbMd}>
            {activePartners?.map((partner) => (
              <button
                className={`${styles.partnerBtn} ${partnerId === partner.partnerId ? styles.partnerBtnActive : ""}`}
                key={`partner-btn-${partner.partnerId}`}
                type="button"
                title={`Click to select ${partner.companyName} as current partner`}
                onClick={() => setPartnerId(partner.partnerId)}>
                {partner.logoMainUrl ? (
                  <img className={styles.logo} src={partner.logoMainUrl} alt={`${partner.companyName} logo`} />
                ) : (
                  <h5>{partner.companyName}</h5>
                )}
              </button>
            ))}
          </div>
          <button
            className={`btn-new ${styles.mbMd}`}
            disabled={partnerId === undefined || pending}
            title={partnerId === undefined ? "Please select one of the options above" : "Click to set title company"}
            type="submit">
            Continue
          </button>
          <p className="cursor-pointer" onClick={handleClose}>
            <u>Cancel</u>
          </p>
        </form>
      )}
      {!activePartnersLoading && !activePartners?.length && (
        <>
          <h3 className={`notification-modal__header ${styles.mbSm}`}>Sorry, no companies were found.</h3>
          <p className={`large ${styles.mbLg}`}>Please contact your account manager if the issue persists.</p>
          <button className={`btn-new ${styles.mbMd}`} title="Close modal" onClick={handleClose}>
            Close
          </button>
        </>
      )}
    </div>
  );
};

export const SwitchPartnersModal = () => {
  const user = useSelector((state: ApplicationState) => state.account.signedInUser);
  const justSignedIn = !!useSearchParams().get(fromSignIn);
  const hasMultiplePartners = hasMultiPartners(user);
  const { showSwitchPartnersModal, setShowSwitchPartnersModal } = usePartnersModalStore((state) => state);
  const history = useHistory();
  const open = showSwitchPartnersModal || justSignedIn;

  const replaceFromSignInParam = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(fromSignIn);
    history.replace(location.pathname + "?" + searchParams.toString());
  };

  // close partner picker & remove fromSignIn query param
  const handleClose = () => {
    justSignedIn && replaceFromSignInParam();
    setShowSwitchPartnersModal(false);
  };

  // clear fromSignIn search param if they don't have multi partners
  useEffect(() => {
    if (!hasMultiplePartners) {
      replaceFromSignInParam();
    }
  }, []);

  // having this down here will help with React's "Rendered more hooks than during the previous render" message
  if (!hasMultiplePartners) return null;

  return (
    <Modal open={open} onClose={handleClose} className="notification-modal">
      <PartnerPickerForm handleClose={handleClose} />
    </Modal>
  );
};
