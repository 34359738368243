import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { SearchOrdersResult } from "../../dataTypes/orders/interfaces/orderLite";
import { OrderDetailResponse } from "../../dataTypes/orders/interfaces/order";
import { OrderSearchRequest, OrdersSortBy } from "../../dataTypes/orders/interfaces/orderSearchRequest";
import { OrderStatus } from "../../dataTypes/orders/enums/orderStatus";
import { newReqStatus, ReqStatus } from "utilities/apiService";

export interface OrdersState {
  searchResults: SearchOrdersResult;
  searchOrdersPending: boolean;
  orderDetailResponse: OrderDetailResponse | null;
  orderDetailReqStatus: ReqStatus;
  orderSearch: OrderSearchRequest;
}

export const initialOrderSearch: OrderSearchRequest = {
  orderTypes: [],
  orderServiceTypes: [],
  // right now the way the UI is designed, only 1 order status is ever active, but the API expects an array of statuses
  orderStatuses: [OrderStatus.InProcess, OrderStatus.CancelRequested],
  searchText: "",
  minOpenDate: "",
  maxOpenDate: "",
  minCloseDate: "",
  maxCloseDate: "",
  sortBy: OrdersSortBy.OpenedDateDesc,
  pageSize: 36,
  pageIndex: 1,
};

const unloadedState: OrdersState = {
  searchResults: {
    orders: [],
    pageCount: 0,
    orderCount: 0,
  },
  searchOrdersPending: false,
  orderDetailResponse: null,
  orderDetailReqStatus: newReqStatus(),
  orderSearch: { ...initialOrderSearch },
};

export const OrdersReducer: Reducer<OrdersState, KnownAction> = (state = unloadedState, action: KnownAction) => {
  switch (action.type) {
    case "SEARCH_ORDERS_PENDING":
      return { ...state, searchOrdersPending: true };
    case "SEARCH_ORDERS":
      return { ...state, searchResults: action.payload, searchOrdersPending: false };
    case "GET_ORDER_DETAIL":
      return { ...state, orderDetailResponse: action.payload };
    case "SET_ORDER_DETAIL_REQ_STATUS":
      return { ...state, orderDetailReqStatus: action.payload };
    case "CLEAR_ORDER_DETAIL":
      return { ...state, orderDetailResponse: null };

    case "SET_ORDER_STATUS_FILTER":
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          orderStatuses: action.payload,
        },
      };
    case "SET_ORDER_SEARCH":
      return {
        ...state,
        orderSearch: {
          ...action.payload,
        },
      };
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          pageIndex: 1,
          searchText: action.payload,
        },
      };
    case "SET_PAGE_SIZE":
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          pageSize: action.payload,
        },
      };
    case "SET_PAGE_INDEX":
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          pageIndex: action.payload,
        },
      };
    case "SET_ORDERS_SORT_BY":
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          sortBy: action.payload,
        },
      };
    case "RESET_ORDER_SEARCH":
      return {
        ...state,
        orderSearch: { ...initialOrderSearch },
      };
    default:
      return state || unloadedState;
  }
};
