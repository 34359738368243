import { trimAllWhitespace } from "./formatters";

export default class Validate {
  static Email(email: string): boolean {
    return /^\S+@\S+$/.test(email);
  }

  static Phone(phone: string): boolean {
    // remove all white space from phone
    const trimmedPhone: string = trimAllWhitespace(phone);
    return trimmedPhone.match(/^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/) ? true : false;
  }

  static Password(password: string): string[] {
    const errorMessages = [];

    if (!PasswordValidator.MinLength(password)) {
      errorMessages.push("Password must have at least 8 characters");
    }

    if (!PasswordValidator.HasNumbers(password)) {
      errorMessages.push("Password must have numeric characters");
    }

    if (!PasswordValidator.HasUpperCaseLetters(password)) {
      errorMessages.push("Password must have upper case characters");
    }

    if (!PasswordValidator.HasLowerCaseLetters(password)) {
      errorMessages.push("Password must have lower case characters");
    }

    if (!PasswordValidator.HasNoWhiteSpaces(password)) {
      errorMessages.push("Password must not have any spaces");
    }

    // New password criteria doesn't require special characters
    //if (!PasswordValidator.HasSpecialCharacters(password)) {
    //	errorMessages.push("Password must have special characters");
    //}

    return errorMessages;
  }

  static ConfirmationCode(confirmationCode: string): boolean {
    // must be 6 characters exactly and only numbers
    return /^\d{6}$/.test(confirmationCode);
  }

  static Zipcode(zipcode: string): boolean {
    return /^(\d{5})?$/.test(zipcode) ? true : false;
  }

  static SSN(ssn: string): boolean {
    return /^\d{4}/.test(ssn);
  }

  static TaxId(taxId: string): boolean {
    return taxId.match(/^\d{2}-?\d{7}$/) ? true : false;
  }

  static Value(value: string | number): boolean {
    // used for making sure some type of value exists for the input
    return value !== null && value !== undefined && value !== "" && value !== 0 ? true : false;
  }

  static NotNullOrUndefined(
    value: string | number | null | undefined | boolean | (string | number | boolean)[],
  ): boolean {
    return value !== null && value !== undefined ? true : false;
  }

  static CustomPattern(pattern: RegExp, value: string): boolean {
    return pattern.test(value);
  }
}

class PasswordValidator {
  static MinLength(password: string): boolean {
    return password.length >= 8 ? true : false;
  }

  static HasNumbers(password: string): boolean {
    const regexWithNumbers = /[0-9]/;
    return regexWithNumbers.test(password);
  }

  static HasUpperCaseLetters(password: string): boolean {
    const regexWithUpperCase = /[A-Z]/;
    return regexWithUpperCase.test(password);
  }

  static HasLowerCaseLetters(password: string): boolean {
    const regexWithLowerCase = /[a-z]/;
    return regexWithLowerCase.test(password);
  }

  static HasNoWhiteSpaces(password: string): boolean {
    const regexWithoutWhiteSpaces = /^\S*$/;
    return regexWithoutWhiteSpaces.test(password);
  }

  static HasSpecialCharacters(password: string): boolean {
    const regexWithSpecialCharacters = /[!@$#^|%"`'&/(){}<>[\]=?+*^~\-_.:,;]/;
    const testForBackslash = new RegExp("\\\\", "");
    return regexWithSpecialCharacters.test(password) || testForBackslash.test(password);
  }
}
