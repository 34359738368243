import { Entry } from "contentful";
import React, { useEffect, useState } from "react";
import { ReactComponent as FlueidLogoWhite } from "../../css/images/svg/logos/flueid-logomark-white.svg";
import { FlueidProBanner } from "../homePage/flueidProBanner";
import { useFetchContentfulAlerts } from "./queries";
import { IAlert } from "./types";

export const INFO_ALERT_TIMEOUT = 10000;
const ALERT_FADEIN_TIMEOUT = 2000;

const AlertBanner: React.FC<{ className?: string; alert: Entry<IAlert> }> = ({ children, className, alert }) => {
  const [show, setShow] = useState<boolean>(false);
  // going from false to true will trigger animation
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
      // info alerts auto-hide after timeout
      if (alert.fields.severity === "Info") {
        setTimeout(() => {
          setShow(false);
        }, INFO_ALERT_TIMEOUT);
      }
    }, ALERT_FADEIN_TIMEOUT);
  }, []);

  return (
    <FlueidProBanner show={show} className={className}>
      <div className="flex flex-1 justify-between items-center">
        <div className="flex-1 alert-content_margin">
          <div className="flex items-center">
            <FlueidLogoWhite className="logo-flueidpro-white" />
            <div className="divider" />
            {children}
          </div>
        </div>
        <div className="icon-close m-l_sm" onClick={() => setShow(false)} />
      </div>
    </FlueidProBanner>
  );
};

const Alert = ({ scope }: Pick<IAlert, "scope">) => {
  const { data: alerts } = useFetchContentfulAlerts(scope);

  return alerts ? (
    <>
      {alerts.map(
        (alert) =>
          alert.fields.scope === scope && (
            <AlertBanner key={alert.sys.id} alert={alert} className={`flueid-pro-banner_${alert.fields.severity}`}>
              <p>{alert.fields.description}</p>
            </AlertBanner>
          ),
      )}
    </>
  ) : null;
};

export default Alert;
