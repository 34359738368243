import { enumToDictArray } from "utilities/typescriptHelpers";
import { IndustryType } from "../partners";
import { UserLite } from "./userLite";

export enum UserStatus {
  Disabled = 0,
  Active,
  Pending,
  Approved,
  Declined,
}

export interface UpdateUserStatusRequest {
  userId: number;
  status: UserStatus;
}

export interface User {
  address1: string;
  address2: string;
  assistants: RelatedUser[];
  branchId: number;
  city: string;
  companyId: number;
  companyLogoUrl: string;
  companyName: string;
  currentPartner: UserPartnerAssociation | null;
  divisionId: number;
  email: string;
  emailSecondary: string;
  firstName: string;
  fullName: string;
  groups: UserGroup[];
  identityId: string;
  industryType: IndustryType;
  internalUser: boolean;
  lastName: string;
  middleName: string;
  partnerAssociations: UserPartnerAssociation[];
  phoneDirect: string;
  phoneFax: string;
  phoneMobile: string;
  photoUrl: string;
  role: string;
  state: string;
  status: UserStatus;
  suffix: string;
  termsAccepted: boolean;
  title: string;
  userId: number;
  username: string;
  zip: string;
  lastLoginDate: string | null;
  licenseNumber: string;
}

export type UserKey = keyof User;

export const emptyUser: User = {
  address1: "",
  address2: "",
  city: "",
  companyName: "",
  email: "",
  emailSecondary: "",
  firstName: "",
  fullName: "",
  identityId: "",
  lastName: "",
  middleName: "",
  phoneDirect: "",
  phoneFax: "",
  phoneMobile: "",
  photoUrl: "",
  state: "",
  status: 0,
  suffix: "",
  title: "",
  userId: 0,
  username: "",
  zip: "",
  industryType: 0,
  partnerAssociations: [],
  currentPartner: null,
  assistants: [],
  companyLogoUrl: "",
  termsAccepted: false,
  companyId: 0,
  divisionId: 0,
  branchId: 0,
  internalUser: false,
  groups: [],
  role: "",
  lastLoginDate: null,
  licenseNumber: "",
};

/** Used for form validation to determine if license number is required field. */
export function userLicenseNumberRequired(userGroups?: UserGroup[]) {
  return !!userGroups?.some((x) => x === UserGroup.DecisionRealEstatePro);
}

/** Text for Info Popup associated with user's license number inputs. */
export const userLicenseNumberInfoText =
  "Please provide your individual license number if you are a real estate agent.";

export interface UserMembership {
  branches: number[];
  companies: number[];
  divisions: number[];
  groups: number[];
}

export interface UserPartnerCompany {
  companyName: string;
  companyId: number;
  divisionId: number;
  branchId: number;
}

export interface UserPartnerAssociation {
  accountManagers: RelatedUser[];
  branchId: number;
  divisionId: number;
  escrowOfficers: RelatedUser[];
  isDefault: boolean;
  partnerId: number;
  titleOfficers: RelatedUser[];
  logoMainUrl?: string;
  companyName?: string;
  company: UserPartnerCompany;
  groups: UserGroup[];
  assistants: RelatedUser[];
  status: UserStatus;
}

export function getCurrentPartnerAssociation(user: User | null): UserPartnerAssociation | undefined {
  return user?.partnerAssociations.find((p) => p.isDefault);
}

export function getPartnerAssociationById(user?: User | null, partnerId?: number) {
  return user?.partnerAssociations?.find((x) => x.partnerId === partnerId);
}

export function hasMultiPartners(user: User | null): boolean {
  return !!(user?.partnerAssociations && user?.partnerAssociations?.length > 1);
}

export interface UpdatePartnerAssociationRequest {
  userId: number;
  partnerAssociation: UserPartnerAssociation;
}

export interface UpdateUserAssistantsRequest {
  userId: number;
  partnerId: number;
  assistants: number[];
}

export interface RelatedUser {
  userId: number;
  autoSelect: boolean;
}

/**
 * UserGroups can often be used to describe internal vs. external users, available permissions & roles.
 * Internal users work with a partner company, doing title & escrow related services.
 * External users work with a partner's client company, people like lenders, listing agents, sellers etc.
 */
// when adding new UserGroup member, add it to userGroupLabels below for consistency in labels for this enum
export enum UserGroup {
  /** External user - Business Source */
  FinancePro = 1,

  /** External user - Business Source */
  RealEstatePro = 2,

  /** External user */
  Assistant = 3,

  /* Internal User - Business Source */
  TitleOfficer = 4,

  /* Internal User - Business Source */
  EscrowOfficer = 5,

  /* Internal User - Business Source */
  AccountExecutive = 6,

  TransactionCoordinator = 7,
  Executive = 8,
  Consumer = 9,
  Guest = 10,

  /* Flueid System Admin - RESTRICTED */
  Admin = 11,

  Trustee = 12,
  TitleAssistant = 14,
  EscrowAssistant = 15,

  /** External user */
  BranchAssistant = 16,

  /* Internal User - Admin */
  BranchAdmin = 17,

  /** External user - Admin */
  CompanyAdmin = 18,

  /* Internal User - Admin */
  PartnerAdmin = 19,

  /* Internal User - Admin */
  OpenOrderClerk = 20,

  /** External user - Business Source */
  DecisionLender = 21,

  /* Internal User - Business Source */
  DecisionTitle = 22,

  /** External user - Business Source */
  IndependentEscrowOfficer = 23,

  FlueidOnboardingAdmin = 24,
  FlueidInternalAdmin = 25,

  /** External user - Admin */
  CompanyManager = 26,

  /** External user - Business Source */
  DecisionRealEstatePro = 27,

  /** External user - Business Source */
  DecisionServicer = 28,

  FldCompleteUser = 29,
}

// map for user groups that need a custom label different from enum's reverse mapping
export const userGroupLabels = new Map<UserGroup, string>([
  [UserGroup.FinancePro, "Finance Pro"],
  [UserGroup.RealEstatePro, "Real Estate Pro"],
  [UserGroup.Assistant, "Assistant"],
  [UserGroup.TitleOfficer, "Title Officer"],
  [UserGroup.EscrowOfficer, "Escrow Officer"],
  [UserGroup.AccountExecutive, "Account Manager"],
  [UserGroup.TransactionCoordinator, "Transaction Coordinator"],
  [UserGroup.Executive, "Executive"],
  [UserGroup.Consumer, "Consumer"],
  [UserGroup.Guest, "Guest"],
  [UserGroup.Admin, "Flueid Pro Admin"],
  [UserGroup.Trustee, "Trustee"],
  [UserGroup.TitleAssistant, "Title Assistant"],
  [UserGroup.EscrowAssistant, "Escrow Assistant"],
  [UserGroup.BranchAssistant, "Branch Assistant"],
  [UserGroup.BranchAdmin, "Branch Manager"],
  [UserGroup.CompanyAdmin, "Company Administrator"],
  [UserGroup.PartnerAdmin, "Partner Admin"],
  [UserGroup.OpenOrderClerk, "Open Order Clerk"],
  [UserGroup.DecisionLender, "TitleCheck for Lender"],
  [UserGroup.DecisionTitle, "TitleDecision"],
  [UserGroup.IndependentEscrowOfficer, "Independent Escrow Officer"],
  [UserGroup.FlueidOnboardingAdmin, "Flueid Onboarding Admin"],
  [UserGroup.FlueidInternalAdmin, "Flueid Internal Admin"],
  [UserGroup.CompanyManager, "Company Manager"],
  [UserGroup.DecisionRealEstatePro, "TitleCheck for Real Estate Pro"],
  [UserGroup.DecisionServicer, "TitleCheck for Servicer"],
  [UserGroup.FldCompleteUser, "Flueid Complete User"],
]);

const compare = new Intl.Collator().compare;

/** Full list of all user groups as dropdown options */
export const userGroupOptions = enumToDictArray(UserGroup, false, userGroupLabels).sort((a, b) =>
  compare(a.text, b.text),
);

export const hasUserGroup = (group: UserGroup, user?: User | UserLite | null) => {
  return user?.groups?.some((x) => x === group);
};
