import { HasPermission, HasPermissionProps } from "dataTypes/user/permissionTypes/utils";
import { UnregisterCallback } from "history";
import * as React from "react";
import { withRouter, Route, Redirect, RouteComponentProps, RouteProps } from "react-router-dom";
import AuthService from "../../utilities/authService";

type ComponentProps = HasPermissionProps & RouteProps & RouteComponentProps;

interface State {
  loaded: boolean;
  isAuthenticated: boolean;
}

class PrivateRoute extends React.Component<ComponentProps, State> {
  unregisterHistoryListener: UnregisterCallback | null = null;

  state: State = {
    loaded: false,
    isAuthenticated: false,
  };

  componentDidMount() {
    this.authenticate();
    this.listen();
  }

  componentWillUnmount() {
    // unregister listener prevents unmounted private route from trying to update state in listen's catch block
    if (this.unregisterHistoryListener) this.unregisterHistoryListener();
  }

  // if the route changes, this fires to check and see if user is still signed in
  listen = () => {
    this.unregisterHistoryListener = this.props.history.listen(() => {
      AuthService.GetCurrentAuthenticatedUser()
        .then((user) => console.log("user authenticated"))
        .catch(() => {
          if (this.state.isAuthenticated) this.setState({ isAuthenticated: false });
        });
    });
  };

  authenticate() {
    AuthService.GetCurrentAuthenticatedUser()
      .then(() => {
        this.setState({ loaded: true, isAuthenticated: true });
      })
      .catch(() => {
        this.setState({ loaded: true });
      });
  }

  render() {
    const {
      component: Component,
      permTypes,
      hasSome,
      showFallbackUI = true,
      customCheck,
      behavior,
      fallbackUI,
      render: Render,
      ...rest
    } = this.props;
    const { loaded, isAuthenticated } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated ? (
            <HasPermission
              permTypes={permTypes}
              behavior={behavior}
              hasSome={hasSome}
              customCheck={customCheck}
              showFallbackUI={showFallbackUI}
              fallbackUI={fallbackUI}>
              {Render ? Render(props) : Component ? <Component {...props} /> : null}
            </HasPermission>
          ) : (
            <Redirect
              to={{
                pathname: "/auth",
                // pass location along with the redirection so the auth container will have data
                // of where the user was trying to go
                state: props.location,
              }}
            />
          );
        }}
      />
    );
  }
}

export default withRouter(PrivateRoute);
