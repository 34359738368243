import * as React from "react";
import { Link } from "react-router-dom";

const AuthNavBar: React.FC = ({ children }) => {
  return (
    <div className="navbar-auth-container">
      <div className="navbar-auth-content navbar-width">{children}</div>
    </div>
  );
};

export const AuthNavHeader: React.FC = ({ children }) => (
  <div className="header-8 boldest navigavation-HeaderTxt">{children}</div>
);

export const AuthNavCloseBtn: React.FC<{ websiteUrl: string | undefined }> = ({ websiteUrl }) =>
  websiteUrl ? (
    <a href={websiteUrl} style={{ height: "15px" }}>
      <div className="closing-btn" />
    </a>
  ) : (
    <Link to="/" style={{ height: "15px" }}>
      <div className="closing-btn" />
    </Link>
  );

export default AuthNavBar;
