import { AxiosError, AxiosResponse } from "axios";
import { UnregisterCallback } from "history";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ApiService, { ApiEndpoints } from "../../utilities/apiService";
import * as sourceStackTrace from "sourcemapped-stacktrace";
import { DefaultFallbackUI } from "./fallbackPage";
import { isDev } from "utilities/misc";

interface Props {
  children?: React.ReactNode;
  fallbackUI?: React.ReactNode;
  showFallbackUI?: boolean; // sometimes we may not want to show any fallbackUI, like if this is wrapped around a widget and we just want nothing to show
  useRouteListener?: boolean; // might not always need to use history.listen, like if this is wrapped around a widget and won't need to reset state.hasError when route changes
}

type ComponentProps = Props & RouteComponentProps;

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ComponentProps, State> {
  unregisterListener: UnregisterCallback | null = null;

  state: State = {
    hasError: false,
  };

  componentDidMount() {
    // default useRouteListener to true
    const { history, useRouteListener = true } = this.props;
    if (useRouteListener) {
      // route changes, reset state
      this.unregisterListener = history.listen(() => {
        if (this.state.hasError) this.setState({ hasError: false });
      });
    }
  }

  componentDidCatch(_error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true });

    // get stack trace and submit to log service
    sourceStackTrace.mapStackTrace(_error.stack || info.componentStack, (mappedStack: string[]) => {
      // format error string with line breaks
      const error: string = _error.message + "\n" + mappedStack.join("\n");
      console.log(error);
      if (!isDev) {
        //Send Errors to CloudWatch thru API call
        ApiService.CallApi(
          "POST",
          ApiEndpoints.LogError,
          { error },
          () => {},
          () => {},
          { postWithURLQueryParams: true },
        )
          .then((resp: AxiosResponse) => console.log("error logged"))
          .catch((resp: AxiosError) => console.log("issue with logging error: ", resp));
      }
    });
  }

  componentWillUnmount() {
    if (this.unregisterListener) this.unregisterListener();
  }

  render() {
    const { children, fallbackUI, showFallbackUI } = this.props;
    // error, determine what to show
    if (this.state.hasError) {
      if (showFallbackUI === false) return null;
      return fallbackUI || <DefaultFallbackUI />;
    }
    return children;
  }
}

export default withRouter(ErrorBoundary);
