import { Reducer, combineReducers } from "redux";
import { KnownAction as FiltersAction } from "./filters/actionTypes";
import { KnownAction as MapAction } from "./map/actionTypes";
import { KnownAction as UiAction } from "./ui/actionTypes";
import { KnownAction } from "./actionTypes";
import { SalesCompsUiState, initialSalesCompsUiState } from "./ui/salesCompsUiState";
import { SalesCompsFiltersState, initialSalesCompsFiltersState } from "./filters/salesCompsFiltersState";
import { PropertyDetailLite } from "../../dataTypes/properties/propertyDetailLite";
import { SalesCompsMapState } from "./map/salesCompsMapState";
import { ReqStatus, newReqStatus, newPendingReq, reqFailed, reqOk } from "../../utilities/apiService";

interface SalesCompsPropertiesState {
  salesComps: PropertyDetailLite[];
  getSalesCompsReqStatus: ReqStatus;
  subjectPropDetailLite: PropertyDetailLite | null;
  getSubjectPropLiteReqStatus: ReqStatus;
}

export interface SalesCompsState {
  properties: SalesCompsPropertiesState;
  ui: SalesCompsUiState;
  defaultFilters: SalesCompsFiltersState;
  filters: SalesCompsFiltersState;
  map: SalesCompsMapState;
}

const initialState: SalesCompsState = {
  properties: {
    salesComps: [],
    getSalesCompsReqStatus: newReqStatus(),
    subjectPropDetailLite: null,
    getSubjectPropLiteReqStatus: newReqStatus(),
  },
  map: new SalesCompsMapState(),
  ui: initialSalesCompsUiState,
  filters: initialSalesCompsFiltersState,
  defaultFilters: initialSalesCompsFiltersState,
};

export type SalesCompsAction = FiltersAction | MapAction | UiAction | KnownAction;

export const SalesCompsReducer = combineReducers<SalesCompsState, SalesCompsAction>({
  properties: (state: SalesCompsPropertiesState = initialState.properties, action: SalesCompsAction) => {
    switch (action.type) {
      case "GET_SALES_COMPS_PENDING":
        return { ...state, getSalesCompsReqStatus: newPendingReq() };
      case "GET_SALES_COMPS":
        return { ...state, salesComps: action.payload, getSalesCompsReqStatus: reqOk() };
      case "GET_SALES_COMPS_FAILED":
        return { ...state, getSalesCompsReqStatus: reqFailed() };
      case "GET_SUBJECT_PROP_LITE_PENDING":
        return { ...state, getSubjectPropLiteReqStatus: newPendingReq() };
      case "GET_SALES_COMPS_SUBJECT_PROP_LITE":
        return { ...state, subjectPropDetailLite: action.payload, getSubjectPropLiteReqStatus: reqOk() };
      case "GET_SUBJECT_PROP_LITE_FAILED":
        return { ...state, getSubjectPropLiteReqStatus: reqFailed() };
      default:
        return state;
    }
  },
  map: (state: SalesCompsMapState = initialState.map, action: SalesCompsAction) => {
    switch (action.type) {
      case "SET_COMPS_IW_CONTENT_TYPE":
        return { ...state, iwContentType: action.payload };
      case "SET_SELECTED_PROP_ID":
        return { ...state, selectedPropId: action.payload };
      case "SET_COMPS_MAP_CENTER_POINT":
        return { ...state, centerPoint: action.payload };
      case "SET_COMPS_MAP_STATE":
        return { ...state, ...action.payload };
      case "SET_COMPS_MAP_OPTIONS":
        return { ...state, options: { ...state.options, ...action.payload } };
      default:
        return state;
    }
  },

  ui: (state: SalesCompsUiState = initialState.ui, action: SalesCompsAction) => {
    switch (action.type) {
      case "SET_MOBILE_MAP_VISIBLE":
        return { ...state, mobileMapVisible: action.payload };
      case "SET_SORT_TYPE":
        return { ...state, sortType: action.payload };
      case "SET_SLIDER_VALUES":
        return { ...state, sliderValues: action.payload };
      default:
        return state;
    }
  },

  filters: (state: SalesCompsFiltersState = initialState.filters, action: SalesCompsAction) => {
    switch (action.type) {
      case "SET_SALES_COMPS_FILTERS":
        return { ...state, ...action.payload };
      case "RESET_FILTERS":
        return { ...initialSalesCompsFiltersState };
      default:
        return state || initialSalesCompsFiltersState;
    }
  },
  defaultFilters: (state: SalesCompsFiltersState = initialState.defaultFilters, action: SalesCompsAction) => {
    switch (action.type) {
      case "SET_DEFAULT_FILTERS":
        return { ...state, ...action.payload };
      default:
        return state || initialSalesCompsFiltersState;
    }
  },
});

export const SalesCompsRootReducer: Reducer<SalesCompsState, SalesCompsAction> = (
  state = initialState,
  action: SalesCompsAction,
) => {
  if (action.type === "RESET_SALES_COMPS_STORE") {
    return initialState;
  }
  return SalesCompsReducer(state, action);
};
