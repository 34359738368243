import { PropertyType, allPropTypes, propTypeFiltersByPropType } from "./propertyType";
import { PropertyDetailLite } from "./propertyDetailLite";
import { PropertyDetail } from "./propertyDetail/propertyDetail";
import { PropLocation } from "./propertyDetail/propLocation";
import { PropertyCharacteristics } from "./propertyDetail/propertyCharacteristics/propertyCharacteristics";
import * as QueryString from "query-string";
import { CancelToken } from "axios";
import { hasKey } from "utilities/typescriptHelpers";
import { ParseOptions } from "query-string";
import { getPropDetailValueInfo, getPropValueInfo } from "components/titlefyPro/propertyDetails/utilities/getPropValue";

// params passed to get sales comps action creator
export interface GetSalesCompsActionProps {
  filters: SalesCompsRequest;
  cancelToken: CancelToken;
  // used to let the action creator know if it needs to call hideLoading for loading bar
  cancelInvoked?: boolean;
}

export type SalesCompsEditableField = keyof SalesCompsSliderValues;

enum DefaultSliderValues {
  Sqft = 10,
  Bathrooms = 2,
  Bedrooms = 2,
  SalesPrice = 10,
  YearBuilt = 3,
  SaleDate = 6,
  SearchRadius = 2,
}

const parseOptions: ParseOptions = {
  arrayFormat: "bracket",
  parseNumbers: true,
  parseBooleans: true,
};

export class SalesCompsSliderValues {
  sqft: number = DefaultSliderValues.Sqft;
  bathrooms: number = DefaultSliderValues.Bathrooms;
  bedrooms: number = DefaultSliderValues.Bedrooms;
  salesPrice: number = DefaultSliderValues.SalesPrice;
  yearBuilt: number = DefaultSliderValues.YearBuilt;
  saleDate: number = DefaultSliderValues.SaleDate;
  searchRadius: number = DefaultSliderValues.SearchRadius;

  static Stringify(sliderValues: SalesCompsSliderValues): string {
    const slidersAlias = { ...sliderValues } as any;
    const params = QueryString.stringify(slidersAlias);
    return params;
  }

  static ParseQueryParams(params: string): SalesCompsSliderValues {
    const sliderValues = new SalesCompsSliderValues();
    const values = { ...sliderValues } as any;

    const parsedParamObj = QueryString.parse(params, parseOptions);
    for (const key in parsedParamObj) {
      if (hasKey(values, key)) {
        values[key] = parsedParamObj[key];
      }
    }

    return values;
  }
}

export interface UpdateSalesCompsField {
  filters: SalesCompsRequest;
  name: SalesCompsEditableField;
  value: number;
  prop: PropertyDetail;
}

export class SalesCompsRequest {
  minSqFt: number | null = null;
  maxSqFt: number | null = null;

  minBathrooms: number | null = null;
  maxBathrooms: number | null = null;

  minBedrooms: number | null = null;
  maxBedrooms: number | null = null;

  minSalesPrice: number | null = null;
  maxSalesPrice: number | null = null;

  minYearBuilt: number | null = null;
  maxYearBuilt: number | null = null;

  propertyTypes: PropertyType[] = allPropTypes();
  saleDateMonthsBack: number | null = DefaultSliderValues.SaleDate;
  searchRadiusInMiles: number | null = DefaultSliderValues.SearchRadius;

  centerPointLongitude: number = 0;
  centerPointLatitude: number = 0;
  subjectPropIdToExclude: number | null = null;

  // API MODEL DOESN'T INCLUD THESE ANYMORE...keep for a wee while in case this comes back in
  //minStories: number | null;
  //maxStories: number | null;
  maxResults: number | null = 20;

  static DefaultFromPropDetail(prop: PropertyDetail): SalesCompsRequest {
    const filter = new SalesCompsRequest();
    const location: PropLocation | null = prop.location;
    const characteristics: PropertyCharacteristics | null = prop.characteristics;

    filter.subjectPropIdToExclude = prop.propertyId;

    // LAT/LNG
    if (location) {
      filter.centerPointLatitude = location.latitude;
      filter.centerPointLongitude = location.longitude;
    }

    if (characteristics) {
      // BED
      if (characteristics.bedrooms !== 0) {
        const minBedrooms = characteristics.bedrooms - 1;
        filter.minBedrooms = minBedrooms > 0 ? minBedrooms : null;
        filter.maxBedrooms = characteristics.bedrooms + 1;
      }

      // BATH
      if (characteristics.bathroomsTotal !== 0) {
        const minBathrooms = Math.floor(characteristics.bathroomsTotal - DefaultSliderValues.Bathrooms);
        filter.minBathrooms = minBathrooms > 0 ? minBathrooms : null;
        filter.maxBathrooms = Math.ceil(characteristics.bathroomsTotal + DefaultSliderValues.Bathrooms);
      }

      // SQFT
      const sqft =
        characteristics.propertyType === PropertyType.LotLand ? characteristics.lotSqFt : characteristics.livingSqFt;
      filter.minSqFt = Math.floor(sqft * (1 - DefaultSliderValues.Sqft / 100));
      //filter.minSqFt = Math.floor(sqft * 0.9);
      filter.maxSqFt = Math.ceil(sqft * (1 + DefaultSliderValues.Sqft / 100));
      //filter.maxSqFt = Math.ceil(sqft * 1.1);

      // SALES PRICE
      const { value } = getPropDetailValueInfo(prop);
      filter.minSalesPrice = Math.floor(value * (1 - DefaultSliderValues.SalesPrice / 100));
      filter.maxSalesPrice = Math.ceil(value * (1 + DefaultSliderValues.SalesPrice / 100));

      // YEAR BUILT
      filter.minYearBuilt = characteristics.yearBuilt - DefaultSliderValues.YearBuilt;
      filter.maxYearBuilt = characteristics.yearBuilt + DefaultSliderValues.YearBuilt;

      // TODO - based on propertyType, determine how many propTypeFilters are active
      // New! make default active propertyType match whatever subject property propertyType is
      filter.propertyTypes = propTypeFiltersByPropType(characteristics.propertyType);
    }

    return filter;
  }
  static DefaultFromPropDetailLite(prop: PropertyDetailLite): SalesCompsRequest {
    const filter = new SalesCompsRequest();

    filter.subjectPropIdToExclude = prop.propertyId;
    filter.centerPointLatitude = prop.lat;
    filter.centerPointLongitude = prop.lon;

    // BED
    if (prop.beds !== 0) {
      const minBedrooms = prop.beds - 1;
      filter.minBedrooms = minBedrooms > 0 ? minBedrooms : null;
      const maxBedrooms = prop.beds + 1;
      filter.maxBedrooms = maxBedrooms < 10 ? maxBedrooms : null;
    }

    // BATH
    if (prop.baths !== 0) {
      const minBathrooms = Math.floor(prop.baths - DefaultSliderValues.Bathrooms);
      filter.minBathrooms = minBathrooms > 0 ? minBathrooms : null;
      filter.maxBathrooms = Math.ceil(prop.baths + DefaultSliderValues.Bathrooms);
    }

    // SQFT
    const sqft = prop.propType === PropertyType.LotLand ? prop.lotSqFt : prop.sqFt;
    filter.minSqFt = Math.floor(sqft * (1 - DefaultSliderValues.Sqft / 100));
    filter.maxSqFt = Math.ceil(sqft * (1 + DefaultSliderValues.Sqft / 100));

    // VALUE
    const { value } = getPropValueInfo(prop);
    filter.minSalesPrice = Math.floor(value * (1 - DefaultSliderValues.SalesPrice / 100));
    filter.maxSalesPrice = Math.ceil(value * (1 + DefaultSliderValues.SalesPrice / 100));

    // YEAR BUILT
    filter.minYearBuilt = prop.yearBuilt - DefaultSliderValues.YearBuilt;
    filter.maxYearBuilt = prop.yearBuilt + DefaultSliderValues.YearBuilt;

    // New! make default active propertyType match whatever subject property propertyType is
    filter.propertyTypes = propTypeFiltersByPropType(prop.propType);

    return filter;
  }

  // creates new query params string to attach SalesCompRequest data to the url
  static Stringify(filters: SalesCompsRequest): string {
    const filtersAlias = { ...filters } as any;
    const params = QueryString.stringify(filtersAlias);

    return params;
  }

  // takes in raw query params string from url and populates SalesCompsRequest with data
  static ParseQueryParams(params: string): SalesCompsRequest {
    const salesComps = new SalesCompsRequest();
    const filters = { ...salesComps } as any;
    const parsedParamObj = QueryString.parse(params, parseOptions);

    for (const key in parsedParamObj) {
      if (hasKey(filters, key)) {
        filters[key] = parsedParamObj[key];
      }
    }

    return filters;
  }

  static UpdateField({ filters, prop, name, value }: UpdateSalesCompsField): SalesCompsRequest {
    const updatedFilters = { ...filters };

    const characteristics: PropertyCharacteristics | null = prop.characteristics;
    switch (name) {
      case "searchRadius":
        // if value is zero, and since API errors when given zero, then pass a negligibly small value so API effectively returns no sales comps
        // ps. passing null means API will just ignore this field entirely and return sales comps
        updatedFilters.searchRadiusInMiles = value > 0 ? value : 0.01;
        break;
      case "saleDate":
        updatedFilters.saleDateMonthsBack = value;
        break;
      case "salesPrice":
        if (characteristics) {
          const { value: propValue } = getPropDetailValueInfo(prop);
          updatedFilters.minSalesPrice = Math.floor(propValue * (1 - value / 100));
          updatedFilters.maxSalesPrice = Math.ceil(propValue * (1 + value / 100));
        }
        break;
      case "yearBuilt":
        if (characteristics) {
          updatedFilters.minYearBuilt = characteristics.yearBuilt - value;
          updatedFilters.maxYearBuilt = characteristics.yearBuilt + value;
        }
        break;
      case "bedrooms":
        if (characteristics) {
          const minBedrooms = characteristics.bedrooms - value;
          updatedFilters.minBedrooms = minBedrooms > 0 ? minBedrooms : null;
          updatedFilters.maxBedrooms = characteristics.bedrooms + value;
        }
        break;
      case "bathrooms":
        if (characteristics) {
          const minBathrooms = Math.floor(characteristics.bathroomsTotal - value);
          updatedFilters.minBathrooms = minBathrooms > 0 ? minBathrooms : null;
          updatedFilters.maxBathrooms = Math.ceil(characteristics.bathroomsTotal + value);
        }
        break;
      case "sqft":
        if (characteristics) {
          const sqft =
            characteristics.propertyType === PropertyType.LotLand
              ? characteristics.lotSqFt
              : characteristics.livingSqFt;
          updatedFilters.minSqFt = Math.floor(sqft * (1 - value / 100));
          updatedFilters.maxSqFt = Math.ceil(sqft * (1 + value / 100));
        }
        break;
      default:
        break;
    }

    return updatedFilters;
  }
}
