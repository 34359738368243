import {
  ageDefaultMax,
  bedBathDefaultMax,
  CalculatedMaxDefaults,
  defaultMin,
  estValueDefaultMax,
  loanToValueDefaultMax,
  mtgAmountDefaultMax,
  mtgRateDefaultMax,
  propAgeDefaultMax,
  sqFtDefaultMax,
  storiesDefaultMax,
} from "components/titlefyPro/farms/farmFilters/utilities/defaultValues";
import { allPropTypes, PropertyType } from "../properties/propertyType";
import * as QueryString from "query-string";
import { mapParsedValues } from "store/salesComps/map/salesCompsMapState";

export interface SavedFarmFilters {
  savedFilters: FarmFilter[];
  globalFilters: FarmFilter[];
}

export function minMaxHasValues(filter: FarmFilterMinMax | null, maxDefault: number): boolean {
  if (!filter) return false;
  if (filter.minValue === null && filter.maxValue === null) return false;
  return (filter?.minValue !== null && filter?.minValue > defaultMin) ||
    (filter?.maxValue !== null && filter?.maxValue < maxDefault)
    ? // (filter?.maxValue !== null && filter?.maxValue < maxDefault && filter?.maxValue)
      true
    : false;
}

export function filterHasValues(filter: object | null): boolean {
  if (!filter) return false;
  return Object.values(filter).some((x) => x);
}

// use for when saving a filter or filtering client side because if a filter minMax isn't active the filter should be null
export function sanitizeFarmFilter(filter: FarmFilter, maxDefaults: CalculatedMaxDefaults): FarmFilter {
  const f = { ...filter };

  if (!filterHasValues(f.propertyLabel)) f.propertyLabel = null;
  if (!filterHasValues(f.propertyStatus)) f.propertyStatus = null;
  if (f.occupancyStatus?.ownerOccupied === null) f.occupancyStatus = null;
  if (!minMaxHasValues(f.averageEquity, maxDefaults.equity)) f.averageEquity = null;
  if (!minMaxHasValues(f.estimatedMarketValue, maxDefaults.value)) f.estimatedMarketValue = null;
  if (!minMaxHasValues(f.lengthOfOwnership, ageDefaultMax)) f.lengthOfOwnership = null;
  if (!minMaxHasValues(f.mortgage1Rate, mtgRateDefaultMax)) f.mortgage1Rate = null;
  if (!minMaxHasValues(f.mortgage1Age, ageDefaultMax)) f.mortgage1Age = null;
  if (!minMaxHasValues(f.mortgage1Amount, maxDefaults.value)) f.mortgage1Amount = null;
  if (!minMaxHasValues(f.mortgage2Rate, mtgRateDefaultMax)) f.mortgage2Rate = null;
  if (!minMaxHasValues(f.mortgage2Age, ageDefaultMax)) f.mortgage2Age = null;
  if (!minMaxHasValues(f.mortgage2Amount, maxDefaults.value)) f.mortgage2Amount = null;
  if (!minMaxHasValues(f.mortgagesTotalAmount, maxDefaults.value)) f.mortgagesTotalAmount = null;
  if (!minMaxHasValues(f.loanToValue, loanToValueDefaultMax)) f.loanToValue = null;
  if (!minMaxHasValues(f.bedrooms, bedBathDefaultMax) || (f.bedrooms?.minValue === 0 && f.bedrooms.maxValue === 0))
    f.bedrooms = null;
  if (!minMaxHasValues(f.bathrooms, bedBathDefaultMax) || (f.bathrooms?.minValue === 0 && f.bathrooms.maxValue === 0))
    f.bathrooms = null;
  if (!minMaxHasValues(f.livingSqFt, maxDefaults.sqft)) f.livingSqFt = null;
  if (!minMaxHasValues(f.stories, storiesDefaultMax) || (f.stories?.minValue === 0 && f.stories.maxValue === 0))
    f.stories = null;
  if (!minMaxHasValues(f.propertyAge, maxDefaults.propAge)) f.propertyAge = null;
  if (!f.propertyTypes?.length) f.propertyTypes = null;

  return f;
}

export class FarmFilter {
  farmFilterId: number = 0;
  userId?: number = 0;
  farmId?: number = 0;
  filterName: string = "";
  propertyLabel: FarmFilterPropertyLabel | null = { ...new FarmFilterPropertyLabel() };
  propertyStatus: FarmFilterPropertyStatus | null = { ...new FarmFilterPropertyStatus() };
  occupancyStatus: FarmFilterOccupancyStatus | null = { ...new FarmFilterOccupancyStatus() };
  averageEquity: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: estValueDefaultMax };
  estimatedMarketValue: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: estValueDefaultMax };
  lengthOfOwnership: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: ageDefaultMax };
  mortgage1Rate: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: mtgRateDefaultMax };
  mortgage1Age: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: ageDefaultMax };
  mortgage1Amount: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: mtgAmountDefaultMax };
  mortgage2Rate: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: mtgRateDefaultMax };
  mortgage2Age: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: ageDefaultMax };
  mortgage2Amount: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: mtgAmountDefaultMax };
  mortgagesTotalAmount: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: mtgAmountDefaultMax };
  loanToValue: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: loanToValueDefaultMax };
  bedrooms: FarmFilterMinMax | null = { ...new FarmFilterMinMax() };
  bathrooms: FarmFilterMinMax | null = { ...new FarmFilterMinMax() };
  livingSqFt: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: sqFtDefaultMax };
  stories: FarmFilterMinMax | null = { ...new FarmFilterMinMax() };
  propertyAge: FarmFilterMinMax | null = { ...new FarmFilterMinMax(), maxValue: propAgeDefaultMax };
  propertyTypes: PropertyType[] | null = [...allPropTypes()];
  isGlobal?: boolean | null = false;

  constructor(maxDefaults?: CalculatedMaxDefaults) {
    if (maxDefaults) {
      if (this.averageEquity) this.averageEquity.maxValue = maxDefaults.equity;
      if (this.estimatedMarketValue) this.estimatedMarketValue.maxValue = maxDefaults.value;
      if (this.mortgagesTotalAmount) this.mortgagesTotalAmount.maxValue = maxDefaults.value;
      if (this.mortgage1Amount) this.mortgage1Amount.maxValue = maxDefaults.value;
      if (this.mortgage2Amount) this.mortgage2Amount.maxValue = maxDefaults.value;
      if (this.livingSqFt) this.livingSqFt.maxValue = maxDefaults.sqft;
      if (this.propertyAge) this.propertyAge.maxValue = maxDefaults.propAge;
    }
  }
}
export type FarmFilterKey = keyof FarmFilter;

export function getFiltersFromParams(searchParams: string, maxDefaults: CalculatedMaxDefaults): FarmFilter {
  const filterParams = QueryString.parse(searchParams, {
    arrayFormat: "bracket",
    parseNumbers: true,
    parseBooleans: true,
  });
  return mapParsedValues({ ...new FarmFilter(maxDefaults) }, filterParams);
}

class FarmFilterPropertyLabel {
  undefined?: boolean | null = false;
  lead?: boolean | null = false;
  visitProspect?: boolean | null = false;
  doNotContact?: boolean | null = false;
}
export type FarmFilterPropertyLabelKey = keyof FarmFilterPropertyLabel;

class FarmFilterPropertyStatus {
  hotProperties?: boolean | null = false;
  forSale?: boolean | null = false;
  recentlySold?: boolean | null = false;
  pendingSale?: boolean | null = false;
  offMarket?: boolean | null = false;
}
export type FarmFilterPropertyStatusKey = keyof FarmFilterPropertyStatus;

export class FarmFilterOccupancyStatus {
  ownerOccupied?: boolean | null = null;
}

export class FarmFilterMinMax {
  minValue: number | null = 0;
  maxValue: number | null = 0;
}
