type Env = "development" | "production" | "test" | "beta" | "staging";

export default class AppConfig {
  static Environment(): Env {
    const hostname = window && window.location && window.location.hostname;

    if (hostname.includes("staging.pro.flueid.com") || hostname.includes("flueid-pro-web-staging")) {
      return "staging";
    }

    if (hostname.includes("beta.pro.flueid.com") || hostname.includes("flueid-pro-web-beta")) {
      return "beta";
    }

    if (hostname.includes("test.pro.flueid.com") || hostname.includes("flueid-pro-web-test")) {
      return "test";
    }

    if (hostname.includes("pro.flueid.com") || hostname.includes("flueid-pro-web-prod")) {
      return "production";
    } else {
      return "development";
    }
  }

  //===========================
  //API URL
  //===========================
  static ApiURL(): string {
    let environment = this.Environment();

    switch (environment) {
      case "staging":
        return "https://api-staging.pro.flueid.com";

      case "production":
        return "https://api.pro.flueid.com";

      case "beta":
        return "https://api-beta.pro.flueid.com";

      case "test":
        return "https://api-test.pro.flueid.com";

      default:
        return "https://api-test.pro.flueid.com";
      //  return "https://localhost:44315";
      // return "https://localhost:5001";
    }
  }
}
