import { PropertyDetailLite } from "../../../../dataTypes/properties/propertyDetailLite";
import { getPropStatus, getPropDetailStatus } from "../../../../dataTypes/properties/propertyStatus";
import { PropertyDetail } from "../../../../dataTypes/properties/propertyDetail/propertyDetail";

type PropValueTextType = "LISTING" | "ESTIMATED" | "ASSESSED";
type PropValueTextTypeModifier = "DEFAULT" | "ABBV" | "LONG";
type PropValueTextKey = `${PropValueTextType}-${PropValueTextTypeModifier}`;

// different text is used throughout app alongside prop value, this aides in resolving which to use
const PropValueTextMap: Record<PropValueTextKey, string> = {
  "LISTING-DEFAULT": "Listing Price",
  "LISTING-ABBV": "Listing Price",
  "LISTING-LONG": "a listing price",
  "ESTIMATED-DEFAULT": "Estimated Value",
  "ESTIMATED-ABBV": "Est. Value",
  "ESTIMATED-LONG": "an estimated market value",
  "ASSESSED-DEFAULT": "Assessed Value",
  "ASSESSED-ABBV": "Assessed Value",
  "ASSESSED-LONG": "an assessed market value",
};

type PropValueInfo = { text: string; value: number };

function propValueHierarchy(
  prop: PropertyDetailLite,
  textModifier: PropValueTextTypeModifier = "DEFAULT",
): PropValueInfo {
  // always use estimated value before assessed value
  const key: PropValueTextKey = `${prop.estValue ? "ESTIMATED" : "ASSESSED"}-${textModifier}` as const;
  const value = prop.estValue || prop.assdValue;
  return { text: PropValueTextMap[key], value };
}

function propDetailValueHierarchy(
  prop: PropertyDetail | null,
  textModifier: PropValueTextTypeModifier = "DEFAULT",
): PropValueInfo {
  // always use estimated value before assessed value
  const key: PropValueTextKey = `${
    prop?.valuation?.estimatedValue ? "ESTIMATED" : "ASSESSED"
  }-${textModifier}` as const;
  const value = prop?.valuation?.estimatedValue || prop?.assessment?.assessedValue || 0;
  return { text: PropValueTextMap[key], value };
}

export function getPropValueInfo(
  prop: PropertyDetailLite,
  textModifier: PropValueTextTypeModifier = "DEFAULT",
): PropValueInfo {
  const status = getPropStatus(prop);

  // Always check sold explicitly first, cus of issue where property data is both sold & pending. https://gitlab.com/flueid/titlefy/-/issues/801
  // Giving sold priority cus it would be considered a more recent event than pending or listed
  if (status === "SOLD") {
    return propValueHierarchy(prop, textModifier);
  }

  if ((status === "PENDING" || status === "LISTED") && prop.listingPrice) {
    const key: PropValueTextKey = `LISTING-${textModifier}` as const;
    const value = prop.listingPrice;
    return { text: PropValueTextMap[key], value };
  }

  return propValueHierarchy(prop, textModifier);
}

export function getPropDetailValueInfo(
  prop: PropertyDetail | null,
  textModifier: PropValueTextTypeModifier = "DEFAULT",
): PropValueInfo {
  const status = getPropDetailStatus(prop);

  // Always check sold explicitly first, cus of issue where property data is both sold & pending. https://gitlab.com/flueid/titlefy/-/issues/801
  // Giving sold priority cus it would be considered a more recent event than pending or listed
  if (status === "SOLD") {
    return propDetailValueHierarchy(prop, textModifier);
  }

  if ((status === "PENDING" || status === "LISTED") && prop?.listingInfo?.listingPrice) {
    const key: PropValueTextKey = `LISTING-${textModifier}` as const;
    const value = prop.listingInfo.listingPrice;
    return { text: PropValueTextMap[key], value };
  }

  return propDetailValueHierarchy(prop, textModifier);
}

export function hasPropSaleInfo(prop: PropertyDetailLite): boolean {
  return Boolean(prop.sold && prop.saleAmt && prop.saleDate);
}
