// import all the stores here
import * as PropertiesStore from "./properties/propertiesStore";
import * as FiltersStore from "./filters/filtersStore";
import * as AccountStore from "./account/accountStore";
import * as SettingsStore from "./settings/settingsStore";
import * as CalculatorsStore from "./calculators/calculatorsStore";
import * as DrawingMapStore from "./maps/drawingMap/drawingMapStore";
import * as FarmMapStore from "./maps/farmMap/farmMapStore";
import * as PropertiesMapStore from "./maps/propertiesMap/propertiesMapStore";
import * as FarmsStore from "./farms/farmsStore";
import * as FarmFiltersStore from "./filters/farmFilters/farmFiltersStore";
import * as PropertiesUIStore from "./ui/propertiesUI/propertiesUIStore";
import * as FarmsUIStore from "./ui/farmsUI/farmsUIStore";
import * as OrdersStore from "./orders/ordersStore";
import * as OrdersUIStore from "./ui/ordersUI/ordersUIStore";
import * as NewOrderStore from "./orders/newOrder/newOrderStore";
import * as OrderSubmissionStore from "./orders/orderSubmission/orderSubmissionStore";
import * as LoadingPageStore from "./controls/loadingPage/loadingPageStore";
import * as NewOrderUIStore from "./ui/newOrderUI/newOrderUIStore";
import * as AdminUIStore from "./ui/adminUI/adminUIStore";
import * as CompaniesStore from "./companies/companiesStore";
import { loadingBarReducer } from "react-redux-loading-bar";
import * as SalesCompsStore from "./salesComps/salesCompsStore";

// The top-level state object
export interface ApplicationState {
  properties: PropertiesStore.PropertiesState;
  filters: FiltersStore.FiltersState;
  farmFilters: FarmFiltersStore.FarmFiltersState;
  account: AccountStore.AccountState;
  settings: SettingsStore.SettingsState;
  calculators: CalculatorsStore.CalculatorsState;
  drawingMap: DrawingMapStore.DrawingMapState;
  farmMap: FarmMapStore.FarmMapState;
  farms: FarmsStore.FarmsState;
  propertiesMap: PropertiesMapStore.PropertiesMapState;
  propertiesUI: PropertiesUIStore.PropertiesUIState;
  farmsUI: FarmsUIStore.FarmsUIState;
  orders: OrdersStore.OrdersState;
  ordersUI: OrdersUIStore.OrdersUIState;
  newOrder: NewOrderStore.NewOrderState;
  newOrderUI: NewOrderUIStore.NewOrderUIState;
  orderSubmission: OrderSubmissionStore.OrderSubmissionState;
  adminUI: AdminUIStore.AdminUIState;
  companies: CompaniesStore.CompaniesState;
  // haven't been able to find documentation on the shape of the react-redux-loading-bar state object
  loadingBar: any;
  loadingPage: LoadingPageStore.LoadingState;
  salesComps: SalesCompsStore.SalesCompsState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  properties: PropertiesStore.PropertiesReducer,
  filters: FiltersStore.FiltersReducer,
  farmFilters: FarmFiltersStore.FarmFiltersReducer,
  account: AccountStore.AccountReducer,
  settings: SettingsStore.SettingsReducer,
  calculators: CalculatorsStore.CalculatorsReducer,
  drawingMap: DrawingMapStore.DrawingMapReducer,
  farmMap: FarmMapStore.FarmMapReducer,
  farms: FarmsStore.FarmsReducer,
  propertiesMap: PropertiesMapStore.PropertiesMapReducer,
  propertiesUI: PropertiesUIStore.PropertiesUIReducer,
  farmsUI: FarmsUIStore.FarmsUIReducer,
  orders: OrdersStore.OrdersReducer,
  ordersUI: OrdersUIStore.OrdersUIReducer,
  newOrder: NewOrderStore.NewOrderReducer,
  newOrderUI: NewOrderUIStore.NewOrderUIReducer,
  orderSubmission: OrderSubmissionStore.OrderSubmissionRootReducer,
  adminUI: AdminUIStore.AdminUIReducer,
  companies: CompaniesStore.CompaniesRootReducer,
  loadingPage: LoadingPageStore.LoadingReducer,
  loadingBar: loadingBarReducer,
  salesComps: SalesCompsStore.SalesCompsRootReducer,
};

interface ClearReduxStore {
  type: "CLEAR_REDUX_STORE";
}

// All app wide action types
type AppActions = ClearReduxStore;

// app wide action creators
export const appActionCreators = {
  clearReduxStore: (): AppThunkAction<AppActions> => (dispatch, getState) => {
    dispatch({ type: "CLEAR_REDUX_STORE" });
  },
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
