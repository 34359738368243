import * as React from "react";
import { withRouter, RouteComponentProps, Switch, Route } from "react-router-dom";

// section components
import NavBar from "./navbar";
import HeroSection from "./sections/heroSection";
import MissionSection from "./sections/missionSection";
import FeatureSection from "./sections/featureSection";
import TxMSection from "./sections/txMSection";
import AdvantageSection from "./sections/advantageSection";
import ContactSection from "./sections/contactSection";
import Footer from "./footer";
import {
  allSchemaFieldsValidGeneric,
  newValidatedField,
  setValidatedFieldTrue,
  validateSchemaGeneric,
  ValidationSchemaGeneric,
} from "../../dataTypes/controls/interfaces/validationSchema";
import { ValidationType } from "../../dataTypes/controls/enums/validationType";
import Privacy from "../privacyTerms/privacyPage";
import TermsConditions from "../privacyTerms/termsConditionsPage";
import ApiService, {
  ApiEndpoints,
  getAxiosErrorMessage,
  newPendingReq,
  newReqStatus,
  reqFailed,
  reqOk,
  ReqStatus,
} from "../../utilities/apiService";
import { AxiosError, AxiosResponse } from "axios";
import ErrorHandler from "../controls/errorHandler";
import AuthService from "utilities/authService";
import Alert from "components/alert";
import TxMFullPageSection from "./sections/txMFullPage";
import "../../css/partials/homePage/sections/_txmSection.scss";
import HousingWireBanner from "./housingWire";

export class ContactUsRequest {
  name: string = "";
  email: string = "";
  topic: string = "";
  message: string = "";
}
export type ContactUsKey = keyof ContactUsRequest;

interface State {
  authChecked: boolean;
  contactUsRequest: ContactUsRequest;
  contactUsReqStatus: ReqStatus;
  validation: ValidationSchemaGeneric<ContactUsRequest>;
  showSubmission: boolean;
  submissionError: boolean;
}

class HomePageContainer extends React.Component<RouteComponentProps, State> {
  state: State = {
    authChecked: false,
    contactUsRequest: new ContactUsRequest(),
    contactUsReqStatus: newReqStatus(),
    validation: {
      name: newValidatedField(true, [ValidationType.Value]),
      email: newValidatedField(true, [ValidationType.Email]),
      topic: newValidatedField(true, [ValidationType.Value]),
      message: newValidatedField(true, [ValidationType.Value]),
    },
    showSubmission: false,
    submissionError: false,
  };

  componentDidMount() {
    // user already signed in?
    AuthService.GetCurrentAuthenticatedUser()
      .then(() => {
        // going to root path, push to internal app
        if (this.props.location.pathname === "/") {
          this.props.history.push("/app/properties");
        }
        this.setState({ authChecked: true });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ authChecked: true });
      });

    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    const { location } = this.props;
    // if route changes and user goes to privacy/terms page, reset the form
    if (
      prevProps.location.pathname !== location.pathname &&
      (location.pathname === "/privacy" || location.pathname === "/terms")
    ) {
      this.setState({ contactUsRequest: new ContactUsRequest() });
    }
  }

  handleInputChange = (name: ContactUsKey, value: string) => {
    const { contactUsRequest, validation } = this.state;
    this.setState({
      ...this.state,
      contactUsRequest: {
        ...contactUsRequest,
        [name]: value,
      },
      validation: setValidatedFieldTrue<ContactUsRequest>(validation, name),
    });
  };

  handleInputClear = (name: ContactUsKey) => {
    const { validation, contactUsRequest } = this.state;
    this.setState({
      ...this.state,
      contactUsRequest: {
        ...contactUsRequest,
        [name]: "",
      },
      validation: setValidatedFieldTrue<ContactUsRequest>(validation, name),
    });
  };

  submitContactForm = () => {
    const { validation, contactUsRequest, showSubmission, submissionError, contactUsReqStatus } = this.state;

    // validate first
    const newValidation = validateSchemaGeneric(contactUsRequest, validation);
    const allFieldsValid = allSchemaFieldsValidGeneric(newValidation);
    // disable submission if showing success/error/req pending
    if (allFieldsValid && !contactUsReqStatus.pending && !showSubmission && !submissionError) {
      // submit request
      this.setState({ contactUsReqStatus: newPendingReq() });
      ApiService.CallApi(
        "POST",
        ApiEndpoints.ContactUs,
        contactUsRequest,
        () => {},
        () => {},
        { allowAnonymous: true },
      )
        .then((resp: AxiosResponse) => {
          this.setState({ showSubmission: true, contactUsReqStatus: reqOk() });
          setTimeout(() => {
            this.setState({
              contactUsRequest: new ContactUsRequest(),
              showSubmission: false,
            });
          }, 5000);
        })
        .catch((error: AxiosError) => {
          const errorMessage = getAxiosErrorMessage(error);
          ErrorHandler.HandleError(error, errorMessage);
          this.setState({ submissionError: true, contactUsReqStatus: reqFailed() });
          setTimeout(() => {
            this.setState({ submissionError: false });
          }, 5000);
        });
    } else {
      this.setState({ validation: newValidation });
    }
  };

  closeSuccessMsg = () => this.setState({ showSubmission: false, contactUsRequest: new ContactUsRequest() });
  closeErrorMsg = () => this.setState({ submissionError: false });

  renderHomePage = () => {
    const { authChecked, contactUsRequest, validation, showSubmission, submissionError } = this.state;
    return (
      authChecked && (
        <div className="homepage-container" id="homepage-container">
          {/* <HousingWireBanner /> Commenting out but keeping for potential future use https://gitlab.com/flueid/flueid-pro/fp-ui/-/issues/30#note_884324131 */}
          <NavBar setTopic={this.handleInputChange} key={"navbar-homepage"} useSubpageLinks={false} />
          <div className="mobile-overflow-fix">
            <HeroSection />
            <MissionSection setTopic={this.handleInputChange} />
            <FeatureSection setTopic={this.handleInputChange} />
            <TxMSection />
            <AdvantageSection />
            <ContactSection
              contactUsRequest={contactUsRequest}
              handleInputChange={this.handleInputChange}
              handleInputClear={this.handleInputClear}
              submitContactForm={this.submitContactForm}
              validation={validation}
              showSubmission={showSubmission}
              submissionError={submissionError}
              closeSuccessMsg={this.closeSuccessMsg}
              closeErrorMsg={this.closeErrorMsg}
            />
            <Footer />
          </div>
        </div>
      )
    );
  };

  renderTxmPage = () => {
    const { authChecked } = this.state;
    return (
      authChecked && (
        <div className="homepage-container" id="homepage-container">
          <NavBar
            setTopic={this.handleInputChange}
            useFloatingLogo={false}
            key={"navbar-txmpage"}
            useSubpageLinks={true}
          />
          <div className="mobile-overflow-fix">
            <TxMFullPageSection />
            <Footer />
          </div>
        </div>
      )
    );
  };

  render() {
    return (
      <>
        <Alert scope="Public" />
        <Switch>
          <Route path="/privacy" render={() => <Privacy setTopic={this.handleInputChange} />} />
          <Route path="/terms" render={() => <TermsConditions setTopic={this.handleInputChange} />} />
          <Route path="/txm-about" render={() => this.renderTxmPage()} />
          <Route path="/" render={() => this.renderHomePage()} />
        </Switch>
      </>
    );
  }
}

export default withRouter(HomePageContainer);
