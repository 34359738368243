import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { UserSettings } from "../userSettings";
import { PermissionTypes } from "./permissionTypes";
import { DefaultFallbackUI } from "../../../components/controls/fallbackPage";

export function hasPermission(
  userSettings: UserSettings | null,
  permTypes: PermissionTypes | PermissionTypes[],
): boolean {
  const perms = Array.isArray(permTypes) ? permTypes : [permTypes];
  return perms.every((permType) => !!userSettings?.permissions.find((perm) => perm.name === permType && perm.value));
}

export function hasSomePermissions(
  userSettings: UserSettings | null,
  permTypes: PermissionTypes | PermissionTypes[],
): boolean {
  const perms = Array.isArray(permTypes) ? permTypes : [permTypes];
  return perms.some((permType) => !!userSettings?.permissions.find((perm) => perm.name === permType && perm.value));
}

// sometimes extra helpers like this are needed that check affirmative/negatively named permissions
export function canUploadFarms(userSettings: UserSettings | null): boolean {
  return (
    !hasPermission(userSettings, PermissionTypes.GeneralAppSettings.HideFarms) &&
    hasPermission(userSettings, PermissionTypes.GeneralAppSettings.UploadFarms)
  );
}

//=====================
export interface HasPermissionProps {
  permTypes?: PermissionTypes | PermissionTypes[];
  // behavior if permission is found. For ex. any negatively named permission like "HideFarms", should have behavior: "HIDE"
  // Any affirmatively named permission like "ViewFeeCalculators" should have behavior: "SHOW"
  // Note that behavior defaults to "SHOW" since the majority of permissions are affirmatively named
  behavior?: "SHOW" | "HIDE";
  showFallbackUI?: boolean;
  fallbackUI?: React.ReactNode;
  hasSome?: boolean;
  customCheck?: boolean; // don't want to use permTypes? use customCheck instead
}

// general wrapper to go around some JSX that needs to have permissions checked/granted to view
export const HasPermission: React.FC<HasPermissionProps> = ({
  permTypes = [],
  behavior = "SHOW",
  children,
  showFallbackUI,
  fallbackUI,
  hasSome,
  customCheck,
}) => {
  const userSettings = useSelector((state: ApplicationState) => state.account.userSettings);

  const permFound: boolean =
    customCheck !== undefined // customCheck takes precedent over permTypes
      ? customCheck
      : hasSome
      ? hasSomePermissions(userSettings, permTypes)
      : hasPermission(userSettings, permTypes);

  const hideBehavior = showFallbackUI ? (
    fallbackUI ? (
      <>{fallbackUI}</>
    ) : (
      <DefaultFallbackUI
        navTitle="Permissions Error"
        header="Sorry, you don't have access to view this page."
        text="If you believe this to be incorrect, please contact customer service."
      />
    )
  ) : null;

  if (behavior === "SHOW") {
    return permFound ? <>{children}</> : hideBehavior;
  } else if (behavior === "HIDE") {
    return permFound ? hideBehavior : <>{children}</>;
  } else {
    return <>{children}</>;
  }
};
