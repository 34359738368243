import * as React from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { LoadingBar } from "../../../controls/loadingBar";

interface Props extends RouteComponentProps {
  title: string;
  onClick?: () => void;
  to?: string;
  rightIcon?: React.ReactNode;
}

class PropDetailNavbar extends React.Component<Props> {
  render() {
    const { title, to, history, rightIcon, onClick } = this.props;

    return (
      <div className="prop-detail-navbar">
        <div className="prop-detail-navbar-padded-width">
          {
            // if a "to" path is supplied, use the link, otherwise default to history.goBack
            to ? (
              <Link to={to}>
                <div className="back-icon" />
              </Link>
            ) : (
              <div style={{ position: "relative" }}>
                <div className="back-icon" onClick={onClick || history.goBack} />
              </div>
            )
          }
          <div className="property-name bold">{title}</div>
          {rightIcon && <div className="right-icon">{rightIcon}</div>}
        </div>
        <LoadingBar containerStyle={{ position: "absolute", bottom: "0px" }} />
      </div>
    );
  }
}

export default withRouter(PropDetailNavbar);
