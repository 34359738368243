import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Validate from "../../../utilities/formFieldValidator";
import AuthService from "../../../utilities/authService";
import AuthNavbar, { AuthNavCloseBtn, AuthNavHeader } from "../authNavbar";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store/index";
import { Partner } from "../../../dataTypes/partners";
import BasicInput from "../../controls/inputs/basicInput";
import { loadingBarActionCreators } from "../../../store/controls/loadingBar/loadingBarActionCreators";
import { LoadingBar } from "../../controls/loadingBar";
import { getAuthPath } from "../types/authPath";
import { InputField } from "../types/inputField";
import AuthFormButton from "./button";

interface State {
  currentFieldIndex: number;
  fieldInvalid: boolean;
  fieldInvalidMessages: string[];
  inputFields: InputField[];
  showSuccessMessage: boolean;
  submitButtonDisabled: boolean;
}

interface ReduxDispatchProps {
  showLoadingBar: (scope?: string) => void;
  hideLoadingBar: (scope?: string) => void;
}

interface OwnProps {
  partner?: Partner;
  signInEmail: string;
  setSignInEmail: (email: string) => void;
}

type Props = OwnProps & RouteComponentProps & ReduxDispatchProps;

class ResetPassword extends React.Component<Props, State> {
  state: State = {
    currentFieldIndex: 0,
    fieldInvalid: false,
    fieldInvalidMessages: [],
    inputFields: [
      { name: "email", label: "Email Address", value: "", instructionText: "Enter the email you used to Sign Up." },
      {
        name: "confirmationCode",
        label: "Confirmation Code",
        value: "",
        instructionText: "Check your email for the confirmation code.",
      },
      { name: "newPassword", label: "Password", value: "", instructionText: "Enter your new password." },
      {
        name: "confirmPassword",
        label: "Password",
        value: "",
        instructionText: "Please enter your password again to confirm it.",
      },
    ],
    showSuccessMessage: false,
    submitButtonDisabled: false,
  };

  private inputRef = React.createRef<HTMLInputElement>();

  //-------------------
  // LIFECYCLE METHODS
  componentDidMount() {
    const { signInEmail } = this.props;

    const input = this.inputRef.current;

    if (input) {
      input.focus();
    }

    // if there's an email in the ReduxState...prepopulate the email input with this value
    if (signInEmail) {
      const { inputFields } = this.state;
      //make a copy of the fields
      const updatedFields = [...inputFields];
      //update the current fields value
      updatedFields[0].value = signInEmail;

      this.setState({ inputFields: updatedFields });
    }
  }

  componentDidUpdate() {
    const input = this.inputRef.current;

    if (input) {
      input.focus();
    }
  }

  componentWillUnmount() {
    this.props.setSignInEmail("");
  }
  //--------------------

  //--------------------
  // FORM HANDLING METHODS
  handleInput = (value: string) => {
    const { inputFields, currentFieldIndex } = this.state;
    //make a copy of the fields
    const updatedFields = [...inputFields];
    //update the current fields value
    if (updatedFields[currentFieldIndex].name === "email") {
      updatedFields[currentFieldIndex].value = value.toLowerCase().trim();
    } else {
      updatedFields[currentFieldIndex].value = value.trim(); // trim is helpful in case user copy/pasted confirmation code with unwanted white space
    }

    this.setState({
      inputFields: updatedFields,
      fieldInvalid: false,
      fieldInvalidMessages: [],
      submitButtonDisabled: false,
    });
  };

  handleClear = (name: string) => {
    const { inputFields, currentFieldIndex } = this.state;
    const updatedFields = [...inputFields];
    updatedFields[currentFieldIndex].value = "";
    this.setState({
      inputFields: updatedFields,
      fieldInvalid: false,
      fieldInvalidMessages: [],
      submitButtonDisabled: false,
    });
  };

  progressNextFormField = (e: any) => {
    e.preventDefault();

    const { inputFields, currentFieldIndex, fieldInvalidMessages } = this.state;

    // if there's no input in the field, let user know to enter value
    if (!inputFields[currentFieldIndex].value) {
      this.setState({
        fieldInvalid: true,
        fieldInvalidMessages: [
          ...fieldInvalidMessages,
          `Please enter a ${inputFields[currentFieldIndex].label.toLowerCase()}`,
        ],
      });
      return;
    } else {
      if (inputFields[currentFieldIndex].value) {
        // validate the new password with AWS password policy rules
        if (inputFields[currentFieldIndex].name === "newPassword") {
          const errorMessages = Validate.Password(inputFields[currentFieldIndex].value);

          if (errorMessages.length > 0) {
            this.setState({
              fieldInvalid: true,
              fieldInvalidMessages: errorMessages,
            });
          } else {
            this.setState({
              currentFieldIndex: currentFieldIndex + 1,
              submitButtonDisabled: false,
            });
          }
          // validate the confirmation code to be 8 digit numeric set
        } else if (
          inputFields[currentFieldIndex].name === "confirmationCode" &&
          !Validate.ConfirmationCode(inputFields[currentFieldIndex].value)
        ) {
          this.setState({
            fieldInvalid: true,
            fieldInvalidMessages: [...fieldInvalidMessages, "Please enter a valid confirmation code"],
          });
        } else {
          this.setState({
            currentFieldIndex: currentFieldIndex + 1,
            submitButtonDisabled: false,
          });
        }
      }
    }
  };

  previousFormField = () => {
    this.setState({
      currentFieldIndex: this.state.currentFieldIndex - 1,
      fieldInvalid: false,
      fieldInvalidMessages: [],
      submitButtonDisabled: false,
    });
  };

  requestConfirmationCode = (e: any) => {
    e.persist();
    e.preventDefault();

    const { inputFields, currentFieldIndex, fieldInvalidMessages } = this.state;
    const { showLoadingBar, hideLoadingBar } = this.props;

    // check to see if email is valid before submitting for confirmation code
    if (!Validate.Email(inputFields[currentFieldIndex].value)) {
      this.setState({
        fieldInvalid: true,
        fieldInvalidMessages: [...fieldInvalidMessages, "Please enter a correct email address"],
      });
      return;
    } else {
      // disable submit button to prevent extra requests
      this.setState({ submitButtonDisabled: true });
      showLoadingBar();
      AuthService.ForgotPassword(inputFields[0].value)
        .then(() => {
          // setTimeout to give loading bar more time
          setTimeout(() => {
            hideLoadingBar();
            this.progressNextFormField(e);
          }, 500);
        })
        .catch(() => {
          hideLoadingBar();
          this.setState({
            fieldInvalid: true,
            fieldInvalidMessages: [...fieldInvalidMessages, "User not found"],
            submitButtonDisabled: true,
          });
        });
    }
  };

  submitNewPassword = (e: any) => {
    e.preventDefault();

    const { inputFields, fieldInvalidMessages } = this.state;
    const { showLoadingBar, hideLoadingBar } = this.props;

    // check to see if password/confirmPassword are equal and then submit email/confirmationCode/password
    if (inputFields[2].value === inputFields[3].value) {
      // disable submit button to prevent extra requests
      this.setState({ submitButtonDisabled: true });

      showLoadingBar();
      AuthService.ForgotPasswordSubmit(inputFields[0].value, inputFields[1].value, inputFields[2].value)
        .then(() => {
          setTimeout(() => {
            hideLoadingBar();
            this.setState({ showSuccessMessage: true });
          }, 500);
        })
        .catch((error) => {
          hideLoadingBar();
          this.setState({ fieldInvalid: true, submitButtonDisabled: true });

          // if they entered an invalid confirmation code
          if (error.name === "CodeMismatchException") {
            this.setState({
              currentFieldIndex: 1,
              fieldInvalid: true,
              fieldInvalidMessages: [...fieldInvalidMessages, "Invalid confirmation code provided, please try again"],
              submitButtonDisabled: true,
            });
          }
        });
    } else {
      this.setState({
        fieldInvalid: true,
        fieldInvalidMessages: [...fieldInvalidMessages, "Please ensure the passwords match"],
        submitButtonDisabled: true,
      });
    }
  };
  //--------------------

  //--------------------
  // RENDER UI ELEMENTS METHODS
  renderFormField = (currentFieldIndex: number) => {
    const { inputFields, fieldInvalid, fieldInvalidMessages, submitButtonDisabled } = this.state;
    const field: InputField = inputFields[currentFieldIndex];

    return (
      <form
        className="auth-form"
        onSubmit={
          currentFieldIndex === 0
            ? this.requestConfirmationCode
            : currentFieldIndex === inputFields.length - 1
            ? this.submitNewPassword
            : this.progressNextFormField
        }>
        <div key={`input-${field.name}`} className="form-group">
          <BasicInput
            label={field.label}
            type={field.name === "newPassword" || field.name === "confirmPassword" ? "password" : "text"}
            name={field.name}
            initialValue={field.value}
            placeHolder={field.label}
            onChange={(name: string, value: string) => this.handleInput(value)}
            onClearClick={(name: string) => this.handleClear(name)}
            inputVersion="v2"
            focusOnMount
            valid={!fieldInvalid}
            errorMessages={fieldInvalidMessages}
            autoComplete
          />
        </div>
        <AuthFormButton
          type="submit"
          className={!inputFields[currentFieldIndex].value || fieldInvalid ? "btn-disabled" : ""}
          disabled={fieldInvalid || submitButtonDisabled ? true : false}>
          {currentFieldIndex === 0 || currentFieldIndex === inputFields.length - 1 ? "Send" : "Next"}
        </AuthFormButton>
      </form>
    );
  };

  renderSuccessMessage = () => {
    return (
      <div className="content shorter-content">
        <h3 className="header">Success! Your password has been updated.</h3>
        <Link to={getAuthPath("/auth/sign-in", this.props.partner)}>
          <AuthFormButton className="m-t_none">Sign In</AuthFormButton>
        </Link>
      </div>
    );
  };

  renderHeader = () => {
    const { currentFieldIndex } = this.state;

    let headerText = "";

    if (currentFieldIndex === 0) {
      headerText = "Forgot Password";
    } else if (currentFieldIndex === 1) {
      headerText = "Enter Verification Code";
    } else headerText = "Update Password";

    return (
      <h3 className={`header header-3 boldest`} style={{ marginBottom: "10px" }}>
        {headerText}
      </h3>
    );
  };

  //---------------------

  render() {
    const { currentFieldIndex, inputFields, showSuccessMessage } = this.state;

    return (
      <div className="fade-in">
        <AuthNavbar>
          {currentFieldIndex === 0 && (
            <Link to={getAuthPath("/auth/sign-in", this.props.partner)}>
              <div className="back-btn" />
            </Link>
          )}
          {currentFieldIndex > 0 && <div className="back-btn" onClick={this.previousFormField} />}
          <AuthNavHeader>Reset Password</AuthNavHeader>
          <AuthNavCloseBtn websiteUrl={this.props.partner?.websiteUrl} />
        </AuthNavbar>
        <LoadingBar />
        <div className="auth-form-container">
          {!showSuccessMessage ? (
            <div className="content shorter-content">
              {this.renderHeader()}
              <div className="paragraph-small">{inputFields[currentFieldIndex].instructionText}</div>
              {this.renderFormField(currentFieldIndex)}
            </div>
          ) : (
            this.renderSuccessMessage()
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: ReduxDispatchProps = {
  showLoadingBar: loadingBarActionCreators.showLoadingBar,
  hideLoadingBar: loadingBarActionCreators.hideLoadingBar,
};

export default connect<{}, ReduxDispatchProps, {}, ApplicationState>(null, mapDispatchToProps)(ResetPassword);
