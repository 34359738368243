import * as React from "react";
import { Dropdown, Visibility, Transition, DropdownProps } from "semantic-ui-react";
import { ContactUsRequest, ContactUsKey } from "../homePageContainer";
import { DropdownOption } from "../../../dataTypes/common";
import { ValidationSchemaGeneric } from "../../../dataTypes/controls/interfaces/validationSchema";
import HomeInput from "../forms/homeInput";

export enum ContactTopic {
  Undefined = "",
  Demo = "Schedule a demo",
  Meeting = "Request a meeting",
  General = "General Question",
  Join = "I’m interested in joining Flueid",
  Feedback = "Feedback",
}

const contactOptions: DropdownOption[] = [
  { value: ContactTopic.General, text: ContactTopic.General },
  { value: ContactTopic.Meeting, text: ContactTopic.Meeting },
  { value: ContactTopic.Demo, text: ContactTopic.Demo },
  { value: ContactTopic.Join, text: ContactTopic.Join },
  { value: ContactTopic.Feedback, text: ContactTopic.Feedback },
];

interface State {
  emailValidError: boolean;
  elementVisible: boolean;
}

interface Props {
  contactUsRequest: ContactUsRequest;
  handleInputChange: (name: ContactUsKey, value: string) => void;
  handleInputClear: (name: ContactUsKey) => void;
  submitContactForm: () => void;
  validation: ValidationSchemaGeneric<ContactUsRequest>;
  showSubmission: boolean;
  submissionError: boolean;
  closeSuccessMsg: () => void;
  closeErrorMsg: () => void;
}

class TalkToUsSection extends React.Component<Props, State> {
  private defaultState: State = {
    emailValidError: true,
    elementVisible: false,
  };

  state = { ...this.defaultState };

  handleVisibilityUpdate = (e: any, data: any) => {
    const { elementVisible } = this.state;
    const { topVisible, bottomVisible } = data.calculations;

    if (!elementVisible && (topVisible || bottomVisible)) {
      this.setState({
        elementVisible: true,
      });
    }
  };

  render() {
    const { elementVisible } = this.state;

    const {
      contactUsRequest,
      handleInputChange,
      submitContactForm,
      validation,
      showSubmission,
      submissionError,
      closeErrorMsg,
      closeSuccessMsg,
    } = this.props;

    return (
      <div className="flueidProMotive-container">
        <div className="flueidProTalktDes-main">
          <div className="flueidProTalktDes-sec">
            <div className="header-1">Talk to Us</div>
            <div className="subheader-1 txt-light">
              We’re here to help. Call us, email us or fill out the contact form. We look forward to talking to you.
            </div>
            <div className="flueidProTalktDes-officeSec">
              <div className="flueidProTalktDes-officeItem">
                <div className="flueidProTalktDes-seperator"></div>
                <div className="p-2 txt-semiBold">AUSTIN, TX OFFICE</div>
                <div className="p-2 txt-regular">7500 Rialto Blvd, Building 2, Suite 210</div>
                <div className="p-2 txt-regular address">Austin, Texas 78735</div>
                <div className="flueidProTalktDes-locationDir">
                  <a
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                    href="https://www.google.com/maps/place/7500+Rialto+Blvd+%23260,+Austin,+TX+78735/@30.2538202,-97.8743147,17z/data=!3m1!4b1!4m5!3m4!1s0x865b4a29d552f86f:0x34cefd6cda8e9bf0!8m2!3d30.2538202!4d-97.872126"
                    className="p-2 txt-regular ">
                    Map
                  </a>
                  <a
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                    href="https://www.google.com/maps/dir//7500+Rialto+Blvd+%23260,+Austin,+TX+78735/@31.7304032,-98.4605258,8z/data=!4m8!4m7!1m0!1m5!1m1!1s0x865b4a29d552f86f:0x34cefd6cda8e9bf0!2m2!1d-97.872126!2d30.2538202"
                    className="p-2 txt-regular ">
                    Driving Directions
                  </a>
                </div>
              </div>
              <div className="flueidProTalktDes-officeItem">
                <div className="flueidProTalktDes-seperator"></div>
                <div className="p-2 txt-semiBold">SANTA BARBARA, CA OFFICE</div>
                <div className="p-2 txt-regular">800 Miramonte Drive, Suite 320</div>
                <div className="p-2 txt-regular address">Santa Barbara, CA 93109</div>
                <div className="flueidProTalktDes-locationDir">
                  <a
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                    href="https://www.google.com/maps/place/800+Miramonte+Dr+%23320,+Santa+Barbara,+CA+93109/@34.4105404,-119.7109922,17z/data=!3m1!4b1!4m5!3m4!1s0x80e9146e33b1a6e7:0x8efd28ee0b3ffa18!8m2!3d34.4105404!4d-119.7088035"
                    className="p-2 txt-regular ">
                    Map
                  </a>
                  <a
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                    href="https://www.google.com/maps/dir//800+Miramonte+Dr+%23320,+Santa+Barbara,+CA+93109/@34.4105404,-119.7109922,17z/data=!4m17!1m7!3m6!1s0x80e9146e33b1a6e7:0x8efd28ee0b3ffa18!2s800+Miramonte+Dr+%23320,+Santa+Barbara,+CA+93109!3b1!8m2!3d34.4105404!4d-119.7088035!4m8!1m0!1m5!1m1!1s0x80e9146e33b1a6e7:0x8efd28ee0b3ffa18!2m2!1d-119.7088035!2d34.4105404!3e2"
                    className="p-2 txt-regular ">
                    Driving Directions
                  </a>
                </div>
              </div>
              <div className="flueidProTalktDes-officeItem">
                <div className="flueidProTalktDes-seperator"></div>
                <div className="p-2 txt-semiBold">BUSINESS HOURS</div>
                <div className="p-2 txt-regular">8:30 am - 5:30 pm</div>
                <div className="p-2 txt-regular address">Mon - Fri</div>
              </div>

              <div className="flueidProTalktDes-officeItem">
                <div className="flueidProTalktDes-seperator"></div>
                <div className="p-2 txt-semiBold">CUSTOMER SERVICE</div>
                <a href="tel:888-834-8434" className="p-2 txt-regular">
                  (888) 834-8434
                </a>
                <a href="mailto:support@flueid.com" className="p-2 txt-regular address">
                  support@flueid.com
                </a>
              </div>
              <div className="flueidProTalktDes-officeItem">
                <div className="flueidProTalktDes-seperator"></div>
                <div className="p-2 txt-semiBold">SALES</div>
                <a href="tel:888-834-8434" className="p-2 txt-regular">
                  (888) 834-8434
                </a>
                <a href="mailto:sales@flueid.com" className="p-2 txt-regular address">
                  sales@flueid.com
                </a>
              </div>
              <div className="flueidProTalktDes-officeItem">
                <div className="flueidProTalktDes-seperator"></div>
                <div className="p-2 txt-semiBold">MEDIA</div>
                <a href="tel:737-703-3578" className="p-2 txt-regular">
                  (737) 703-3578
                </a>
                <a href="mailto:media@flueid.com" className="p-2 txt-regular address">
                  media@flueid.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flueidProContactForm-main">
          <div className="flueidProContactForm-contactSec">
            <div className="flueidProContactFormContainer">
              <div className="flueidProContactForm-container">
                <div className="flueidProContactForm-content-container">
                  <div className="flueidProContactForm-text-background-container">
                    <div className="flueidProContactForm-text-content" id="contact">
                      <div className="flueidProContactForm-lineSeperator"></div>
                      {!showSubmission && !submissionError && (
                        <>
                          <div className="p-2 txt-semiBold">FILL OUT THE CONTACT FORM</div>
                          <div className="flueidProContactForm-inputSec">
                            <HomeInput
                              label="Name"
                              type="text"
                              name="name"
                              initialValue={contactUsRequest.name}
                              onChange={handleInputChange}
                            />
                            {!validation.name?.valid ? (
                              <div style={{ color: "red", fontSize: "11px" }}>Please enter name</div>
                            ) : null}
                          </div>
                          <div className="flueidProContactForm-inputSec">
                            <HomeInput
                              label="Email Address"
                              type="text"
                              name="email"
                              initialValue={contactUsRequest.email}
                              onChange={handleInputChange}
                            />
                            {!validation.email?.valid ? (
                              <div style={{ color: "red", fontSize: "11px" }}>Please enter valid email</div>
                            ) : null}
                          </div>
                          <div className="flueidProContactForm-inputSec">
                            <Dropdown
                              className="contactSection-input"
                              onChange={(e: any, data: DropdownProps) => {
                                if (typeof data.value === "string") handleInputChange("topic", data.value);
                              }}
                              options={contactOptions}
                              placeholder="How can we help?"
                              selectOnBlur={false}
                              selection
                              value={contactUsRequest.topic}
                            />
                          </div>
                          <div className="flueidProContactForm-inputSec msg">
                            <HomeInput
                              label="Message"
                              type="text"
                              name="message"
                              initialValue={contactUsRequest.message}
                              onChange={handleInputChange}
                            />
                            {!validation.message?.valid ? (
                              <div style={{ color: "red", fontSize: "11px" }}>Please enter valid email</div>
                            ) : null}
                          </div>
                        </>
                      )}
                      {showSubmission && !submissionError && (
                        <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate}>
                          <Transition visible={elementVisible} animation="fade in" duration={2000}>
                            <div className="flueidProContactForm-successContainer">
                              <div
                                className="icon-close flueidProContactForm-IconClose"
                                onClick={closeSuccessMsg}></div>
                              <div className="flueidProContactForm-successTxt">Success</div>
                              <div className="flueidProContactForm-successSubTxt">Your message has been sent</div>
                            </div>
                          </Transition>
                        </Visibility>
                      )}
                      {submissionError && (
                        <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate}>
                          <Transition visible={elementVisible} animation="fade in" duration={2000}>
                            <div className="flueidProContactForm-successContainer">
                              <div className="icon-close flueidProContactForm-IconClose" onClick={closeErrorMsg}></div>
                              <div className="flueidProContactForm-successTxt">Oops</div>
                              <div
                                className="flueidProContactForm-successSubTxt"
                                style={{ width: "60%", textAlign: "center" }}>
                                Something went wrong. Please try again
                              </div>
                            </div>
                          </Transition>
                        </Visibility>
                      )}
                      <div className="flueidProContactForm-submitBtnSec">
                        <div className="p-4 txt-light">
                          <span className="txt-semiBold">We care about your privacy. </span>
                          Your data is secure and we will never share your information with outside parties.
                        </div>
                        <div className="btn-black" onClick={submitContactForm}>
                          SUBMIT
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flueidProContactForm-transition-containerWeb">
                    <div className="flueidProContactForm-container-column-right">
                      <div className="flueidProContactForm-content">
                        <div className="flueidProContactForm-img"></div>
                        <div className="subheader-2 txt-medium">
                          Sina Leonard{" "}
                          <span className="txt-regular" style={{ margin: "0 5px" }}>
                            | Chief of Staff
                          </span>
                        </div>
                        <div className="p-1 txt-light">. . . . . . . . . . . . . .</div>
                        <div className="subheader-main">
                          We’re always looking for exceptional people to join our team.{" "}
                          <a
                            target="_blank"
                            href="https://jobs.lever.co/flueid"
                            className="txt-medium"
                            rel="nofollow noreferrer noopener"
                            style={{ borderBottom: "1px solid #FFFFFF", color: "#ffffff" }}>
                            <span>See open positions.</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TalkToUsSection;
