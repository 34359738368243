import { showLoading, hideLoading, resetLoading } from "react-redux-loading-bar";
import { AppThunkAction } from "../..";
import { Action } from "redux";

export const loadingBarActionCreators = {
  showLoadingBar:
    (scope?: string): AppThunkAction<Action> =>
    (dispatch) => {
      dispatch(showLoading(scope));
    },

  hideLoadingBar:
    (scope?: string): AppThunkAction<Action> =>
    (dispatch) => {
      dispatch(hideLoading(scope));
    },

  resetLoadingBar:
    (scope?: string): AppThunkAction<Action> =>
    (dispatch) => {
      dispatch(resetLoading(scope));
    },
};
