import * as React from "react";
import DashedLineGroup, { DashedLine } from "../../controls/dashedLineGroup";

class AdvantageSection extends React.Component<any> {
  render() {
    return (
      <div className="whatElse-container">
        <div className="whatElse-content">
          <DashedLineGroup
            animationDirection="left"
            visibilityClassName="advantage-section-visibility-line-group-1"
            className="advantage-section-dashed-line-group-1"
            render={(visible: boolean) => (
              <>
                <DashedLine width={199} duration={0.5} delay={0.7} x={"-10vw"} show={visible} color="white" />
                <DashedLine width={150} duration={1.5} delay={0} x={"-8vw"} show={visible} color="white" />
                <DashedLine width={15} duration={1.5} delay={1} x={"-4vw"} show={visible} color="white" />
              </>
            )}
          />
          <div className="header-1 txt-white">The Flueid Advantage</div>
          <div className="subheader-1 txt-light">Independent. Secure. Customizable. Trusted.</div>

          <div className="whatElseTxtSubSecMain">
            <div className="whatelseSec rich">
              <div className="subheader-2 txt-semiBold">Independent.</div>
              <div className="p-3 txt-extraLight">
                We are an independent technology company meaning our platform is unique to us – it is not owned or
                managed by any one underwriter, lender, title agent or service provider.
              </div>
              <div className="subheader-2 txt-semiBold block-text-margin">Customizable.</div>
              <div className="p-3 txt-extraLight">
                <span className="txt-medium txt-underline">Rich Data:</span> Our proprietary database of consumer and
                property information, combined with various third-party data sources, eliminates the need for data
                sources that are traditionally employed in the closing process. As a result, our Flueid reports accessed
                within Flueid Pro deliver fast results and provide you actionable information whenever you need it.
              </div>
              <div className="p-3 txt-extraLight">
                <span className="txt-medium txt-underline">API:</span> Our platform is designed to connect all core real
                estate transaction systems together in one unified exchange. This means through Flueid Pro, your systems
                can be integrated across the touchpoints of the real estate lifecycle for a simpler, easier-to-manage
                process.
              </div>
            </div>

            <div className="whatelseSec rich">
              <div className="subheader-2 txt-semiBold">Enterprise-Grade Security and Data Handling.</div>
              <div className="p-3 txt-extraLight">
                <span className="txt-medium">Flueid takes security seriously.</span> Supporting our clients' efforts to
                safeguard their customers' data in a security-first approach is core to our mission. We stay current on
                the latest in consumer data protection, regulation, and security technology to actively adapt our
                security posture in response to latest cyber trends and events. This lets Flueid shoulder some of the
                stress associated with security and compliance for the industry professionals using our platform,
                providing an advanced solution with tested security guardrails, ensuring your clients' data is
                logistically separated from other clients or outside access.
              </div>
              <div className="whatelseSecParaTxt">
                <div className="icon-check-white"></div>
                <div className="p-3 txt-medium">Data and documents are securely stored</div>
              </div>
              <div className="whatelseSecParaTxt">
                <div className="icon-check-white"></div>
                <div className="p-3 txt-medium">Sensitive information is encrypted</div>
              </div>
              <div className="whatelseSecParaTxt">
                <div className="icon-check-white"></div>
                <div className="p-3 txt-medium">Access to information is highly restricted</div>
              </div>
              <div className="whatelseSecParaTxt">
                <div className="icon-check-white"></div>
                <div className="p-3 txt-medium">Events are tracked and audited</div>
              </div>
            </div>

            <div className="whatelseSec rich">
              <div className="subheader-2 txt-semiBold">Trusted.</div>
              <div className="p-3 txt-extraLight">
                <span className="txt-medium txt-underline">National Coverage:</span> With more data sources than other
                platforms on the market, Flueid Pro delivers comprehensive data{" "}
                <span className="txt-medium">in all 50 states.</span>{" "}
              </div>
              <div className="p-3 txt-extraLight">
                <span className="txt-medium txt-underline">SOC 2 Type I and Type 2 Audited:</span> We are proud to
                announce that we have completed the SOC 2 Type 1 and Type 2 Audits.
              </div>
              <div className="whatelseSecImage"></div>
            </div>
          </div>
          <DashedLineGroup
            visibilityClassName="advantage-section-visibility-line-group-2"
            className="advantage-section-dashed-line-group-2"
            render={(visible: boolean) => (
              <>
                <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} color="white" />
                <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} color="white" />
                <DashedLine width={140} duration={1} delay={0.5} x={"18vw"} show={visible} color="white" />
                <DashedLine width={189} duration={0.5} delay={0.7} x={"20vw"} show={visible} color="white" />
              </>
            )}
          />
        </div>
      </div>
    );
  }
}

export default AdvantageSection;
