import { Partner } from "dataTypes/partners";
import styles from "./_variables.module.scss";

export function setPartnerStyles(partner?: Partner) {
  if (partner?.branding?.mainColor) {
    document.documentElement.style.setProperty(styles.partnerMainColor, partner.branding.mainColor);
  }
  if (partner?.branding?.secondaryColor) {
    document.documentElement.style.setProperty(styles.partnerSecondaryColor, partner.branding.secondaryColor);
  }
}
