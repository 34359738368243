import PropDetailNavbar from "../titlefyPro/propertyDetails/propDetailPage/propDetailNavbar";

interface Props {
  navTitle?: string;
  header?: string;
  text?: string | React.ReactNode;
}

const titleDefault = "Error Message";
const headerDefault = "Oops! Something went wrong.";
const textDefault = (
  <div>
    Please refresh the page. If the problem persists, please contact{" "}
    <a href="mailto:oops@pro.flueid.com">oops@pro.flueid.com</a> for assistance.
  </div>
);

export const DefaultFallbackUI = ({ header = headerDefault, text = textDefault, navTitle = titleDefault }: Props) => (
  <div className="errorBoundary-fallbackUI">
    <PropDetailNavbar title={navTitle} />
    <div className="content-border">
      <div className="errorBoundary-header">{header}</div>
      <div className="errorBoundary-txt">{text}</div>
    </div>
  </div>
);
