import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { AdminType } from "../../../dataTypes/user/authorizationSettings";
import { AdminNavTabType } from "../../../dataTypes/ui/adminUI";
import { SearchUsersFilter, newUserFilters } from "../../../dataTypes/user/searchUsers";
import { CompanyFilters, newCompanyFilters } from "../../../dataTypes/partners";

export interface AdminUIState {
  adminType: AdminType;
  activeNavTab: AdminNavTabType;
  usersFilters: SearchUsersFilter;
  usersFiltersPartnerSearchText: string;
  companyFilters: CompanyFilters;
}

export const defaultAdminUI: AdminUIState = {
  adminType: AdminType.Titlefy,
  activeNavTab: AdminNavTabType.Users,
  usersFilters: newUserFilters(),
  usersFiltersPartnerSearchText: "",
  companyFilters: newCompanyFilters(),
};

export const AdminUIReducer: Reducer<AdminUIState, KnownAction> = (state = defaultAdminUI, action: KnownAction) => {
  switch (action.type) {
    case "SET_ADMIN_TYPE":
      return { ...state, adminType: action.payload };
    case "SET_NAV_TAB_TYPE":
      return { ...state, activeNavTab: action.payload };
    case "SET_COMPANY_FILTERS":
      return { ...state, companyFilters: action.payload };
    case "SET_USERS_FILTERS":
      return { ...state, usersFilters: action.payload };
    case "SET_USERS_FILTERS_PARTNERS_SEARCH_TEXT":
      return { ...state, usersFiltersPartnerSearchText: action.payload };
    case "CLEAR_ADMIN_UI_STORE":
      return defaultAdminUI;
    default:
      return state || defaultAdminUI;
  }
};
