import * as React from "react";
import { List } from "semantic-ui-react";

export const UnknownValue = "-";
export const UnknownMoney = "$0";

interface ListUIProps {
  children?: React.ReactNode;
  className?: string;
}
export const ListContainer = (props: ListUIProps) => {
  return <div className={`property-detail-listContainer ${props.className || ""}`}>{props.children}</div>;
};

export const ListColumnLeft = (props: ListUIProps) => {
  return <div className={`property-detail-listColumn-left ${props.className || ""}`}>{props.children}</div>;
};

export const ListColumnRight = (props: ListUIProps) => {
  return <div className={`property-detail-listColumn-right ${props.className || ""}`}>{props.children}</div>;
};

interface ListHeaderProps extends ListUIProps {
  text?: string;
}
export const ListHeader = (props: ListHeaderProps) => {
  return (
    <div className={`property-detail-header ${props.className || ""}`}>
      {props.text} {props.children || ""}
    </div>
  );
};

export const ListHeader2 = (props: ListHeaderProps) => {
  return (
    <div className={`property-detail-header2 ${props.className || ""}`}>
      {props.text} {props.children || ""}
    </div>
  );
};

export const ListSubHeader = (props: ListHeaderProps) => {
  return (
    <div className={`property-detail-subheader ${props.className || ""}`}>
      {props.text} {props.children || ""}
    </div>
  );
};

interface ListRowProps {
  name: string | JSX.Element;
  iconClass?: string;
  value: string | string[] | number | JSX.Element | null | undefined;
  className?: string;
  subValue?: string;
}

export const ListRow = ({ name, value, iconClass, className, subValue }: ListRowProps) => (
  <div className={`property-factItem ${className || ""}`}>
    <List.Item className="property-factListItem">
      {iconClass && <List.Content className={iconClass} />}
      <List.Content>{name}</List.Content>
    </List.Item>
    <List.Item>
      {Array.isArray(value) && value.length ? (
        value.map((x: string, index: number) => (
          <List.Content key={`list-row-${index}`} className="property-familyStatus">
            {
              // more than one item and not last item? add a coma and space between values
              value.length > 1 && index + 1 !== value.length ? `${x}, ` : x || UnknownValue
            }
          </List.Content>
        ))
      ) : (
        <List.Content className="property-familyStatus">{value || UnknownValue}</List.Content>
      )}
      {subValue ? <List.Content className="txt-sm txt-gray txt-right">{subValue || UnknownValue}</List.Content> : ""}
    </List.Item>
  </div>
);
