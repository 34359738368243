import { Reducer } from "redux";
import { OrderTypeState } from "../../../dataTypes/ui";
import { KnownAction } from "./actionTypes";

export interface NewOrderUIState {
  serviceStep: number;
  finalServiceStep: number;
  productCategoryStep: number;
  finalProductCategoryStep: number;
  subTypeStep: number;
  finalSubTypeStep: number;
  selected: OrderTypeState;
}

export const defaultNewOrderUIState: NewOrderUIState = {
  serviceStep: 1,
  finalServiceStep: 0,
  productCategoryStep: 1,
  finalProductCategoryStep: 0,
  subTypeStep: 1,
  finalSubTypeStep: 0,
  selected: {
    commercial: false,
    construction: false,
    orderServiceTypeOptions: null,
    orderSubTypeOption: null,
    orderTypeOption: null,
    preCloseType: null,
    additionalOrderOptions: null,
  },
};

export const NewOrderUIReducer: Reducer<NewOrderUIState, KnownAction> = (
  state = defaultNewOrderUIState,
  action: KnownAction,
) => {
  switch (action.type) {
    case "UPDATE_NEW_ORDER_UI":
      return { ...state, ...action.payload };
    case "SET_SELECTED":
      return { ...state, selected: { ...state.selected, ...action.payload } };
    case "INCREMENT_SERVICE_STEP":
      return { ...state, serviceStep: state.serviceStep + 1, productCategoryStep: 1, subTypeStep: 1 };
    case "DECREMENT_SERVICE_STEP":
      return {
        ...state,
        serviceStep: state.serviceStep - 1,
        productCategoryStep: action.finalProdCategoryStep,
        finalProductCategoryStep: action.finalProdCategoryStep,
        subTypeStep: action.finalSubTypeStep,
        finalSubTypeStep: action.finalSubTypeStep,
      };
    case "INCREMENT_PROD_CATEGORY_STEP":
      return { ...state, productCategoryStep: state.productCategoryStep + 1, subTypeStep: 1 };
    case "DECREMENT_PROD_CATEGORY_STEP":
      return {
        ...state,
        productCategoryStep: state.productCategoryStep - 1,
        subTypeStep: action.finalSubTypeStep,
        finalSubTypeStep: action.finalSubTypeStep,
        //subTypeStep: action.payload,
        //finalSubTypeStep: action.payload
      };
    case "INCREMENT_SUB_TYPE_STEP":
      return { ...state, subTypeStep: state.subTypeStep + 1 };
    case "DECREMENT_SUB_TYPE_STEP":
      return { ...state, subTypeStep: state.subTypeStep - 1 };
    case "RESET_PRODUCTS_STEPS":
      return {
        ...state,
        serviceStep: 1,
        finalServiceStep: 0,
        productCategoryStep: 1,
        finalProductCategoryStep: 0,
        subTypeStep: 1,
        finalSubTypeStep: 0,
      };
    case "RESET_NEW_ORDER_UI":
      return defaultNewOrderUIState;
    default:
      return state || defaultNewOrderUIState;
  }
};
