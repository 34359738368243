import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { CognitoUser } from "@aws-amplify/auth";
import { User } from "../../dataTypes/user/user";
import { UserSettings } from "../../dataTypes/user/userSettings";
import { uniqueID } from "../../utilities/formatters";
import { DropdownOption } from "../../dataTypes/common";
import {
  RequestStatus,
  newRequestStatus,
  ReqStatus,
  newReqStatus,
  newPendingReq,
  reqOk,
  reqFailed,
} from "../../utilities/apiService";
import { SearchUsersResult, newSearchUsersResult } from "../../dataTypes/user/searchUsers";

export interface AccountState {
  signedInUser: User | null;
  userSettings: UserSettings | null;
  saveResult: saveResult;
  isLoading: boolean;
  realEstateUserRole: any;

  officersOrAccountExecsOptions: DropdownOption[];
  assistantUsersOptions: DropdownOption[];

  financeUserRole: any;
  partnerUserRole: any;
  searchUsersResult: SearchUsersResult;
  searchUsersReqStatus: ReqStatus;
  // this userProfile is for updating a user from the Admin portal, NOT anything todo with the signed in user
  userProfile: User | null;
  acctManagerProfile: User | null;
  getUserPending: boolean;

  updatePartnerAssociationPending: boolean;

  updateUserStatusPending: boolean;
  approveUserRequestPending: boolean;

  // update the user's basic contact info
  updateUserBasicProfilePending: boolean;
  updateUserBasicProfileSuccessId: string;
}

const unloadedState: AccountState = {
  signedInUser: null,
  userSettings: null,
  saveResult: { status: 0 },
  isLoading: false,
  realEstateUserRole: [],
  officersOrAccountExecsOptions: [],
  assistantUsersOptions: [],
  financeUserRole: [],
  partnerUserRole: [],
  searchUsersReqStatus: newReqStatus(),
  searchUsersResult: newSearchUsersResult(),
  userProfile: null,
  acctManagerProfile: null,
  getUserPending: false,
  updatePartnerAssociationPending: false,
  updateUserStatusPending: false,
  approveUserRequestPending: false,
  updateUserBasicProfilePending: false,
  updateUserBasicProfileSuccessId: "",
};

interface saveResult {
  status: number;
}

//NOTE: typescript didn't like (state: UserState) in the argument list
export const AccountReducer: Reducer<AccountState, KnownAction> = (state = unloadedState, action: KnownAction) => {
  switch (action.type) {
    case "SET_USER_PROFILE":
      return { ...state, userProfile: action.payload };
    case "GET_SIGNED_IN_USER":
      return { ...state, signedInUser: action.payload };
    case "SET_SIGNED_IN_USER":
      return { ...state, signedInUser: action.payload };
    case "GET_USER_SETTINGS":
      return { ...state, userSettings: action.payload };
    case "UPDATE_USER_BASIC_PROFILE_PENDING":
      return { ...state, updateUserBasicProfilePending: true };
    case "UPDATE_USER_BASIC_PROFILE":
      return { ...state, updateUserBasicProfilePending: false, updateUserBasicProfileSuccessId: uniqueID() };
    case "UPDATE_USER_BASIC_PROFILE_FAILED":
      return { ...state, updateUserBasicProfilePending: false };

    case "SAVE_USER_PROFILE_RECEIVED_FAILURE":
      return { ...state, saveResult: { status: 2 }, isLoading: false };

    case "GET_REAL_ESTATE_ROLES_REQUEST":
      return { ...state, isLoading: true };

    case "GET_REAL_ESTATE_ROLES_RECEIVED":
      return { ...state, realEstateUserRole: action.payload, isLoading: false };

    case "GET_FINANCE_ROLES_REQUEST":
      return { ...state, isLoading: true };

    case "GET_FINANCE_ROLES_RECEIVED":
      return { ...state, financeUserRole: action.payload, isLoading: false };

    case "GET_PRTNER_USER_ROLES_REQUEST":
      return { ...state, isLoading: true };

    case "GET_PRTNER_USER_ROLES_RECEIVED":
      return { ...state, partnerUserRole: action.payload, isLoading: false };

    case "SEARCH_OFFICERS_OR_ACCOUNT_EXECS":
      return { ...state, officersOrAccountExecsOptions: action.payload };
    case "SEARCH_ASSISTANT_USERS":
      return { ...state, assistantUsersOptions: action.payload };
    case "CLEAR_OFFICERS_ACCOUNT_EXECS_OPTIONS":
      return { ...state, officersOrAccountExecsOptions: [] };

    //-------------
    // SEARCH USERS
    case "SEARCH_USERS_PENDING":
      return { ...state, searchUsersReqStatus: action.payload };
    case "SEARCH_USERS":
      return { ...state, searchUsersResult: action.payload, searchUsersReqStatus: reqOk() };
    case "SEARCH_USERS_FAILED":
      return { ...state, searchUsersReqStatus: reqFailed() };
    //---------------

    case "GET_USER_PENDING":
      return { ...state, getUserPending: true };
    case "GET_USER_PROFILE":
      return { ...state, userProfile: action.payload, getUserPending: false };

    case "GET_ACCT_MANAGER_PROFILE":
      return { ...state, acctManagerProfile: action.payload };

    case "UPDATE_USER_STATUS_PENDING":
      return { ...state, updateUserStatusPending: true };
    case "UPDATE_USER_STATUS":
      return {
        ...state,
        updateUserStatusPending: false,
      };

    case "APPROVE_USER_REQUEST_PENDING":
      return { ...state, approveUserRequestPending: true };

    case "SAVE_APPROVE_USER_RECEIVED_SUCCESS":
      return {
        ...state,
        approveUserRequestPending: false,
      };

    case "SAVE_RESET_PASSWORD_USER_RECEIVED_SUCCESS":
      return { ...state };

    case "UPDATE_PARTNER_ASSOCIATION_PENDING":
      return { ...state, updatePartnerAssociationPending: true };
    case "UPDATE_PARTNER_ASSOCIATION":
      return { ...state, updatePartnerAssociationPending: false };
    case "UPDATE_USER_ASSISTANTS":
      return state.userProfile // check because userProfile can be null
        ? {
            ...state,
            userProfile: { ...state.userProfile, assistants: action.payload },
          }
        : state;

    case "CLEAR_USER_PROFILE":
      return { ...state, userProfile: null };
    case "CLEAR_ACCOUNT_STORE":
      return { ...unloadedState };
    default:
      return state || unloadedState;
  }
};
