import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { FarmFilter, SavedFarmFilters } from "../../../dataTypes/filters/farmFilter";
import { CalculatedMaxDefaults } from "../../../components/titlefyPro/farms/farmFilters/utilities/defaultValues";
import { uniqueID } from "../../../utilities/formatters";
import { isEqual } from "lodash";

export interface FarmFiltersState {
  farmFilters: FarmFilter;
  filtersResetEventId: string;
  savedFarmFilters: SavedFarmFilters;
  maxDefault: CalculatedMaxDefaults;
  filtersEdited: boolean;
  saveFarmFilter: FarmFilter; // filter that's getting ready to be saved, renamed etc.
  deletedFarmFilterId: number; // id for filter that's getting ready to be deleted
}

const unloadedState: FarmFiltersState = {
  farmFilters: { ...new FarmFilter() },
  filtersResetEventId: "",
  savedFarmFilters: { savedFilters: [], globalFilters: [] },
  maxDefault: new CalculatedMaxDefaults(),
  filtersEdited: false,
  saveFarmFilter: { ...new FarmFilter() },
  deletedFarmFilterId: 0,
};

export const FarmFiltersReducer: Reducer<FarmFiltersState, KnownAction> = (
  state = unloadedState,
  action: KnownAction,
) => {
  switch (action.type) {
    case "SET_FILTERS":
      return {
        ...state,
        farmFilters: action.payload,
        filtersEdited: !isEqual(action.payload, { ...new FarmFilter(action.maxDefaults || state.maxDefault) }),
      };

    case "SET_MAX_DEFAULT_VALUES":
      return {
        ...state,
        maxDefault: action.payload,
      };

    case "RESET_DEFAULT_FARM_FILTERS":
      return {
        ...state,
        farmFilters: { ...new FarmFilter(state.maxDefault) },
        filtersResetEventId: uniqueID(),
        filtersEdited: false,
      };

    case "GET_FARM_FILTERS":
      const savedFarmFilters: SavedFarmFilters = {
        savedFilters: [],
        globalFilters: [],
      };

      // portion out the global/saved farm filters
      action.payload.forEach((farmFilter: FarmFilter) => {
        // isGlobal seems to be ignored with BSON ignore flag on the API
        //  maybe custom global filter needs to be found by whether it has a farmId on it or not...

        farmFilter.farmId
          ? savedFarmFilters.savedFilters.push(farmFilter)
          : savedFarmFilters.globalFilters.push(farmFilter);
      });

      return { ...state, savedFarmFilters };

    case "SET_SAVED_FILTER_ACTIVE":
      // find the saved filter we're going to apply
      const activeSavedFilter = [...state.savedFarmFilters.savedFilters, ...state.savedFarmFilters.globalFilters].find(
        (farmFilter: FarmFilter) => farmFilter.farmFilterId === action.payload,
      );

      if (activeSavedFilter) {
        return {
          ...state,
          farmFilters: activeSavedFilter,
          filtersEdited: true,
        };
      } else return { ...state };

    case "SET_SAVE_FARM_FILTER":
      return { ...state, saveFarmFilter: action.payload };
    case "SET_DELETE_FARM_FILTER_ID":
      return { ...state, deletedFarmFilterId: action.payload };

    default:
      return state || unloadedState;
  }
};
