import { enumToDictArray } from "../../../utilities/typescriptHelpers";
import { OrderServiceType, serviceTypeLabels } from "./orderServiceType";

export enum OrderType {
  Undefined = 0,
  Refinance = 1,
  Purchase = 2,
  REO = 3,
  HomeEquity = 4,
  Default = 5,
  Uninsured = 6,
  Presale = 7,
}

/** IMPORTANAT NOTE - when adding new member, if not a Decision related type, MUST add to isClosingOrderSubType list for options to appear in Order Type step of wizard */
export enum OrderSubType {
  Undefined = 0,

  //Refinance
  FirstLoanOnly = 100,
  FirstAndSecondLoan = 101,
  FirstLoanAndCashOut = 102,
  ConstructionLoanRefi = 103,
  LoanModification = 104,
  ReverseMortgage = 105,
  TitleCheckRefinance = 106,

  //Purchase
  WithMortgage = 200,
  AllCash = 201,
  ShortSale = 202,
  TenThirtyOneExchange = 203,
  ConstructionLoanPurchase = 204,
  Timeshare = 205,
  TitleCheckPurchase = 206,

  //REO
  StandardREO = 300,

  //Home Equity
  // phasing out StandardHomeEquity, HomeEquityInsured, they were placeholders before HELOC & HELOAN arrived
  // StandardHomeEquity = 400,
  // HomeEquityInsured = 401,
  TitleCheckEquity = 402,
  HELOC = 403,
  HELOAN = 404,

  //Default
  LossMitigation = 500,
  LitigationGuarantee = 501,
  TrusteeSaleGuarantee = 502,
  TitleCheckServicer = 503,

  //Uninsured
  FullPropertyReport = 600,

  //Presale
  StandardPresale = 700,
}

//returns true if order subtype is closing subtype
export const isClosingOrderSubType = (subType: OrderSubType): boolean => {
  return [
    //Refinance
    OrderSubType.FirstLoanOnly,
    OrderSubType.FirstAndSecondLoan,
    OrderSubType.FirstLoanAndCashOut,
    OrderSubType.ConstructionLoanRefi,
    OrderSubType.FirstLoanOnly,
    OrderSubType.LoanModification,
    OrderSubType.ReverseMortgage,

    //Purchase
    OrderSubType.WithMortgage,
    OrderSubType.AllCash,
    OrderSubType.ShortSale,
    OrderSubType.TenThirtyOneExchange,
    OrderSubType.ConstructionLoanPurchase,
    OrderSubType.Timeshare,

    //REO
    OrderSubType.StandardREO,

    //Home equity
    // phasing out StandardHomeEquity, HomeEquityInsured, they were placeholders before HELOC & HELOAN arrived
    // OrderSubType.StandardHomeEquity,
    // OrderSubType.HomeEquityInsured,
    OrderSubType.HELOC,
    OrderSubType.HELOAN,

    //Default
    OrderSubType.LossMitigation,
    OrderSubType.LitigationGuarantee,
    OrderSubType.TrusteeSaleGuarantee,

    //Uninsured
    OrderSubType.FullPropertyReport,

    //Presale
    OrderSubType.StandardPresale,
  ].includes(subType);
};

export const isDecisionOrderSubType = (subType: OrderSubType) => {
  return !isClosingOrderSubType(subType);
};

export const orderSubTypeLabels = new Map<OrderSubType, string>([
  [OrderSubType.TenThirtyOneExchange, "1031 Exchange"],
  [OrderSubType.HELOC, "Home Equity (HELOC)"],
  [OrderSubType.HELOAN, "2nd Loan Only (HELOAN)"],
  [OrderSubType.TitleCheckRefinance, "TitleCheck for Refinance"],
  [OrderSubType.TitleCheckPurchase, "TitleCheck for Purchase"],
  [OrderSubType.TitleCheckEquity, "TitleCheck for Equity"],
  [OrderSubType.TitleCheckServicer, "TitleCheck for Servicer"],
]);

export const arrayOfOrderTypes = enumToDictArray(OrderType);

export const arrayOfOrderSubTypes = enumToDictArray(OrderSubType, true, orderSubTypeLabels);
