import { OrderStep, NewOrderView } from "../enums/orderStep";

export interface OrderStepStatus {
  step: OrderStep;
  complete: boolean;
}

export interface OrderSteps {
  newOrderView: NewOrderView;
  completedSteps: OrderStep[];
  activeStep: OrderStep;
  confirmChangesOpen: boolean;
}

export const initialCompletedSteps: OrderStep[] = [];
