import React from "react";
import styles from "./styles.module.scss";

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const AuthFormButton: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <button
      className={
        `${styles.authBtn} btn btn-xxl btn-black partner-main-color_background cursor-pointer ` + className || ""
      }
      {...props}>
      {children}
    </button>
  );
};

export default AuthFormButton;
