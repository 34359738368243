import { Partner } from "dataTypes/partners";
import styles from "./styles.module.scss";

export const AuthNavbarLogo: React.FC<{ partner?: Partner }> = ({ partner }) => {
  if (!partner?.logoMainUrl) return null;
  return (
    <a href={partner?.websiteUrl} className="flex">
      <img src={partner?.logoMainUrl} className={styles.navbarLogo} alt="company logo" />
    </a>
  );
};

export const AuthFormLogo: React.FC<{ partner?: Partner }> = ({ partner }) => {
  if (!partner?.logoMainUrl) return null;
  return <img src={partner?.logoMainUrl} className={styles.formLogo} alt="company logo" />;
};
