//export interface AuthorizationSettings {
//  settings: AuthSettingsCollection;
//}

import { enumToDictArray } from "utilities/typescriptHelpers";

//export interface AuthSettingsCollection {
//  orders: OrdersAuthSettings;
//  propertyData: PropertyDataAuthSettings;
//  accounts: AccountsAuthSettings;
//  quotes: QuotesSettings;
//  generalAppSettings: GeneralAppSettings;
//}

//export interface PropertyDataAuthSettings {
//  searchProperties: HierarchySetting;
//}

//export interface OrdersAuthSettings {
//  showOrdersSection: HierarchySetting;
//  openOrders: HierarchySetting;
//  canBeBusinessSource: HierarchySetting;
//}

//export interface AccountsAuthSettings {
//  administerAllUsers: HierarchySetting;
//  administerAllPartnerUsers: HierarchySetting;
//  administerAllCompanyUsers: HierarchySetting;
//  administerAllDivisionUsers: HierarchySetting;
//  administerAllBranchUsers: HierarchySetting;
//  createUsers: HierarchySetting;
//  resendTempPassword: HierarchySetting;
//  approveUserSignUps: HierarchySetting;
//  userSignUpApprovalsRequireAdminVerification: HierarchySetting;
//}

// used for determining which type of UI elements show in the Admin components
export enum AdminType {
  Titlefy,
  Partner,
  Company,
}

//export interface QuotesSettings {
//  showQuotesSection: HierarchySetting;
//  showNetsheet: HierarchySetting;
//  showBuyersEstimate: HierarchySetting;
//}

//export interface GeneralAppSettings {
//  viewFeeCalculators: HierarchySetting;
//  uploadFarms: HierarchySetting;
//}

//interface HierarchySetting {
//  value: boolean;
//}

export interface Permission {
  name: string;
  overrideLowerLevels: boolean;
  preventOverridebyHigherLevels: boolean;
  sourceEntityId: number;
  sourceEntityType: string;
  value: boolean;
}

export interface PermissionDisplay extends Permission {
  displayAs: string;
  helpInfo: string;
  isEnabled: boolean;
}

export interface HierarchySetting extends Permission {
  setByEntityType: EntityType;
  setByEntityId: number;
}

export enum EntityType {
  User = 10,
  Branch = 20,
  Company = 30,
  Division = 40,
  Group = 50,
  Partner = 60,
  Default = 9999,
}

export const entityTypeOptions = enumToDictArray(EntityType);

export enum PermissionGroup {
  Search = 0,
  Orders,
  Accounts,
  Quotes,
  GeneralAppSettings,
  PropertyData,
}

export interface GetEntityPermissionsRequest {
  entityType: EntityType;
  entityId?: number;
  permissionGroupName: keyof typeof PermissionGroup;
  targetedPartnerId: number;
}

export interface UpsertEntityPermissionRequest {
  entityId: number | undefined;
  entityType: EntityType;
  targetedPartnerId: number;
  permission: HierarchySetting;
}

export interface EffectivePermissionRequest {
  userId: number;
  targetedPartnerId: number;
  permissionName: string;
}

export interface EffectivePermission {
  entityLevel: EntityType;
  value: boolean;
  overrideLowerLevels: boolean;
  preventOverrideByHigherLevels: boolean;
}
