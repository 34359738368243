import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { OrderStep } from "../../../dataTypes/orders/enums/orderStep";
import { OrderStatus } from "../../../dataTypes/orders/enums/orderStatus";
import { OrdersSortBy } from "../../../dataTypes/orders/interfaces/orderSearchRequest";

export interface OrdersUIState {
  orderStatus: OrderStatus;
  pageIndex: number;
  ordersSortBy: OrdersSortBy;
}

const defaultOrdersUI: OrdersUIState = {
  orderStatus: OrderStatus.InProcess,
  pageIndex: 1,
  ordersSortBy: OrdersSortBy.OpenedDateDesc,
};

export const OrdersUIReducer: Reducer<OrdersUIState, KnownAction> = (state = defaultOrdersUI, action: KnownAction) => {
  switch (action.type) {
    case "UPDATE_ORDERS_UI":
      return { ...state, ...action.payload };
    default:
      return state || defaultOrdersUI;
  }
};
