import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { SavedListType } from "../../../dataTypes/properties";

export type SidebarContentType = "SearchResults" | "CardsList" | "Map";
export type SavedMobileContentType = Exclude<SidebarContentType, "SearchResults">;

export interface PropertiesUIState {
  showFilters: boolean;
  showMobileFilters: boolean;
  savedListType: SavedListType;
  propsListStartIndex: number;
  savedPropsListIndex: number;
  sidebarContentType?: SidebarContentType;
  savedMobileContentType?: SavedMobileContentType;
}

const unloadedState: PropertiesUIState = {
  showFilters: false,
  showMobileFilters: false,
  savedListType: "properties",
  propsListStartIndex: 0,
  savedPropsListIndex: 0,
  sidebarContentType: "Map",
  savedMobileContentType: "Map",
};

export const PropertiesUIReducer: Reducer<PropertiesUIState, KnownAction> = (
  state = unloadedState,
  action: KnownAction,
) => {
  switch (action.type) {
    case "UPDATE_PROPERTIES_UI":
      return { ...state, ...action.payload };
    case "SET_PROPS_LIST_START_INDEX":
      return { ...state, propsListStartIndex: action.payload };
    case "SET_SIDEBAR_CONTENT_TYPE":
      return { ...state, sidebarContentType: action.payload };
    case "SET_SAVED_MOBILE_CONTENT_TYPE":
      return { ...state, savedMobileContentType: action.payload };
    case "SET_SAVED_PROPS_LIST_INDEX":
      return { ...state, savedPropsListIndex: action.payload };
    default:
      return state || unloadedState;
  }
};
