const Accounts = {
  AdjustPermissions: "Accounts.AdjustPermissions",
  AdministerAllUsers: "Accounts.AdministerAllUsers",
  AdministerAllPartnerUsers: "Accounts.AdministerAllPartnerUsers",
  AdministerAllCompanyUsers: "Accounts.AdministerAllCompanyUsers",
  AdministerAllDivisionUsers: "Accounts.AdministerAllDivisionUsers",
  AdministerAllBranchUsers: "Accounts.AdministerAllBranchUsers",
  ApproveUserSignUps: "Accounts.ApproveUserSignUps",
  CreateUsers: "Accounts.CreateUsers",
  ResendTempPassword: "Accounts.ResendTempPassword",
  UserSignUpApprovalsRequireAdminVerification: "Accounts.UserSignUpApprovalsRequireAdminVerification",
} as const;

const Orders = {
  CanBeBusinessSource: "Orders.CanBeBusinessSource",
  OpenOrders: "Orders.OpenOrders",
  ShowOrdersSection: "Orders.ShowOrdersSection",
  OpenClosingServices: "Orders.OpenClosingServices",
  OpenInsurfulOrders: "Orders.OpenInsurfulOrders",
  OpenTitleCheckOrders: "Orders.OpenTitleCheckOrders",
  UpdateInsurfulOrders: "Orders.UpdateInsurfulOrders",
  OnlyDisplayTitleOption: "Orders.OnlyDisplayTitleOption",
  CanCloseOrders: "Orders.CanCloseOrders",
  CanFullUpdateDecisionOrder: "Orders.CanFullUpdateDecisionOrder",
  CanModifyDecisionOrder: "Orders.CanModifyDecisionOrder",
  DisableOpenOrdersForExternalUsers: "Orders.DisableOpenOrdersForExternalUsers",
  DownloadTrailingDocuments: "Orders.DownloadTrailingDocuments",
} as const;

const GeneralAppSettings = {
  ViewFeeCalculators: "GeneralAppSettings.ViewFeeCalculators",
  UploadFarms: "GeneralAppSettings.UploadFarms",
  CanRefreshConfig: "GeneralAppSettings.CanRefreshConfig",
  HideFarms: "GeneralAppSettings.HideFarms",
  HideHotProperties: "GeneralAppSettings.HideHotProperties",
  DisableEmailNotifications: "GeneralAppSettings.DisableEmailNotifications",
} as const;

const PropertyData = {
  SearchProperties: "PropertyData.SearchProperties",
  PullPropertyImages: "PropertyData.PullPropertyImages",
  PullTaxRolls: "PropertyData.PullTaxRolls",
} as const;

const Quotes = {
  ShowBuyersEstimate: "Quotes.ShowBuyersEstimate",
  ShowNetsheet: "Quotes.ShowNetsheet",
  ShowQuotesSection: "Quotes.ShowQuotesSection",
  CanCalculateRateQuotes: "Quotes.CanCalculateRateQuotes",
} as const;

export const PermissionTypes = {
  Accounts: { ...Accounts },
  Orders: { ...Orders },
  GeneralAppSettings: { ...GeneralAppSettings },
  PropertyData: { ...PropertyData },
  Quotes: { ...Quotes },
} as const;

export type PermissionTypes =
  | typeof Accounts[keyof typeof Accounts]
  | typeof Orders[keyof typeof Orders]
  | typeof GeneralAppSettings[keyof typeof GeneralAppSettings]
  | typeof PropertyData[keyof typeof PropertyData]
  | typeof Quotes[keyof typeof Quotes];

export const adminPermissions: PermissionTypes[] = [
  PermissionTypes.Accounts.AdministerAllUsers,
  PermissionTypes.Accounts.AdministerAllPartnerUsers,
  PermissionTypes.Accounts.AdministerAllCompanyUsers,
  PermissionTypes.Accounts.AdministerAllDivisionUsers,
  PermissionTypes.Accounts.AdministerAllBranchUsers,
];
