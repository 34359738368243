import * as React from "react";
import LoadingBarAlias, { LoadingBarProps, showLoading, hideLoading, resetLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";

interface Props extends LoadingBarProps {
  containerStyle?: React.CSSProperties;
}

export const LoadingBar = ({ style = { backgroundColor: "#00B2F1", height: "2px" }, ...props }: Props) => {
  return (
    <div className="loading-bar-container" style={{ width: "100%", ...props.containerStyle }}>
      <LoadingBarAlias style={style} {...props} />
    </div>
  );
};

/** Hook into loading bar redux actions and state. */
export const useLoadingBar = () => {
  const dispatch = useDispatch();
  const loadingBar = useSelector((state: ApplicationState) => state.loadingBar);

  // there's no documentation on this but when inspecting the loadingBar state in redux,
  // the loader shows if default property is greater than 0
  const isLoading = loadingBar?.default > 0;

  return {
    isLoading,
    hideLoading: () => dispatch(hideLoading()),
    resetLoading: () => dispatch(resetLoading()),
    showLoading: () => dispatch(showLoading()),
  };
};
