import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { MapTilt } from "../propertiesMap/propertiesMapStore";
import { IwContentType } from "dataTypes/maps";

export interface FarmMapState {
  options: google.maps.MapOptions;
  iwContentType: IwContentType;
}

const unloadedState: FarmMapState = {
  options: {
    mapTypeId: "roadmap",
    tilt: MapTilt.None,
  },
  iwContentType: "value",
};

export const FarmMapReducer: Reducer<FarmMapState, KnownAction> = (state = unloadedState, action: KnownAction) => {
  switch (action.type) {
    case "SET_FARM_MAP_OPTIONS":
      return { ...state, options: { ...state.options, ...action.payload } };
    case "RESET_FARM_MAP_STATE":
      return { ...unloadedState };
    case "SET_IW_CONTENT_TYPE":
      return { ...state, iwContentType: action.payload };
    default:
      return state || unloadedState;
  }
};
