import { DropdownOption } from "../common";
import { startCase } from "lodash";

export enum PropertyType {
  // keep Unknown and ResidentialOnly commented out because they're problematic with the API
  // Unknown = 0,
  // ResidentialOnly = 1,
  SingleFamily = 2,
  Condo = 3,
  Townhouse = 4,
  MultiFamily = 5,
  LotLand = 6,
  Cooperative = 7,
  MobileHome = 8,
  Pud = 9,
  Duplex = 10,
  Triplex = 11,
  Quadraplex = 12,
  Residential = 50,
  Agricultural = 70,
  Miscellaneous = 100,
  Commercial = 200,
}

// these properties will fail on TitleCheck api
export const invalidFDProps = [
  PropertyType.MultiFamily,
  PropertyType.Cooperative,
  PropertyType.Residential,
  PropertyType.Miscellaneous,
  PropertyType.LotLand,
  PropertyType.Agricultural,
  PropertyType.Commercial,
];

export const PropertyTypeLabels = new Map<PropertyType, string>([
  [PropertyType.SingleFamily, "Single Family Residential"],
  [PropertyType.Condo, "Condo"],
  [PropertyType.Townhouse, "Townhouse"],
  [PropertyType.MultiFamily, "Multi-Family"],
  [PropertyType.LotLand, "Lot / Land"],
  [PropertyType.Cooperative, "Cooperative"],
  [PropertyType.MobileHome, "Mobile Home"],
  [PropertyType.Pud, "Pud"],
  [PropertyType.Duplex, "Duplex"],
  [PropertyType.Triplex, "Triplex"],
  [PropertyType.Quadraplex, "Quadraplex"],
  [PropertyType.Residential, "Residential - Other"],
  [PropertyType.Agricultural, "Agricultural"],
  [PropertyType.Commercial, "Commercial"],
  [PropertyType.Miscellaneous, "Miscellaneous"],
]);

// builds an array of all the PropertyType values
export function allPropTypes(): any[] {
  const allPropTypes: any[] = [];
  for (let propType in PropertyType) {
    if (typeof PropertyType[propType] === "number") allPropTypes.push(PropertyType[propType]);
  }
  return allPropTypes;
}

export function allSingleResidentialTypes(): PropertyType[] {
  return [PropertyType.SingleFamily, PropertyType.Residential];
}

export function allCommercialTypes(): PropertyType[] {
  return [PropertyType.Commercial, PropertyType.Cooperative, PropertyType.Pud, PropertyType.Miscellaneous];
}

export function allMultiplexTypes(): PropertyType[] {
  return [PropertyType.Duplex, PropertyType.Triplex, PropertyType.Quadraplex];
}

export function allCondoMultiFamTypes(): PropertyType[] {
  return [PropertyType.Condo, PropertyType.MultiFamily];
}

// can be used to construct initial filters based on a single propertyType
// useful because some filters are presented as a single option, but need to include multiple propertyTypes when selected
export function propTypeFiltersByPropType(propType: PropertyType): PropertyType[] {
  const types: PropertyType[] = [];

  switch (propType) {
    case PropertyType.SingleFamily:
    case PropertyType.Residential:
      types.push(...allSingleResidentialTypes());
      break;
    case PropertyType.Condo:
      types.push(PropertyType.Condo);
      break;
    case PropertyType.Townhouse:
      types.push(PropertyType.Townhouse);
      break;
    case PropertyType.MultiFamily:
      types.push(PropertyType.MultiFamily);
      break;
    case PropertyType.LotLand:
      types.push(PropertyType.LotLand);
      break;
    case PropertyType.Cooperative:
    case PropertyType.Commercial:
    case PropertyType.Pud:
    case PropertyType.Miscellaneous:
      types.push(...allCommercialTypes());
      break;
    case PropertyType.MobileHome:
      types.push(PropertyType.MobileHome);
      break;
    case PropertyType.Duplex:
    case PropertyType.Triplex:
    case PropertyType.Quadraplex:
      types.push(...allMultiplexTypes());
      break;
  }

  return types;
}

export function propTypesDropdownOptions(): DropdownOption[] {
  const propTypes = allPropTypes();

  const propTypeOptions: DropdownOption[] = propTypes.map((x: number) => {
    return { value: x, text: PropertyTypeLabels.get(x) || "" };
  });

  return propTypeOptions;
}
