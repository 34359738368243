import { Partner } from "../../../dataTypes/partners";

type AuthPathBase = "/auth";
type AuthComponent =
  | "/terms-conditions"
  | "/sign-in"
  | "/reset-password"
  | "/new-user-reset-password"
  | "/logout-success";
// All AuthPath's must have the companyName as the last item for getNameFromSignInUrl to work
export type AuthPath = `${AuthPathBase}${AuthComponent}` | `${AuthPathBase}${AuthComponent}/:companyName`;
export const baseSignInPath: AuthPath = "/auth/sign-in";

export function getAuthPath(path: AuthPath, partner?: Partner): string {
  // grab shortname off sign in url
  const nameFromSignInUrl = getNameFromSignInUrl(partner?.branding?.signInUrl);
  // no company short name, just return provided path
  if (!nameFromSignInUrl) return path;
  // return path with trailing slash and name
  return path + `/${nameFromSignInUrl}`;
}

// sign in url assumes pattern of company name being last item on the string
export function getNameFromSignInUrl(url?: string): string {
  return url?.split("/").pop() || "";
}
