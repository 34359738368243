import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import { SidebarRootContent, SidebarSubContent } from "../../../components/titlefyPro/farms/utilities/sidebarContent";

export type FarmMobileUIHeight = "COLLAPSED" | "EXPANDED" | "FULL";

export interface FarmsUIState {
  sidebarRootContent: SidebarRootContent;
  sidebarSubContent?: SidebarSubContent;
  farmPropsListIndex: number;
  farmsListIndex: number;
  overviewHeight: FarmMobileUIHeight;
}

export const defaultFarmsUI: FarmsUIState = {
  sidebarRootContent: "Overview",
  farmPropsListIndex: 0,
  farmsListIndex: 0,
  overviewHeight: "EXPANDED",
};

export const FarmsUIReducer: Reducer<FarmsUIState, KnownAction> = (state = defaultFarmsUI, action: KnownAction) => {
  switch (action.type) {
    case "SET_INITIAL_FARMS_UI":
      return { ...state, sidebarRootContent: "Overview", farmPropsListIndex: 0, sidebarSubContent: undefined };
    case "SET_FARM_UI":
      return { ...state, ...action.payload };
    case "SET_FARM_PROPS_LIST_INDEX":
      return { ...state, farmPropsListIndex: action.payload };
    case "SET_FARMS_LIST_INDEX":
      return { ...state, farmsListIndex: action.payload };
    case "SET_OVERVIEW_HEIGHT":
      return { ...state, overviewHeight: action.payload };
    case "SET_SIDEBAR_ROOT_CONTENT":
      return { ...state, sidebarRootContent: action.payload };
    case "SET_SIDEBAR_SUB_CONTENT":
      return { ...state, sidebarSubContent: action.payload };
    default:
      return state || defaultFarmsUI;
  }
};
