import { UserLite, emptyUserLite, newUserLite, UserLiteFormInfo } from "../user/userLite";
import { SortDirection } from "../user/searchUsers";
import { Links } from "../../store/settings/settingsStore";

export enum PartnerId {
  Titlefy = 0,
}

export interface Partner {
  partnerId: number;
  companyName: string;
  useCompanyBranding: boolean;
  websiteUrl: string;
  rateQuoteUrls: Links[];
  logoMainUrl: string;
  logoAltUrl: string;

  // these are getting phased out, let Dennis know once you've updated all places using these fields
  logoUrl: string;
  logoSmallUrl: string;
  logoLargeUrl: string;

  branding: Branding;
  userAdminNotificationEmail: string[];
  divisions: Division[];
  defaultAccountManager: UserLite | null;
}
//Dont forget about optional ^^

export interface Division {
  divisionId: number;
  divisionName: string;
  branches: Branch[];
}

export function newDivision(): Division {
  const division: Division = {
    divisionId: 0,
    divisionName: "",
    branches: [],
  };
  return division;
}

export interface DivisionFormInfo extends Division {
  [key: string]: any;
}

export interface Branch {
  branchId: number;
  branchName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  license: string;
}

export function newBranch(): Branch {
  const branch: Branch = {
    branchId: 0,
    branchName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    license: "",
  };

  return branch;
}

export interface BranchFormInfo extends Branch {
  [key: string]: any;
}

export interface Branding {
  mainColor: string;
  secondaryColor: string;
  tagLine: string;
  signInUrl: string;
  termsUrl: string;
  privacyUrl: string;
  instagramUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  linkedInUrl: string;
}

export function newBranding(): Branding {
  return {
    mainColor: "",
    secondaryColor: "",
    tagLine: "",
    signInUrl: "",
    termsUrl: "",
    privacyUrl: "",
    instagramUrl: "",
    facebookUrl: "",
    twitterUrl: "",
    linkedInUrl: "",
  };
}

// this is a custom regex pattern for checking on Partner Profile Info&Branding form to ensure the user enters a valid sign in path like:
// "/auth/sign-in/titlefy"
export const signInPathPattern: RegExp = /^(\/auth\/sign-in\/)([^\/]\w*)$/;

interface BrandingFormInfo extends Branding {
  [key: string]: any;
}

export interface PartnerFormInfo extends Partner {
  [key: string]: any;
  branding: BrandingFormInfo;
  defaultAccountManager: UserLiteFormInfo | null;
}

export function newPartner(): Partner | PartnerFormInfo {
  return {
    partnerId: 0,
    companyName: "",
    useCompanyBranding: true,
    websiteUrl: "",
    rateQuoteUrls: [],
    logoMainUrl: "",
    logoAltUrl: "",
    logoUrl: "",
    logoSmallUrl: "",
    logoLargeUrl: "",
    branding: {
      mainColor: "",
      secondaryColor: "",
      tagLine: "",
      signInUrl: "",
      termsUrl: "",
      privacyUrl: "",
      instagramUrl: "",
      facebookUrl: "",
      twitterUrl: "",
      linkedInUrl: "",
    },
    userAdminNotificationEmail: [],
    divisions: [],
    defaultAccountManager: newUserLite(),
  };
}

export const emptyPartnerInfo: PartnerFormInfo = {
  defaultAccountManager: newUserLite(),
  partnerId: 0,
  companyName: "",
  useCompanyBranding: true,
  websiteUrl: "",
  rateQuoteUrls: [],
  logoMainUrl: "",
  logoAltUrl: "",
  logoUrl: "",
  logoSmallUrl: "",
  logoLargeUrl: "",
  branding: {
    mainColor: "",
    secondaryColor: "",
    tagLine: "",
    signInUrl: "",
    termsUrl: "",
    privacyUrl: "",
    instagramUrl: "",
    facebookUrl: "",
    twitterUrl: "",
    linkedInUrl: "",
  },
  userAdminNotificationEmail: [],
  divisions: [],
};

export enum IndustryType {
  Finance = 1,
  RealEstate = 2,
  TitleEscrow = 3,
}

export enum CompaniesListSortBy {
  Undefined = "",
  CompanyName = "CompanyName",
  PartnerName = "PartnerName",
  CompanyId = "CompanyId",
  PartnerId = "PartnerId",
  Website = "WebsiteUrl",
}

// used for SearchPartners API
export interface CompanyFilters {
  searchString: string;
  partnerIds: number[];
  pageSize: number;
  pageIndex: number;
  sortBy: CompaniesListSortBy;
  sortDirection: SortDirection.ASC;
}

export function newCompanyFilters(pageSize?: number): CompanyFilters {
  // to include or not to include the optional fields...
  return {
    searchString: "",
    partnerIds: [],
    // only doing pageSize 5 for now for testing pagination
    // pageSize: pageSize ? pageSize : 5,
    pageSize: pageSize ? pageSize : 20,
    pageIndex: 1,
    sortBy: CompaniesListSortBy.CompanyName,
    sortDirection: SortDirection.ASC,
  };
}

export class PaginatedSearchResult<T> {
  items: T[] = [];
  pageCount: number = 0;
  totalCount: number = 0;
}
