import { enumToDictArray } from "utilities/typescriptHelpers";

/** Used for displaying & grouping in UI. These are what the API returns on suggestions. */
export enum Suggestiontype {
  Undefined = 0,
  Address = 1,
  Owner = 2,
  City = 3,
  Zip = 4,
  Apn = 5,
}

export interface PropertySearchSuggestion {
  propertyId: number;
  suggestionType: Suggestiontype;
  owner: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  matchedText: string[];
  latitude: number;
  longitude: number;
  apn?: string;
}

/** Used for specifying search type to narrow prop suggestion results and lower load on ElasticSearch. */
export enum SuggestionSearchType {
  All,
  AddressesSuggestion,
  OwnersSuggestion,
  APNSuggestion,
  LocationsSuggestion,
}

const suggestionSearchTypeLabels = new Map<SuggestionSearchType, string>([
  [SuggestionSearchType.All, "All"],
  [SuggestionSearchType.AddressesSuggestion, "Address"],
  [SuggestionSearchType.OwnersSuggestion, "Owner"],
  [SuggestionSearchType.APNSuggestion, "APN"],
  [SuggestionSearchType.LocationsSuggestion, "Location"],
]);

export const suggestionSearchTypeOptions = enumToDictArray(SuggestionSearchType, false, suggestionSearchTypeLabels);

export interface PropSearchSuggestionRequest {
  latitude: number;
  longitude: number;
  searchString: string;
  maxResultsPerType: number;
  suggestionSearchType: SuggestionSearchType;
}

export interface PropSearchSuggestionCategories {
  addressSuggestions: PropertySearchSuggestion[];
  ownerSuggestions: PropertySearchSuggestion[];
  placeSuggestions: PropertySearchSuggestion[];
  apnSuggestions: PropertySearchSuggestion[];
}
