import { IwContentType } from "../../../dataTypes/maps";
import * as QueryString from "query-string";
import { hasKey } from "utilities/typescriptHelpers";
import { MapTilt } from "store/maps/propertiesMap/propertiesMapStore";

export class SalesCompsMapState {
  iwContentType: IwContentType = "value";
  selectedPropId: number | null = null;
  options: google.maps.MapOptions = {
    // have to set these fields initially so that these keys are present to assign values to when mapping query params to this object
    // if any other new options fields need to be stored in url query params, be sure to add key with a value or undefined
    mapTypeId: "roadmap",
    tilt: MapTilt.None,
    zoom: 17,
    center: { lat: 0, lng: 0 },
  };

  static Stringify(mapState: SalesCompsMapState): string {
    const mapStateAlias = { ...mapState } as any;
    const params = QueryString.stringify({
      ...mapStateAlias,
      centerPoint: JSON.stringify({ ...mapStateAlias.centerPoint }),
    });
    return params;
  }

  static ParseQueryParams(params: string): SalesCompsMapState {
    const mapState = new SalesCompsMapState();
    const state = { ...mapState } as any;
    const parseOptions = {
      parseNumbers: true,
      parseBooleans: true,
    };
    const parsedParamObj = QueryString.parse(params, parseOptions);
    const finalState = mapParsedValues(state, parsedParamObj);
    return finalState;
  }
}

export function mapParsedValues<T>(targetObj: T, parsedParamObj: object): T {
  const target = { ...targetObj };
  for (const key in target) {
    // check is key exists
    if (hasKey(parsedParamObj, key)) {
      // is this an object?
      if (Object.prototype.toString.call(target[key]).indexOf("Object") > -1) {
        // parse the object
        const parsedNestedObj = JSON.parse(parsedParamObj[key]);
        if (parsedNestedObj) {
          // recursive call
          target[key] = mapParsedValues(target[key], parsedNestedObj);
        }
      } else {
        target[key] = parsedParamObj[key];
      }
    }
  }
  return target;
}

export function stringifyNestedLevels<T>(obj: T): T {
  const result = { ...obj };
  for (const key in obj) {
    // is this an object?
    if (Object.prototype.toString.call(obj[key]).indexOf("Object") > -1) {
      // recursive call, each level gets JSON.stringified as it unwinds
      result[key] = JSON.stringify(stringifyNestedLevels(obj[key])) as any;
    }
  }
  return result;
}
