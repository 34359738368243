import * as React from "react";
import { withRouter, Route, Redirect, RouteComponentProps } from "react-router-dom";
import ResetPassword from "./forms/resetPassword";
import SignIn from "./forms/signIn";
import NewUserPasswordReset from "./forms/newUserPasswordReset";
import { Partner } from "../../dataTypes/partners";
import AcceptTermsConditions from "./forms/acceptTermsConditions";
import Alert from "components/alert";
import { AuthPath, baseSignInPath, getNameFromSignInUrl } from "./types/authPath";
import LogoutSuccess from "./forms/logoutSuccess";
import { getPartnerBranding } from "./queries";
import { CognitoUser } from "@aws-amplify/auth";
import "./partnerStyles/styles.scss";
import { setPartnerStyles } from "./partnerStyles";

interface State {
  partner?: Partner;
  pending: boolean;
  signInEmail: string;
  awsCognitoUser?: CognitoUser;
}

class AuthenticatorContainer extends React.Component<RouteComponentProps, State> {
  state: State = {
    partner: undefined,
    pending: true,
    signInEmail: "",
  };

  async componentDidMount() {
    try {
      const resp = await getPartnerBranding(`${baseSignInPath}/` + getNameFromSignInUrl(this.props.location.pathname));
      const partner = resp.data;
      // update state & css variables with branding data
      if (partner) this.setState({ partner });
      setPartnerStyles(partner);
    } catch (error) {
      console.log(error);
    }
    this.setState({ pending: false });
  }

  setSignInEmail = (signInEmail: string) => this.setState({ signInEmail });
  setAwsCognitoUser = (awsCognitoUser?: CognitoUser) => this.setState({ awsCognitoUser });

  render() {
    const { partner, signInEmail, awsCognitoUser, pending } = this.state;
    return (
      !pending && (
        <>
          <Alert scope="App" />
          {/* this is the default route & handle redirects */}
          <Route
            exact
            path="/auth"
            render={(props) => (
              <Redirect
                to={{
                  pathname: `/auth/sign-in`,
                  // pass along location state so signIn will have data of where user was trying to go
                  state: props.location.state,
                }}
              />
            )}
          />
          <Route<{}, AuthPath>
            path={["/auth/terms-conditions/:companyName", "/auth/terms-conditions"]}
            render={(props) => <AcceptTermsConditions partner={partner} {...props} />}
          />
          <Route<{}, AuthPath>
            path={["/auth/sign-in/:companyName", "/auth/sign-in"]}
            render={(props) => (
              <SignIn
                partner={partner}
                setAwsCognitoUser={this.setAwsCognitoUser}
                setSignInEmail={this.setSignInEmail}
                {...props}
              />
            )}
          />
          <Route<{}, AuthPath>
            path={["/auth/reset-password/:companyName", "/auth/reset-password"]}
            render={(props) => (
              <ResetPassword
                partner={partner}
                signInEmail={signInEmail}
                setSignInEmail={this.setSignInEmail}
                {...props}
              />
            )}
          />
          <Route<{}, AuthPath>
            path={["/auth/new-user-reset-password/:companyName", "/auth/new-user-reset-password"]}
            render={(props) => <NewUserPasswordReset partner={partner} awsCognitoUser={awsCognitoUser} {...props} />}
          />
          <Route<{}, AuthPath>
            path={["/auth/logout-success/:companyName", "/auth/logout-success"]}
            render={() => <LogoutSuccess partner={partner} />}
          />
        </>
      )
    );
  }
}

export default withRouter(AuthenticatorContainer);
