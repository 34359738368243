import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";

export interface LoadingState {
  isLoading: boolean;
  navText?: string;
  transparent?: boolean;
}

const initialState = {
  isLoading: false,
  navText: "",
  transparent: false,
};

export const LoadingReducer: Reducer<LoadingState, KnownAction> = (state = initialState, action: KnownAction) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, ...action.payload };
    default:
      return state || initialState;
  }
};
