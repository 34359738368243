import { UserStatus } from "./user";
import { UserLite } from "./userLite";

export enum SortDirection {
  Unknown,
  ASC,
  DESC,
}

export enum UsersListSortBy {
  Undefined = "",
  Status = "StatusDescription",
  Name = "FullName",
  CompanyName = "CompanyName",
  Email = "Email",
  Title = "Title",
}

export interface SearchUsersFilter {
  searchString: string;
  userStatus: UserStatus[];
  partnerIds: number[];
  pageSize: number;
  pageIndex: number;
  sortBy: UsersListSortBy;
  sortDirection: SortDirection;
}

export function newUserFilters(): SearchUsersFilter {
  return {
    searchString: "",
    userStatus: [],
    pageIndex: 1,
    pageSize: 20,
    partnerIds: [],
    sortBy: UsersListSortBy.Name,
    sortDirection: SortDirection.DESC,
  };
}

export interface SearchUsersResult {
  users: UserLite[];
  pageCount: number;
  usersCount: number;
}

export function newSearchUsersResult(): SearchUsersResult {
  return {
    users: [],
    pageCount: 0,
    usersCount: 0,
  };
}
