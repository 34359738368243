import { Component, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { isDev, sendToAnalytics } from "utilities/misc";
import { ReactQueryDevtools } from "react-query/devtools";

// styles
import "./App.scss";
import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-multi-carousel/lib/styles.css";

// utilities
import AuthService from "./utilities/authService";

// components
import HomePageContainer from "./components/homePage/homePageContainer";
import { CookieBanner } from "./components/controls/cookieBanner";
import AuthenticatorContainer from "./components/auth/authenticatorContainer";
import Pace from "react-pace-progress";
import PrivateRoute from "./components/routes/privateRoute";
import Alert from "components/alert";

const TitlefyProContainer = lazy(() => import("./components/titlefyPro/titlefyProContainer"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !isDev,
      retry: false,
      // ensure refetches are deduped
      staleTime: 1000,
    },
  },
});

AuthService.ConfigureAuth();

class App extends Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <div className="app-container" style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <Alert scope="Global" />
            <Suspense fallback={<Pace height={2} color="#00B2F1" />}>
              {/* used to track dynamic page views, must live outside switch */}
              <Route
                path="/"
                render={({ location }) => {
                  sendToAnalytics("event", "page_view", {
                    page_location: window.location.href,
                    page_path: location.pathname,
                  });
                  return null;
                }}
              />
              <Switch>
                {/* always remove trailing slash from end of url so path checks work */}
                <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
                <Route path="/auth" component={AuthenticatorContainer} />
                <PrivateRoute path="/app" component={TitlefyProContainer} />
                <Route path="/" component={HomePageContainer} />
              </Switch>
            </Suspense>
            <CookieBanner />
          </div>
        </div>
      </QueryClientProvider>
    );
  }
}

export default App;
