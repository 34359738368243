import * as React from "react";
import { ContactUsKey } from "../homePage/homePageContainer";
import NavBar from "../homePage/navbar";

interface Props {
  setTopic: (name: ContactUsKey, value: string) => void;
}
class TermsConditions extends React.Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="privacyTerms-container homepage-container">
        <NavBar setTopic={this.props.setTopic} useSubpageLinks={true} />
        <div className="content-container">
          <Terms />
        </div>
      </div>
    );
  }
}

export const Terms = () => (
  <>
    <h1>Terms and Conditions</h1>

    <p>Effective 6/2/2021</p>

    <p>
      These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with the
      https://pro.flueid.com website (“Site”) and related mobile application (the "Service") operated by Flueid Software
      Corporation (“Flueid”).
    </p>

    <p>
      By accessing, downloading, installing, running or using the Services, you agree to be bound by these Terms, as
      updated from time to time. If you do not agree to our Terms you should access or otherwise use the Services. Any
      continued use of our Services after changes or updates have been made to these Terms constitutes your acceptance
      of such changes. The terms “you” or “your” shall refer to you the user and the terms “we,” “us,” or “our” shall
      refer to Flueid.
    </p>

    <h2>Accounts</h2>

    <p>
      When you create an account with us, you must provide us information that is accurate, complete, and current at all
      times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your
      account on our Service.
    </p>

    <p>
      You are responsible for safeguarding the password that you use to access the Service and for any activities or
      actions under your password, whether your password is with our Service or a third-party service.
    </p>

    <p>
      You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of
      any breach of security or unauthorized use of your account.
    </p>

    <p>
      You may not use as a username the name of another person or entity or that is not lawfully available for use, a
      name or trade mark that is subject to any rights of another person or entity other than you without appropriate
      authorization, or a name that is otherwise offensive, vulgar or obscene.
    </p>

    <h2>License</h2>
    <p>
      For as long as you remain compliant with all Terms as set forth herein, we grant you a limited, non-exclusive,
      non-transferrable, revocable license to access and use our Site on any personal device that you own or control.
      This license also permits you to access and use any content, information, or related materials that are made
      available as a part of our Services. Any rights not expressly granted herein are reserved by us, including our
      licensors. This license does not permit you to rent, lease, lend, sell, redistribute or sublicense our Site and
      Services.
    </p>

    <h2>Site Content and Materials</h2>

    <p>
      The information maintained on the Site is intended to provide users with information about our products and
      services and is subject to change without notice. The Services described in the Site may not be available in all
      geographic areas. For details as to the Services offered, including the related pricing, please refer to the
      applicable product details found within the Site. No solicitation is made to any person utilizing our Services to
      use any information, materials, products or services in any jurisdiction where the provision of such information,
      materials, products or services is prohibited by law.
    </p>

    <h2>Data Use</h2>

    <p>
      All data and information obtained from our Site is intended only for your personal, non-commercial use, and shall
      be used for no other purpose.
    </p>

    <h2>Data Validation</h2>

    <p>
      Information on the Site is deemed to be valid and reliable, but is not guaranteed. It is your sole responsibility
      to independently confirm and verify all information upon which you may rely or use from the Site.
    </p>

    <h2>Inaccurate Data</h2>

    <p>
      In the event you find any property listing data or information from, through or as a part of our Services to be
      inaccurate or incomplete, you shall notify us.
    </p>

    <h2>Technological Issues</h2>

    <p>
      The Site may, from time to time, not operate as intended, at normal speed, with full functionality, or at all. In
      our sole discretion, the Site and our Services may cease to operate for any amount of time, and limitations upon
      the functionality or use of such Site and Services by the end user may result. Any decision to cease the activity
      of any one or all of our Services is solely at our discretion.
    </p>

    <h2>No Unlawful or Prohibited Use</h2>

    <p>
      As a condition of your use of our Services, you represent and warrant to us that you will not use our Services for
      any purpose that is unlawful or prohibited by the applicable terms, conditions, and notices.
    </p>

    <p>
      You may not use our Services in any manner that could damage, disable, overburden, compromise or impair our
      Services or interfere with any other party's use and enjoyment of our Services. You may not obtain or attempt to
      obtain any materials or information through any means not intentionally made available or provided for through our
      Services.
    </p>

    <h2>Intellectual Property</h2>

    <p>
      a. Flueid its affiliates, successors and assigns, as the developer and host of our Site, owns or licenses all
      other intellectual property rights related to our Site, including the look and feel of our Site and all underlying
      software, as updated and modified from time to time. You may not download and/or save a copy of any of the screens
      except as otherwise provided in these Terms. The Service is protected by copyright, trademark, and other laws of
      both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with
      any product or service without the prior written consent of Flueid Software Corporation. Except for the limited
      license granted herein, Flueid, including its licensors, do not grant any license or other authorization to the
      respective trademarks, service marks, copyrightable material, trade secrets or other intellectual property by
      placing or displaying them on or through our Site. The Service and its original content, features and
      functionality are and will remain the exclusive property of Flueid Software Corporation and its licensors.
    </p>

    <p>
      b. You shall (i) never remove or destroy any copyright or other proprietary marking placed upon, contained with,
      or used in connection with our Services; (ii) never create or authorize new versions, modifications, enhancements
      or derivative works to, nor translate, reverse engineer, de-compile, disassemble or attempt to derive the source
      code of, the Site and/or any of our Services or any portion thereof.
    </p>

    <p>
      c. You shall not copy, screen scrape, redistribute or retransmit any of the data or information provided, except
      you may use the property listing data in connection with your purchase, sale, or lease of an individual property.
    </p>

    <h2>Electronic Communications</h2>

    <p>
      When you use our Services, you are communicating with us electronically. You expressly consent and opt in to
      receive communications from us electronically. We will communicate with you by e-mail, instant messaging, or other
      electronic means. You agree that all agreements, notices, disclosures, and other communications that we provide to
      you electronically satisfy any legal requirement that such communication be in writing.
    </p>

    <h2>Links To Other Web Sites</h2>

    <p>
      Our Service may contain links to third-party web sites or services that are not owned or controlled by Flueid
      Software Corporation.
    </p>

    <p>
      Flueid Software Corporation has no control over, and assumes no responsibility for, the content, privacy policies,
      or practices of any third party web sites or services. You further acknowledge and agree that Flueid Software
      Corporation shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged
      to be caused by or in connection with use of or reliance on any such content, goods or services available on or
      through any such web sites or services.
    </p>

    <p>
      We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or
      services that you visit.
    </p>

    <h2>Termination</h2>

    <p>
      We may terminate or suspend your account immediately, without prior notice or liability, for any reason
      whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service
      will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
    </p>

    <h2>Limitation Of Liability</h2>

    <p>
      In no event shall Flueid Software Corporation, nor its directors, employees, partners, agents, suppliers, or
      affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without
      limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or
      use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service;
      (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions
      or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or
      not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to
      have failed of its essential purpose.
    </p>

    <h2>Disclaimer of Warranties</h2>

    <p>
      THE INFORMATION CONTAINED WITHIN OUR SERVICES, INCLUDING WITHOUT LIMITATION ALL TEXT, GRAPHICS, LINKS, TOOLS OR
      OTHER ITEMS ARE PROVIDED ON AN "AS IS", “AS AVAILABLE” BASIS. WE DISCLAIM ANY REPRESENTATION, WARRANTY OR OTHER
      ASSURANCE WITH RESPECT TO THE OPERATION, QUALITY, ACCURACY, SECURITY, COMPLETENESS, TIMELINESS, FUNCTIONALITY OR
      TITLE TO CONTENT PROVIDED OR DISPLAYED, INCLUDING THE PROPERTY LISTING DATA. ACCESS TO OUR SERVICES AT ANY TIME
      MAY BE INTERRUPTED, RESTRICTED OR DELAYED FOR REASONS BEYOND OUR CONTROL. NO WARRANTY OF ANY KIND, IMPLIED,
      EXPRESS OR STATUTORY, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT OF
      INTELLECTUAL PROPERTY, FITNESS FOR ANY PARTICULAR PURPOSE AND FREEDOM FROM COMPUTER VIRUS, IS GIVEN IN CONJUNCTION
      WITH OUR SERVICES, AND THEIR ATTENDANT INFORMATION AND MATERIALS. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE
      DISCLAIM ALL RESPONSIBILITY FOR ANY CLAIMS, DAMAGES OR LOSSES (INCLUDING, WITHOUT LIMITATION, FINANCIAL LOSS,
      DAMAGES FOR BUSINESS LOSS, LOSS OF PROFITS OR OTHER CONSEQUENTIAL LOSSES) ARISING IN CONTRACT, TORT OR OTHERWISE
      FROM THE USE OF OR INABILITY TO USE OUR SERVICES OR FROM ANY ACTION TAKEN AS A RESULT OF USING OUR SERVICES,
      INCLUDING THE PROPERTY LISTING DATA. NO ORAL ADVICE OR WRITTEN INFORMATION GIVEN BY FLUEID, ITS EMPLOYEES,
      AFFILIATES, LICENSORS, SERVICES VENDORS OR AGENTS WILL CREATE A WARRANTY; NOR MAY YOU RELY ON ANY SUCH INFORMATION
      OR ADVICE.
    </p>

    <h2>Indemnification</h2>

    <p>
      You agree to defend and indemnify Flueid, including its affiliates, officers, directors, employees and agents,
      against any and all claims, losses, damages, liability, costs and expenses (including but not limited to
      reasonable attorneys' fees) arising from (a) your use of our Services, including the property listing data; (b)
      violation of these Terms; or (c) violation of any law or third-party's rights, (including, without limitation,
      infringement of any copyright trademark, service mark, trade secrets, right of privacy or publicity or any other
      third party right). The terms of this section shall survive the termination of your relationship with us and/or
      registration for our Services.
    </p>

    <h2>Governing Law</h2>

    <p>
      By using our Services, you agree that applicable federal law and the laws of the State of Texas, without regard to
      its principles of conflict of laws, will govern these Terms and any dispute of any sort that might arise between
      you and us. You agree that venue for any disputes shall be located exclusively in Travis County, Texas and
      expressly waive any right to claim that such forum is inconvenient.
    </p>

    <h2>Changes</h2>

    <p>
      We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
      material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a
      material change will be determined at our sole discretion.
    </p>

    <p>
      By continuing to access or use our Service after those revisions become effective, you agree to be bound by the
      revised terms. If you do not agree to the new terms, please stop using the Service.
    </p>

    <h2>Non-Waiver</h2>

    <p>
      Our failure to exercise or enforce any provision or right set forth in these Terms does not constitute a waiver of
      that right or provision.
    </p>

    <h2>Assignment</h2>

    <p>Flueid may assign these Terms, in whole or in part, at any time with or without notice to you.</p>

    <h2>Headings</h2>

    <p>
      Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of
      such action.
    </p>

    <h2>Complete Agreement</h2>

    <p>
      These Terms constitute the final, complete, and exclusive statement of the terms between the parties that pertain
      to the subject matter found herein, and these Terms supersede all prior and contemporaneous understandings or
      agreements of the parties. No party has been induced to enter into these Terms by, nor is any party relying on any
      representation or warranty independent of those expressly set forth in these Terms. No direct benefit is intended
      to be conferred by these Terms on any person not a party hereto and any benefit which may be actually conferred is
      purely incidental.
    </p>

    <h2>Modification of Terms</h2>

    <p>
      We reserve the right to modify these Terms and its policies at any time without advance notice to you. Any
      modification of these Terms or any related policy is effective once displayed or published on or within our
      Services. You are responsible for regularly reviewing these documents. Continued use of our Services after any
      such changes shall constitute your consent to such changes. We do not and will not assume any obligation to notify
      you of any changes to this Terms.
    </p>
  </>
);

export default TermsConditions;
