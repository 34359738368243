import { OrderServiceTypeOption } from "../interfaces/orderTypeOption";
import { OrderType } from "./orderType";
import { OrderRole } from "./orderRole";
import { DropdownOption } from "../../common";
import { enumToDictArray } from "utilities/typescriptHelpers";

export enum OrderProductType {
  Title = 1,
  Escrow = 2,
}

export enum OrderProductSubType {
  Undefined = 0,
  ShortPolicy = 1,
  FullPolicy = 2,
  FullEscrow = 3,
  PropReportOnly = 5,
  JuniorALTA = 6,
  FullALTA = 7,
}

/** Map for getting a function that returns formatted OrderProductSubType labels. Some OrderProductSubType are customized based on order type */
export const productSubTypeLabels: Record<OrderProductSubType, string> = {
  [OrderProductSubType.Undefined]: "",
  [OrderProductSubType.ShortPolicy]: "Short Policy",
  [OrderProductSubType.FullPolicy]: "Full Policy",
  [OrderProductSubType.FullEscrow]: "Full Escrow",
  [OrderProductSubType.PropReportOnly]: "Property Report Only (No Title Policy)",
  [OrderProductSubType.JuniorALTA]: "Junior ALTA Policy",
  [OrderProductSubType.FullALTA]: "Full ALTA Policy",
} as const;

export const orderProductSubTypeOptions = enumToDictArray(OrderProductSubType);

export function getProductSubTypeOption(subtype: OrderProductSubType, options?: OrderServiceTypeOption[] | null) {
  if (!options) return undefined;
  return options
    .flatMap((o) => o.productCategories)
    .flatMap((c) => c.subTypeOptions)
    .find((s) => s.productSubType === subtype);
}

export const homeEquityPolicyTypes: Array<DropdownOption> = [
  { text: "User Selected", value: OrderProductSubType.Undefined },
  { text: productSubTypeLabels[OrderProductSubType.JuniorALTA], value: OrderProductSubType.JuniorALTA },
  { text: productSubTypeLabels[OrderProductSubType.FullALTA], value: OrderProductSubType.FullALTA },
  { text: productSubTypeLabels[OrderProductSubType.PropReportOnly], value: OrderProductSubType.PropReportOnly },
];
