import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";

export default class AuthService {
  static ConfigureAuth() {
    //this configuration would presumably be done when the app starts, not every time we call the api
    Amplify.configure({
      Auth: {
        //Amazon Cognito Region
        region: "us-west-2",

        //Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_USERPOOL_ID,

        //Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEBCLIENT_ID,

        //Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: "USER_PASSWORD_AUTH",
      },
    });
  }

  //============================
  // These methods all return promises. The ".then/.catch" will be handled wherever they're called,
  // like dispatching Redux action with the data or pushing the user to another route.

  // the aws signIn() technically takes a username not an email for signing in,
  // however in our case, our user's username is the same as their email...hence the "email" as the first argument
  static SignIn(email: string, password: string) {
    return Auth.signIn(email, password);
  }

  static SignOut() {
    return Auth.signOut();
  }

  static ResetNewUserPassword(user: any, newPassword: string) {
    // completeNewPassword requires a 3rd argument for "required attributes", however our AWS Cognito account doesnt require any to be supplied
    return Auth.completeNewPassword(user, newPassword, {});
  }

  static ChangePassword(user: any, oldPassword: string, newPassword: string) {
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  static GetCurrentAuthenticatedUser() {
    return Auth.currentAuthenticatedUser();
  }

  static ForgotPassword(email: string) {
    return Auth.forgotPassword(email);
  }

  static ForgotPasswordSubmit(username: string, confirmationCode: string, password: string) {
    return Auth.forgotPasswordSubmit(username, confirmationCode, password);
  }

  static GetCurrentSession() {
    return Auth.currentSession();
  }

  static GetErrorMsg(error: any): string {
    return error?.message || "";
  }
}
