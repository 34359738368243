import { SortType } from "../../../dataTypes/properties";
import { SalesCompsSliderValues } from "../../../dataTypes/properties/salesCompsRequest";

export interface SalesCompsUiState {
  mobileMapVisible: boolean;
  sortType: SortType;
  sliderValues: SalesCompsSliderValues;
}

export const initialSalesCompsUiState: SalesCompsUiState = {
  mobileMapVisible: false,
  sortType: "Closest",
  sliderValues: new SalesCompsSliderValues(),
};
