import * as React from "react";
import { Link } from "react-scroll";
import DashedLineGroup, { DashedLine } from "../../controls/dashedLineGroup";
import { Transition, Visibility, VisibilityEventData } from "semantic-ui-react";
import { itemVisible } from "./missionSection";

interface State {
  mainGraphicVisible: boolean;
  loanOfficerVisible: boolean;
}

class HeroSection extends React.Component<any, State> {
  state: State = {
    mainGraphicVisible: false,
    loanOfficerVisible: false,
  };

  handleVisibilityUpdate = (e: any, data: VisibilityEventData) => {
    // enter main graphic animation, then stagger showing loanOfficer
    if (!this.state.mainGraphicVisible && itemVisible(data)) {
      this.setState({ mainGraphicVisible: true }, () => {
        setTimeout(() => {
          this.setState({ loanOfficerVisible: true });
        }, 500);
      });
    }
  };

  render() {
    return (
      <div className="hero-container">
        <div className="hero-content-container">
          <DashedLineGroup
            showOnMount
            className="hero-section-dashed-line-group-1"
            render={(visible: boolean) => (
              <>
                <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
                <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
                <DashedLine width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
                <DashedLine width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
              </>
            )}
          />
          <div className="header-main">Flueid Pro is fueling the digital real estate lifecycle</div>
          <div className="subheader-main txt-light">
            Flueid’s integrated technology has modernized the closing process, digitally. Using data and automation, we
            are unlocking efficiencies, reducing costs and identifying potential customers for mortgage lenders, title
            agents, servicers, closing providers and other strategic partners. This eliminates guesswork and drives more
            closings with less effort.
            <DashedLineGroup
              showOnMount
              animationDirection="left"
              className="hero-section-dashed-line-group-2"
              render={(visible: boolean) => (
                <>
                  <DashedLine width={269} duration={0.5} delay={0.7} x={"-10vw"} show={visible} />
                  <DashedLine width={150} duration={1.5} delay={0} x={"-8vw"} show={visible} />
                  <DashedLine width={15} duration={1.5} delay={1} x={"-4vw"} show={visible} />
                  <DashedLine width={25} duration={2} delay={1} x={"-1vw"} show={visible} />
                </>
              )}
            />
          </div>
          <Link to="homepage-visibility-closing-container" smooth={true}>
            <div className="btn-white learnMoreBtn">Learn More</div>
          </Link>
        </div>
        <div className="heroBackgroundSolid">
          <Visibility className="hero-visibility" fireOnMount onUpdate={this.handleVisibilityUpdate}>
            <Transition visible={this.state.mainGraphicVisible} animation="fade" duration={2500}>
              <div className="heroBackgroundImg">
                <Transition visible={this.state.loanOfficerVisible} animation="fade" duration={2500}>
                  <div className="photo-loanOfficer" />
                </Transition>
                <div className="logo-badge-flueid" />
              </div>
            </Transition>
          </Visibility>
          <DashedLineGroup
            visibilityClassName="hero-section-visibility-line-group-3"
            className="hero-section-dashed-line-group-3"
            render={(visible: boolean) => (
              <>
                <DashedLine width={120} duration={2.5} delay={0} x={"5vw"} show={visible} />
                <DashedLine width={44} duration={1.5} delay={0.2} x={"8vw"} show={visible} />
                <DashedLine width={291} duration={1} delay={0.5} x={"20vw"} show={visible} />
                <DashedLine width={36} duration={2.75} delay={0.7} x={"43vw"} show={visible} />
              </>
            )}
          />
        </div>
      </div>
    );
  }
}

export default HeroSection;
