import * as React from "react";
import { Link } from "react-router-dom";
import DashedLineGroup, { DashedLine } from "../controls/dashedLineGroup";

const SocialLinks = () => (
  <>
    <a href="https://www.instagram.com/flueidcorp/" target="_blank" rel="nofollow noreferrer noopener">
      Ig
    </a>{" "}
    |
    <a href="https://www.facebook.com/flueid" target="_blank" rel="nofollow noreferrer noopener">
      {" "}
      Fb
    </a>{" "}
    |
    <a href="https://www.linkedin.com/company/flueid/about/" target="_blank" rel="nofollow noreferrer noopener">
      {" "}
      Li
    </a>
  </>
);
const PrivacyTermsLinks = (className?: string) => (
  <>
    <Link to="/terms">
      <div className={`p-4 ${className || ""}`}>Terms of Use</div>
    </Link>
    <Link to="/privacy">
      <div className={`p-4 ${className || ""}`}>Privacy Policy</div>
    </Link>
  </>
);

class Footer extends React.Component {
  render() {
    return (
      <>
        <div className="insurfulFooter-main">
          <DashedLineGroup
            visibilityClassName="footer-visibility-line-group-1"
            className="footer-section-dashed-line-group-1"
            render={(visible: boolean) => (
              <>
                <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
                <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
                <DashedLine width={140} duration={1} delay={0.5} x={"18vw"} show={visible} />
                <DashedLine width={189} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
              </>
            )}
          />
          <div className="insurfulFooter-sec">
            <div className="insurfulFooter-logo" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} />
            <div className="insurfulFooter-textMain">
              <div className="p-4 txt-lightGray">
                © {new Date().getFullYear()} Flueid Software Corporation. All Rights Reserved.
              </div>
              <div className="insurfulFooter-termsMainWeb txt-acumin">
                {PrivacyTermsLinks()}
                <div className="p-4">
                  Connect: <SocialLinks />
                </div>
              </div>
              <div className="insurfulFooter-termsMainMob txt-acumin">
                <div className="p-4 mob">
                  Connect: <SocialLinks />
                </div>
                <div className="insurfulFooter-termasMain">{PrivacyTermsLinks("mob")}</div>
              </div>
            </div>
            <a
              target="_blank"
              href="https://flueid.com/careers"
              rel="nofollow noreferrer noopener"
              className="p-4 footer-link-careers">
              Careers
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
