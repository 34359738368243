export type CalcValueType = "money" | "percent" | "days";
export type NetSheetFieldNameOption =
  | "salesPrice"
  | "brokersFee"
  | "closingDate"
  | "taxes"
  | "loanPayoffs"
  | "closingCosts";
export type BuyersEstimateFieldNameOption =
  | "purchasePrice"
  | "loanAmount"
  | "closingDate"
  | "downPayment"
  | "taxes"
  | "closingCosts";

export interface NetSheetMini {
  netProceeds: number;
  salesPrice: number;
  brokersFeesPercent: number;
  brokersFees: number;
  closingDate: string;
  closingDateDays: number;
  taxPercent: number;
  taxes: number;
  loanPayoffs: number;
  closingCostsPercent: number;
  closingCosts: number;
}

export const emptyNetSheetMini: NetSheetMini = {
  netProceeds: 0,
  salesPrice: 0,
  brokersFeesPercent: 0,
  brokersFees: 0,
  closingDate: "",
  closingDateDays: 0,
  taxPercent: 0,
  taxes: 0,
  loanPayoffs: 0,
  closingCostsPercent: 0,
  closingCosts: 0,
};

export interface BuyersEstimateMini {
  cashToClose: number;
  purchasePrice: number;
  loanAmount: number;
  closingDate: string;
  closingDateDays: number;
  downPayment: number;
  downPaymentPercent: number;
  taxPercent: number;
  taxes: number;
  closingCostsPercent: number;
  closingCosts: number;
}

export const emptyBuyersEstimateMini: BuyersEstimateMini = {
  cashToClose: 0,
  purchasePrice: 0,
  loanAmount: 0,
  closingDate: "",
  closingDateDays: 0,
  downPayment: 0,
  downPaymentPercent: 0,
  taxPercent: 0,
  taxes: 0,
  closingCostsPercent: 0,
  closingCosts: 0,
};
