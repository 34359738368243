import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";
import {
  NetSheetMini,
  BuyersEstimateMini,
  emptyNetSheetMini,
  emptyBuyersEstimateMini,
} from "../../dataTypes/calculators";

export interface CalculatorsState {
  netSheetMini: NetSheetMini;
  buyersEstimateMini: BuyersEstimateMini;
}

const unloadedState: CalculatorsState = {
  netSheetMini: emptyNetSheetMini,
  buyersEstimateMini: emptyBuyersEstimateMini,
};

export const CalculatorsReducer: Reducer<CalculatorsState, KnownAction> = (
  state = unloadedState,
  action: KnownAction,
) => {
  switch (action.type) {
    case "SET_NET_SHEET_MINI":
      return { ...state, netSheetMini: action.payload };
    case "SET_BUYERS_ESTIMATE_MINI":
      return { ...state, buyersEstimateMini: action.payload };
    default:
      return state || unloadedState;
  }
};
