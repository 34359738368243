/**
 * Wraps analytics call in a null check
 * @types/gtag doesn't support passing spread args, so ts check is bypassed
 */
// @ts-ignore
export const sendToAnalytics: Gtag.Gtag = (...args) => {
  if (typeof window.gtag === "function") {
    // @ts-ignore
    gtag(...args);
  }
};

export const isDev = process.env.NODE_ENV === "development";
